import { useMemo } from "react";

export default function useArtifactAudioPlayerAudioFiles({ files }) {
	return useMemo(() => {
		let start = 0;

		return files.map((file) => {
			const length = file.duration * 1000;
			const stop = start + length;

			const audioFile = {
				...file,
				start,
				length,
				stop,
			};

			start = stop;

			return audioFile;
		});
	}, [files]);
}
