import firebase from "firebase/app";

import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

import createImprint from "astrid-firestore/src/api/imprints/createImprint";
import { Imprint } from "astrid-firestore/src/api/imprints/types/Imprint";

import ModalPrompt from "../../../ui/components/ModalPrompt/ModalPrompt";

import Form from "../../../forms/components/Form/Form";
import useSimpleForm from "../../../forms/hooks/useSimpleForm";

export default function CreateImprintForm({ organization, onClose }) {
	const { t } = useTranslation();
	const navigate = useNavigate();

	const { form, onSubmit, error } = useSimpleForm({
		schema: Imprint,
		defaultValues: {
			name: "",
			publisher: organization,
		},
		onSubmit: (data) => {
			return createImprint(firebase, data);
		},
		onSuccess: ({ id }) => navigate(id),
	});

	return (
		<ModalPrompt
			size="tiny"
			header={t("createImprint", "Create imprint")}
			error={error}
			onSave={onSubmit}
			onClose={onClose}
			closeOnConfirm={false}
		>
			<Form form={form}>
				<Form.Input name="name" label={t("name", "Name")} />
			</Form>
		</ModalPrompt>
	);
}
