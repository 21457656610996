import { Form, Input } from "semantic-ui-react";

import React from "react";

import DateTime from "./DateTime";

export default function DateInput({
	name,
	value,
	label,
	range,
	className,
	enableFrom,
	enableTo,
	onChange,
	disableBeforeToday,
	formatDate,
	placeholder,
	error,
	...props
}) {
	const today = new Date();
	today.setHours(0, 0, 0, 0);

	const isValidDate = (date) => {
		if (range) {
			return date >= range[0] && date <= range[1];
		}

		if (enableFrom) {
			return date >= enableFrom;
		}

		if (enableTo) {
			return date <= enableTo;
		}

		if (disableBeforeToday) {
			return date >= today;
		}

		return props.isValidDate?.(date) || true;
	};

	return (
		<Form.Input label={label} error={error} className={className}>
			<DateTime
				value={value}
				timeFormat={false}
				isValidDate={isValidDate}
				onChange={(date) => {
					onChange(null, {
						name,
						value: formatDate?.(date) || date?.toDate?.() || null,
					});
				}}
				renderInput={(props) => (
					<Input icon="calendar alternate outline" placeholder={placeholder} {...props} />
				)}
				{...props}
			/>
		</Form.Input>
	);
}
