import createDocumentData from "../../utils/createDocumentData";
import runBatch from "../../utils/runBatch";

import { Import } from "./types/Import";

export default function createImport(firebase, data) {
	return runBatch(firebase, (batch) => {
		const doc = Import.parse(createDocumentData(firebase, "imports", data));

		batch.set(doc.ref, doc);
	});
}
