import Label from "../Label/Label";

export default function ProgressLabel({ percent, color, active, children, style, ...props }) {
	return (
		<div style={{ position: "relative", whiteSpace: "nowrap", ...style }} {...props}>
			<Label color={!active && color} style={{ margin: 0 }}>
				{children}
			</Label>

			{active && (
				<div
					style={{
						position: "absolute",
						top: 0,
						left: 0,
						height: "100%",
						width: "50%",
						overflow: "hidden",
					}}
				>
					<Label
						color={color}
						style={{
							top: 0,
							left: 0,
							margin: 0,
							width: `${percent}%`,
							position: "absolute",
							whiteSpace: "nowrap",
						}}
					>
						{children}
					</Label>
				</div>
			)}
		</div>
	);
}
