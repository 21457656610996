import useDetectScroll from "@smakss/react-scroll-direction";

import { Grid, Header, Ref } from "semantic-ui-react";

import React, { useRef } from "react";
import useWindowSize from "react-use/lib/useWindowSize";

import useBoundingClientRect from "astrid-hooks/src/useBoundingClientRect";

import Flex from "../../../../components/Flex/Flex";
import Section from "../../../ui/components/Section/Section";

import Breadcrumbs from "../../components/Breadcrumbs/Breadcrumbs";
import useDocTitle from "../../hooks/useDocTitle";
import truncateString from "../../utils/truncateString";

const offset = 80;

export default function PageView({
	image,
	header,
	breadcrumbs,
	subheader,
	extra,
	sidebar,
	sidebarWidth = 3,
	maxWidth,
	children,
}) {
	useDocTitle(header);

	const headerRef = useRef();

	const { top, height } = useBoundingClientRect(headerRef);

	const { width } = useWindowSize();
	const { scrollPosition } = useDetectScroll();

	const large = width > 600;

	const transition = large && top && height ? (scrollPosition.top > top - (offset - height) ? 1 : 0) : 0;

	const sidebarStyle = {
		position: "sticky",
		top: offset,
		marginTop: -height,
	};

	const sidebarHeaderStyle = {
		opacity: transition,
		transformOrigin: "0 0",
		transition: "opacity 0.2s",
	};

	return (
		<>
			{breadcrumbs && (
				<Section
					style={{ padding: "10px 0", marginTop: -10 }}
					maxWidth={maxWidth || (!sidebar && 1000) || undefined}
				>
					<Breadcrumbs breadcrumbs={breadcrumbs} />
				</Section>
			)}

			{header && (
				<Section maxWidth={maxWidth || (!sidebar && 1000) || undefined}>
					<Flex gap={15} justifyContent="flex-start">
						{image}

						<Section.Header style={{ marginRight: "auto" }}>
							{header}

							{subheader && <Section.SubHeader>{subheader}</Section.SubHeader>}
						</Section.Header>

						{extra}
					</Flex>
				</Section>
			)}

			<Section maxWidth={maxWidth || (!sidebar && 1000) || undefined}>
				<Grid relaxed>
					{sidebar && (
						<Grid.Column width={large ? sidebarWidth : 16} style={{ paddingTop: 5 }}>
							<div style={sidebarStyle}>
								<Ref innerRef={headerRef}>
									<Header as="h3" style={sidebarHeaderStyle}>
										{truncateString(header, 25)}
									</Header>
								</Ref>

								{sidebar}
							</div>
						</Grid.Column>
					)}

					<Grid.Column width={large && sidebar ? 16 - sidebarWidth : 16} style={{ paddingTop: 20 }}>
						{children}
					</Grid.Column>
				</Grid>
			</Section>
		</>
	);
}
