import firebase from "firebase/app";

import { Card, Header } from "semantic-ui-react";

import React from "react";
import { useTranslation } from "react-i18next";

import { db } from "astrid-firebase";
import organizationTypes from "astrid-firestore/src/api/organizations/constants/organizationTypes";
import productionTypes from "astrid-firestore/src/api/productions/constants/productionTypes";
import { Production } from "astrid-firestore/src/api/productions/types/Production";
import updateProduction from "astrid-firestore/src/api/productions/updateProduction";
import useCollectionDataOptions from "astrid-firestore/src/hooks/useCollectionDataOptions";

import PrimaryButton from "../../../ui/components/Buttons/PrimaryButton";

import Form from "../../../forms/components/Form/Form";
import useSimpleForm from "../../../forms/hooks/useSimpleForm";
import usePublisherManagerOptions from "../../../users/hooks/usePublisherManagerOptions";

import useProductionStatusOptions from "../../hooks/useProductionStatusOptions";
import useProductionTagOptions from "../../hooks/useProductionTagOptions";
import useProductionTypeOptions from "../../hooks/useProductionTypeOptions";

export default function ProductionSettingsView({ production }) {
	const { t } = useTranslation();

	const [publisherManagerOptions] = usePublisherManagerOptions(production.publisher);

	const { form, onSubmit, formState } = useSimpleForm({
		schema: Production,
		defaultValues: {
			ref: production.ref,
			title: production.title || "",
			productionType: production.productionType || productionTypes.INTERNAL,
			status: production.status || "",
			publisherManagerIds: production.publisherManagerIds || [],
			tags: production.tags || [],
			productionDate: production.productionDate?.toDate() || null,
			producer: production.producer || "",
		},
		onSubmit: (data) => {
			const { publisherManagerIds } = data;

			const publisherManagers = publisherManagerOptions
				.filter(({ value }) => publisherManagerIds.includes(value))
				.map(({ data }) => data);

			return updateProduction(firebase, { publisherManagers, ...data });
		},
	});

	const typeOptions = useProductionTypeOptions();

	const statusOptions = useProductionStatusOptions();

	const [tagOptions] = useProductionTagOptions();

	const [producerOptions] = useCollectionDataOptions(
		db.collection("organizations").where("type", "==", organizationTypes.PRODUCER),
	);

	return (
		<>
			<Header as="h4" content={t("settings", "Settings")} />

			<Card fluid>
				<Card.Content>
					<Form form={form}>
						<Form.Group widths="equal">
							<Form.Input name="title" label={t("title", "Title")} />

							<Form.Select
								name="productionType"
								label={t("type", "Type")}
								placeholder={t("internal", "Internal")}
								options={typeOptions}
							/>

							<Form.Select name="status" label={t("status", "Status")} options={statusOptions} />
						</Form.Group>

						<Form.Group widths="equal">
							<Form.Select
								name="publisherManagerIds"
								label={t("publisherManagers", "Publisher managers")}
								multiple
								options={publisherManagerOptions}
							/>

							<Form.Select
								name="tags"
								placeholder={false}
								label={t("tags", "Tags")}
								multiple
								options={tagOptions}
							/>
						</Form.Group>

						<Form.Group widths="equal" style={{ marginBottom: 0 }}>
							<Form.DateInput
								name="productionDate"
								label={t("productionDueDate", "Due date")}
								enableTo={production?.deliveryDate?.toDate?.() || Infinity}
							/>

							<Form.Select
								name="producer"
								label={t("changeProducer", "Change producer")}
								options={producerOptions}
							/>
						</Form.Group>
					</Form>
				</Card.Content>

				<Card.Content extra>
					<PrimaryButton disabled={formState.isSubmitting || !formState.isDirty} onClick={onSubmit}>
						{t("save", "Save")}
					</PrimaryButton>
				</Card.Content>
			</Card>
		</>
	);
}
