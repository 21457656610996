import React from "react";

export default function WorkPresentationDetail({ label, value }) {
	if (!value) {
		return null;
	}

	return (
		<div>
			<strong>{label}:</strong> {value}
		</div>
	);
}
