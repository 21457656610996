import { Accordion, Header, Icon } from "semantic-ui-react";

import React, { useState } from "react";
import { useTranslation } from "react-i18next";

import CalendarEditSessionForm from "../../CalendarEditSessionForm/CalendarEditSessionForm";

export default function CalendarEditSession({ session, production, sessions }) {
	const { t } = useTranslation();

	const [active, setActive] = useState(false);

	return (
		<>
			<Accordion.Title active={active} onClick={() => setActive(!active)}>
				<Header size="small">
					<Icon name="dropdown" />
					{t("editSession", "Edit session")}
				</Header>
			</Accordion.Title>

			<Accordion.Content active={active}>
				<CalendarEditSessionForm session={session} sessions={sessions} production={production} />
			</Accordion.Content>
		</>
	);
}
