import { Table } from "semantic-ui-react";

import Flex from "../../../../../components/Flex/Flex";

import getCellStyle from "../utils/getCellStyle";

import TableColumnSort from "./TableColumnSort";

export default function TableHeaderCell({ column, ...props }) {
	return (
		<Table.HeaderCell
			{...props}
			textAlign={column.textAlign || "left"}
			verticalAlign={column.verticalAlign || "middle"}
			collapsing={column.collapsing}
			style={getCellStyle(column, { padding: column.canFilter ? 0 : undefined, zIndex: 1 })}
		>
			<Flex style={{ gap: 15, alignItems: "center" }}>
				<div style={{ flex: 1 }}>{column.canFilter ? column.render("Filter") : column.render("Header")}</div>
				{column.canSort && <TableColumnSort column={column} />}
			</Flex>
		</Table.HeaderCell>
	);
}
