import { Modal } from "semantic-ui-react";

import React from "react";

import CoverImage from "./CoverImage";

export default function CoverImageModal({ data, open, onClose }) {
	return (
		<Modal basic size="mini" open={open} onClose={onClose} closeOnDimmerClick>
			<CoverImage data={data} size="auto" />
		</Modal>
	);
}
