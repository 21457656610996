import { Button, Checkbox, Message, Modal } from "semantic-ui-react";

import React, { useState } from "react";

import { db, firebase } from "astrid-firebase";
import storage from "astrid-firebase/src/storage";
import { migrateProductionToFlattening } from "astrid-firestore/scripts/migrateProductionToFlattening/migrateProductionToFlattening";
import revertMigratedProduction from "astrid-firestore/scripts/migrateProductionToFlattening/revertMigratedProduction";
import { isLive } from "astrid-helpers/src/env";
import stopPropagation from "astrid-helpers/src/stopPropagation";

import featureFlags from "../../../features/authorization/constants/featureFlags";
import useFeatureFlag from "../../../features/authorization/hooks/useFeatureFlag";
import PrimaryButton from "../../../features/ui/components/Buttons/PrimaryButton";
import SecondaryButton from "../../../features/ui/components/Buttons/SecondaryButton";
import ErrorMessage from "../../../features/ui/components/Messages/ErrorMessage";
import useLoadingFunction from "../../../features/ui/hooks/useLoadingFunction";

const env = isLive ? "live" : "stage";
export const migrationApi = {
	admin: firebase,
	[env]: {
		db,
		getStorageMetadata: async (bucket, file) => {
			const metadata = await storage.refFromURL(`gs://${bucket}`).child(file).getMetadata();
			return [metadata];
		},
		getStorageJsonFile: async (bucket, file) => {
			const url = await storage.refFromURL(`gs://${bucket}`).child(file).getDownloadURL();
			const response = await fetch(url);
			if (!response.ok) {
				throw new Error(`Failed to download file: ${response.statusText}`);
			}
			const arrayBuffer = await response.arrayBuffer();
			const jsonString = new TextDecoder("utf-8").decode(arrayBuffer);
			return JSON.parse(jsonString);
		},
	},
	Timestamp: (ts) => {
		return new firebase.firestore.Timestamp(ts.seconds, ts.nanoseconds);
	},
};

function MigrationMessages({ messages }) {
	return (
		<>
			{messages.map((message) => {
				return (
					<Message
						color={message.created ? "green" : "blue"}
						key={message.id}
						success={message.success}
						header={message.header}
						content={message.message}
					/>
				);
			})}
		</>
	);
}

function RevertMigration({ production, setMessages, onClose }) {
	const [revertMigration, revertLoading, error] = useLoadingFunction(async () => {
		const deletedDocuments = await revertMigratedProduction({ api: migrationApi, env }, { production });
		setMessages(
			deletedDocuments.map((doc) => ({
				id: doc.id,
				header: `Deleted ${doc.collection} ${doc.id}`,
				deleted: true,
			})),
		);
	});

	return (
		<>
			<Modal.Content>
				<p>{`Are you sure you wish to revert the migration of ${production.title}?`}</p>
				{error && <ErrorMessage error={error} />}
			</Modal.Content>
			<Modal.Actions>
				<SecondaryButton content="Cancel" onClick={onClose} />
				<Button color="red" content="Revert migration" loading={revertLoading} onClick={revertMigration} />
			</Modal.Actions>
		</>
	);
}

function Migrate({ production, setMessages, updateProductionStatusCallback, onClose }) {
	const [convertToAnotherPublisher, setConvertToAnotherPublisher] = useState(false);
	const [doMigration, loading, error] = useLoadingFunction(async () => {
		const updatedApi = convertToAnotherPublisher ? { ...migrationApi, convertToAnotherPublisher } : migrationApi;
		const createdDocuments = await migrateProductionToFlattening({ api: updatedApi, env }, { production });
		setMessages(
			createdDocuments.map((doc) => {
				return {
					id: doc.data.id,
					header: `Created ${doc.collection} ${doc.data.id}`,
					created: true,
				};
			}),
		);
		updateProductionStatusCallback();
	});

	return (
		<>
			<Modal.Content>
				<p>{`Are you sure you wish to migrate ${production.title}?`}</p>
				<Checkbox
					label={"Convert to astrid publishing"}
					checked={convertToAnotherPublisher}
					onClick={() => {
						if (convertToAnotherPublisher) {
							setConvertToAnotherPublisher(false);
						} else {
							setConvertToAnotherPublisher(isLive ? "Ls9xocYGv4I37r7MVTEV" : "5wGBEakYMREKlmufsjLs");
						}
					}}
				/>
				{error && <ErrorMessage error={error} />}
			</Modal.Content>
			<Modal.Actions>
				<SecondaryButton content="Cancel" onClick={onClose} />
				<PrimaryButton content="Migrate" loading={loading} onClick={doMigration} />
			</Modal.Actions>
		</>
	);
}

export default function MigrateProduction({
	production,
	setMigrateProductionOpen,
	updateProductionStatusCallback = () => {},
}) {
	const [messages, setMessages] = useState([]);
	const hasFlatteningFeature = useFeatureFlag(featureFlags.FLATTENING);

	if (!hasFlatteningFeature) {
		return null;
	}

	const isMigrated = production.migrations?.flattening?.migrated;

	const onClose = () => {
		setMigrateProductionOpen(false);
	};

	return (
		<Modal open onClose={onClose} onKeyDown={stopPropagation} onClick={stopPropagation}>
			<Modal.Header content={isMigrated ? "Revert production" : "Migrate production"} />
			{messages?.length > 0 && (
				<Modal.Content>
					<MigrationMessages messages={messages} />
				</Modal.Content>
			)}
			{isMigrated ? (
				<RevertMigration production={production} setMessages={setMessages} onClose={onClose} />
			) : (
				<Migrate
					production={production}
					setMessages={setMessages}
					updateProductionStatusCallback={updateProductionStatusCallback}
					onClose={onClose}
				/>
			)}
		</Modal>
	);
}
