import firebase from "firebase/app";

import { useTranslation } from "react-i18next";

import importSources from "astrid-firestore/src/api/imports/constants/importSources";
import createImport from "astrid-firestore/src/api/imports/createImport";

import Form from "../../../forms/components/Form/Form";
import useSimpleForm from "../../../forms/hooks/useSimpleForm";

export default function CreateImportForm({ onClose }) {
	const { t } = useTranslation();

	const { form, onSubmit, error } = useSimpleForm({
		defaultValues: {
			source: importSources.BIBLIO,
			json: "",
		},
		onSubmit: ({ source, json }) => {
			const editions = JSON.parse(json);

			if (!Array.isArray(editions)) {
				throw new Error("Editions must be an array");
			}

			return Promise.all(
				editions.map((edition) =>
					createImport(firebase, {
						source,
						data: {
							edition,
						},
					}),
				),
			);
		},
		onSuccess: onClose,
	});

	return (
		<Form.Modal
			header={t("newImport", "New import")}
			size="tiny"
			form={form}
			error={error}
			onSubmit={onSubmit}
			onClose={onClose}
		>
			<Form.TextArea name="json" placeholder={"{}"} />
		</Form.Modal>
	);
}
