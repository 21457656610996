export default function readWavAudioFormat(file) {
	if (!file) {
		return;
	}

	return new Promise((resolve, reject) => {
		const reader = new FileReader();

		reader.onload = (event) => {
			const arrayBuffer = event.target.result;

			// Read the first 4 bytes to check endianness.
			const headerBytes = new Uint8Array(arrayBuffer, 0, 4);
			const headerStr = String.fromCharCode(...headerBytes);
			let littleEndian = true;
			let endianness = "little";

			if (headerStr === "RIFX") {
				littleEndian = false;
				endianness = "big";
			} else if (headerStr !== "RIFF") {
				reject(new Error("Invalid WAV file: missing 'RIFF' or 'RIFX' header."));
				return;
			}

			const dataView = new DataView(arrayBuffer);

			// Check that the "fmt " chunk exists.
			// The "fmt " marker should be at byte offset 12 (4 bytes)
			const fmtChunkMarker = String.fromCharCode(
				dataView.getUint8(12),
				dataView.getUint8(13),
				dataView.getUint8(14),
				dataView.getUint8(15),
			);

			if (fmtChunkMarker !== "fmt ") {
				reject(new Error("Invalid WAV file: missing 'fmt ' chunk."));
				return;
			}

			// Parse header fields. Note: The offsets below are for standard PCM WAV files.
			const audioFormat = dataView.getUint16(20, littleEndian); // 1 for PCM; other values for compressed formats
			const channels = dataView.getUint16(22, littleEndian); // Number of channels
			const sampleRate = dataView.getUint32(24, littleEndian); // Sample rate (Hz)
			const byteRate = dataView.getUint32(28, littleEndian); // Byte rate = SampleRate * NumChannels * BitsPerSample/8
			const blockAlign = dataView.getUint16(32, littleEndian); // Block align = NumChannels * BitsPerSample/8
			const bitDepth = dataView.getUint16(34, littleEndian); // Bits per sample

			// Determine signedness: usually 8-bit audio is stored unsigned, while 16-bit or higher is signed.
			const signed = bitDepth !== 8;

			resolve({
				audioFormat, // 1 = PCM, other values indicate compressed formats
				channels,
				sampleRate,
				byteRate,
				blockAlign,
				bitDepth,
				endianness,
				signed,
			});
		};

		reader.onerror = (error) => {
			reject(error);
		};

		reader.readAsArrayBuffer(file);
	});
}
