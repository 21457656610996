import React from "react";
import { useTranslation } from "react-i18next";

import { languageName, languageNames } from "astrid-helpers/src/languages";

import CoverImage from "../../ui/components/CoverImage/CoverImage";
import truncateString from "../../ui/utils/truncateString";

export default function useArticleMetadataFields() {
	const { t } = useTranslation();

	return {
		abridged: {
			label: t("abridged", "Abridged"),
			accessor: (abridged) =>
				typeof abridged === "boolean" ? (abridged ? t("yes", "Yes") : t("no", "No")) : null,
		},
		additionalLanguages: {
			label: t("otherLanguages", "Other occuring languages"),
			accessor: (additionalLanguages) => languageNames(additionalLanguages),
		},
		channels: {
			label: t("channels", "Channels"),
			accessor: (channels) => JSON.stringify(channels, null, 2),
		},
		contributors: {
			label: t("contributors", "Contributors"),
			accessor: (contributors) =>
				contributors?.map(({ firstName, lastName }) => `${firstName} ${lastName}`).join(", "),
		},
		cover: {
			label: t("cover", "Cover"),
			accessor: (cover) => cover && <CoverImage rounded data={{ cover }} size={45} />,
		},
		duration: {
			label: t("duration", "Duration"),
			accessor: (duration) => duration,
		},
		imprint: {
			label: t("imprint", "Imprint"),
			accessor: (imprint) => imprint?.name,
		},
		isbn: {
			label: t("isbn", "ISBN"),
			accessor: (isbn) => isbn,
		},
		language: {
			label: t("language", "Language"),
			accessor: (language) => languageName(language),
		},
		linkedArticle: {
			label: t("linkedArticle", "Linked article"),
			accessor: (linkedArticle) => linkedArticle?.name,
		},
		name: {
			label: t("title", "Title"),
			accessor: (name) => name,
		},
		subtitle: {
			label: t("subtitle", "Subtitle"),
			accessor: (subtitle) => subtitle,
		},
		originalLanguage: {
			label: t("originalLanguage", "Original language"),
			accessor: (originalLanguage) => languageName(originalLanguage),
		},
		originalName: {
			label: t("originalTitle", "Original title"),
			accessor: (originalName) => originalName,
		},
		serie: {
			label: t("serie", "Series"),
			accessor: (serie) => serie?.name,
		},
		season: {
			label: t("season", "Season"),
			accessor: (season) => season?.name,
		},
		serieNumber: {
			label: t("part", "Part"),
			accessor: (serieNumber) => serieNumber,
		},
		synopsis: {
			label: t("synopsis", "Synopsis"),
			accessor: (synopsis) => truncateString(synopsis, 40),
		},
		tags: {
			label: t("tags", "Tags"),
			accessor: (tags) => tags?.join(", "),
		},
		themaCodes: {
			label: t("themaCodes", "Thema codes"),
			accessor: (themaCodes) => themaCodes?.join(", "),
		},
	};
}
