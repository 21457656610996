import useWindowSize from "react-use/lib/useWindowSize";

import CalendarResourceHeaderButtons from "./CalendarResourceHeaderButtons";
import CalendarResourceHeaderDropdown from "./CalendarResourceHeaderDropdown";

export default function CalendarResourceHeader(props) {
	const { width } = useWindowSize();

	const tablet = width > 900;

	if (!tablet) {
		return <CalendarResourceHeaderDropdown {...props} />;
	}

	return <CalendarResourceHeaderButtons {...props} />;
}
