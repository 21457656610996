import React from "react";
import { useTranslation } from "react-i18next";

import { dropdownMenuColumn, timestampColumn } from "../../ui/utils/columns";

import ImportStatusLabel from "../components/ImportStatus/ImportStatusLabel";

import useImportDropdownMenuOptions from "./useImportDropdownMenuOptions";

export default function useImportsTableColumns() {
	const { t } = useTranslation();

	return [
		{
			id: "status",
			Header: t("status", "Status"),
			collapsing: true,
			Cell: ({ row }) => {
				return <ImportStatusLabel importt={row.original} />;
			},
		},
		{
			id: "source",
			collapsing: true,
			Header: t("source", "Source"),
		},
		{
			id: "article.after.isbn",
			Header: t("isbn", "ISBN"),
		},
		{
			id: "article.after.name",
			Header: t("title", "Title"),
		},
		timestampColumn({ id: "created", Header: t("timestamp", "Timestamp") }),
		dropdownMenuColumn(useImportDropdownMenuOptions()),
	];
}
