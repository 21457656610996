import { Icon, Menu } from "semantic-ui-react";

import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useClearRefinements, useInstantSearch } from "react-instantsearch";

import Flex from "../../../../components/Flex/Flex";

import InstantSearchRefinementDateRange from "./InstantSearchRefinementDateRange";
import InstantSearchRefinementList from "./InstantSearchRefinementList";

function RefinementSwitch({ type, ...props }) {
	switch (type) {
		case "dateRange":
			return <InstantSearchRefinementDateRange {...props} />;
		case "list":
		default:
			return <InstantSearchRefinementList {...props} />;
	}
}

export default function InstantSearchRefinement({ header, attribute, collapsed = false, ...props }) {
	const { t } = useTranslation();
	const { indexUiState } = useInstantSearch();

	const [isCollapsed, setIsCollapsed] = useState(!indexUiState?.refinementList?.[attribute] && collapsed);

	const { refine: clear, canRefine: canClear } = useClearRefinements({
		includedAttributes: [attribute],
	});

	const active = !isCollapsed || canClear;

	const onClickCollapse = () => {
		setIsCollapsed((isCollapsed) => !isCollapsed);
	};

	const onClickClear = (e) => {
		e.stopPropagation();
		clear();
	};

	return (
		<>
			<Menu.Item active={active} onClick={onClickCollapse}>
				<Flex gap={5}>
					{header}

					{canClear && <Icon fitted name="x" onClick={onClickClear} />}
				</Flex>
			</Menu.Item>

			<Menu.Menu
				style={{
					display: isCollapsed && "none",
					paddingLeft: 15,
					borderLeft: "1px solid rgba(0, 0, 0, 0.08)",
				}}
			>
				<RefinementSwitch attribute={attribute} {...props} />
			</Menu.Menu>
		</>
	);
}
