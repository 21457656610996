import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

import { db } from "astrid-firebase";

import CollectionDataTable from "../../../ui/components/CollectionDataTable/CollectionDataTable";

import AdminCard from "../../../admin/components/AdminCard/AdminCard";
import CreateExportButton from "../../../exports/components/CreateExportForm/CreateExportButton";

import useExportsTableColumns from "./hooks/useExportsTableColumns";

export default function ArticleExportsView({ article }) {
	const { t } = useTranslation();

	const navigate = useNavigate();
	const columns = useExportsTableColumns();

	return (
		<AdminCard transparent header={t("exports", "Exports")} extra={<CreateExportButton article={article} />}>
			<CollectionDataTable
				columns={columns}
				onClick={({ id }) => navigate(`./${id}`)}
				query={db
					.collection("exports")
					.where("article.id", "==", article.id)
					.where("deleted", "==", false)
					.orderBy("created", "asc")
					.limit(10)}
			/>
		</AdminCard>
	);
}
