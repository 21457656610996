import { Divider, Icon, Label } from "semantic-ui-react";

import React from "react";

import Flex from "../../../../../components/Flex/Flex";

export default function WorkPresentationLabel({ header, value, icon }) {
	if (value.length === 0) {
		return null;
	}

	return (
		<>
			<Divider />

			<strong>{header}</strong>

			<Flex style={{ marginTop: 10 }} justifyContent="flex-start" gap={5}>
				{value.map((element) => {
					return (
						<Label basic style={{ marginLeft: 0 }} key={element}>
							{icon && <Icon name={icon} />}
							{element}
						</Label>
					);
				})}
			</Flex>
		</>
	);
}
