import React from "react";
import { useTranslation } from "react-i18next";

import { formatDate } from "../../../../../helpers/date";
import Form from "../../../../forms/components/Form/Form";

export default function ArticleDistributionDateForm() {
	const { t } = useTranslation();
	const startOfDay = new Date();
	startOfDay.setHours(0, 0, 0, 0);
	const endOfDay = new Date();
	endOfDay.setHours(23, 59, 59, 999);
	return (
		<Form.Group widths="equal">
			<Form.DateInput
				name="announcementDate"
				label={t("announcementDate", "Announcement date")}
				//disableBeforeToday
				// TODO: Remove this when scheduling is implemented
				range={[startOfDay, endOfDay]} // only allow today
				formatDate={formatDate}
				rules={{ valueAsDate: false }}
				shouldUnregister={false}
			/>

			<Form.DateInput
				name="releaseDate"
				label={t("releaseDate", "Release date")}
				disableBeforeToday
				formatDate={formatDate}
				rules={{ valueAsDate: false }}
				shouldUnregister={false}
			/>

			<Form.DateInput
				name="takedownDate"
				label={t("takedownDate", "Takedown date")}
				//disableBeforeToday
				// TODO: Remove this when scheduling is implemented
				range={[startOfDay, endOfDay]} // only allow today
				formatDate={formatDate}
				rules={{ valueAsDate: false }}
				shouldUnregister={false}
			/>
		</Form.Group>
	);
}
