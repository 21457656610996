import firebase from "firebase";

import { Loader, Modal } from "semantic-ui-react";

import { useState } from "react";

import getArticlesQuery from "astrid-firestore/src/api/articles/getArticlesQuery";
import useCollectionData from "astrid-firestore/src/hooks/useCollectionData";

import SyncWorkMetadataForm from "../../components/SyncWorkMetadataForm/SyncWorkMetadataForm";

import WorkMetadataForm from "./components/WorkMetadataForm";

export default function WorkMetadataView({ work }) {
	const [openSyncModal, setOpenSyncModal] = useState(false);

	const [articles, loading] = useCollectionData(openSyncModal && getArticlesQuery(firebase, { work }));

	return (
		<>
			<WorkMetadataForm work={work} onSuccess={() => setOpenSyncModal(true)} />

			{openSyncModal && articles?.length > 0 && (
				<SyncWorkMetadataForm work={work} articles={articles} onClose={() => setOpenSyncModal(false)} />
			)}

			<Modal basic open={loading}>
				<Loader active />
			</Modal>
		</>
	);
}
