import useArticlesChannels from "../../hooks/useArticlesChannels";

import DistributeArticlesForm from "./DistributeArticlesForm";

export default function DistributeArticles({ articles, onClose }) {
	const [channels, loading] = useArticlesChannels({ articles });

	if (loading) {
		return null;
	}

	return <DistributeArticlesForm articles={articles} onClose={onClose} channels={channels} />;
}
