import { Route } from "react-router-dom";

import Import from "../pages/Import";
import Imports from "../pages/Imports";

export const importt = (
	<Route
		path=":import"
		element={<Import />}
		handle={{ crumb: ({ importt }) => importt?.created.toDate().toLocaleString() }}
	/>
);

export const imports = (
	<Route path="imports" handle={{ crumb: ({ t }) => t("imports", "Imports") }}>
		<Route index element={<Imports />} />

		{importt}
	</Route>
);
