import React from "react";

import PageView from "../../../ui/views/PageView/PageView";

import StudioDropdownMenu from "../../components/StudioDropdownMenu/StudioDropdownMenu";

import StudioDataForm from "./components/StudioDataForm";
import StudioSettingsForm from "./components/StudioSettingsForm";

export default function StudioView({ studio }) {
	return (
		<PageView header={studio.name} subheader={studio.id} extra={<StudioDropdownMenu studio={studio} />}>
			<StudioDataForm studio={studio} />
			<StudioSettingsForm studio={studio} />
		</PageView>
	);
}
