import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

import ModalPrompt from "../../../ui/components/ModalPrompt/ModalPrompt";

import useHasRole from "../../../authorization/hooks/useHasRole";
import Form from "../../../forms/components/Form/Form";

import AddUserSelect from "./components/AddUserSelect";
import useUserForm from "./hooks/useUserForm";
import submitUser from "./utils/submitUser";

export default function AddUserForm({ header, organization, reader, onClose }) {
	const { t } = useTranslation();

	const form = useUserForm();
	const hasRole = useHasRole();
	const navigate = useNavigate();

	const { watch, formState, handleSubmit } = form;

	const userId = watch("userId");

	const onSubmit = async ({ userId, firstName, lastName, email }) => {
		try {
			const user = await submitUser({ organization, userId, firstName, lastName, email, reader });

			navigate(`../users/${user.id}`);
		} catch (error) {
			if (error?.code === "auth/email-already-in-use") {
				throw new Error(t("userExistsMessage", "This user already exists. Contact an admin for help."));
			}

			throw error;
		}
	};

	return (
		<ModalPrompt
			size="tiny"
			header={header}
			disabled={!formState.isValid}
			onClose={onClose}
			onSave={handleSubmit(onSubmit)}
		>
			<Form form={form}>
				{hasRole("owner", "admin") && !reader && <AddUserSelect name="userId" />}

				{userId === "new" && (
					<>
						<Form.Group widths="equal">
							<Form.Input name="firstName" label={t("firstName")} />
							<Form.Input name="lastName" label={t("lastName")} />
						</Form.Group>

						<Form.Input
							name="email"
							label={t("email")}
							rules={{ validate: (value) => value?.includes("@") }}
						/>
					</>
				)}
			</Form>
		</ModalPrompt>
	);
}
