import { useNavigate } from "react-router-dom";

import { db } from "astrid-firebase";

import CollectionDataTable from "../../../ui/components/CollectionDataTable/CollectionDataTable";

import useImprintsTableColumns from "../../hooks/useImprintsTableColumns";

export default function ImprintsCollectionDataTable({ publisher, ...props }) {
	const navigate = useNavigate();

	const columns = useImprintsTableColumns();

	const query = db
		.collection("imprints")
		.where("publisher.id", "==", publisher.id)
		.where("deleted", "==", false)
		.orderBy("name", "asc");

	return (
		<CollectionDataTable
			query={query}
			columns={columns}
			onClick={({ id }) => navigate(id)}
			searchableKeys={["name"]}
			{...props}
		/>
	);
}
