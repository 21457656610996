import React from "react";

import organizationTypes from "astrid-firestore/src/api/organizations/constants/organizationTypes";

import Anchor from "../../../ui/components/Anchor/Anchor";
import ImageAvatar from "../../../ui/components/ImageAvatar/ImageAvatar";
import VerticalMenu from "../../../ui/components/VerticalMenu/VerticalMenu";
import useBreadcrumbs from "../../../ui/hooks/useBreadcrumbs";
import PageView from "../../../ui/views/PageView/PageView";

import AccountAudioSamples from "../../../account/views/AccountSettingsView/components/AccountAudioSamples";
import AccountGeneral from "../../../account/views/AccountSettingsView/components/AccountGeneral";
import AccountLanguages from "../../../account/views/AccountSettingsView/components/AccountLanguages";
import AccountNarration from "../../../account/views/AccountSettingsView/components/AccountNarration";
import UserDropdownMenu from "../../../superadmin/views/SuperAdminUserView/components/UserDropdownMenu";

import UserAgreements from "./components/UserAgreements";
import UserPermissions from "./components/UserPermissions";
import UserPrices from "./components/UserPrices";
import UserVendors from "./components/UserVendors";
import useUserMenuOptions from "./hooks/useUserMenuOptions";

export default function UserView({ user, organization }) {
	const breadcrumbs = useBreadcrumbs({ user });

	const name = `${user?.firstName} ${user?.lastName}`;

	const isProducer = organization.type === organizationTypes.PRODUCER;
	const isReader = isProducer && !!user?.permissions?.reader;

	const options = useUserMenuOptions({ isProducer, isReader });

	return (
		<PageView
			header={name}
			image={<ImageAvatar src={user.img} size="tiny" />}
			breadcrumbs={breadcrumbs}
			sidebar={<VerticalMenu options={options} />}
			extra={
				<>
					<span style={{ flex: 0 }}>{user.email}</span>

					<UserDropdownMenu user={user} organization={organization} />
				</>
			}
		>
			<Anchor id="permissions" />
			<UserPermissions user={user} organization={organization} />

			{isReader && (
				<>
					<Anchor id="general" />
					<AccountGeneral user={user} />

					<Anchor id="languages" />
					<AccountLanguages user={user} />

					<Anchor id="narration" />
					<AccountNarration user={user} />

					<Anchor id="samples" />
					<AccountAudioSamples user={user} />
				</>
			)}

			{isProducer && (
				<>
					<Anchor id="prices" />
					<UserPrices user={user} organization={organization} />

					<Anchor id="agreements" />
					<UserAgreements user={user} organization={organization} />

					<Anchor id="vendors" />
					<UserVendors user={user} />
				</>
			)}
		</PageView>
	);
}
