import { useTranslation } from "react-i18next";
import { useOutletContext, useParams } from "react-router-dom";

import LoadingContext from "../../ui/components/LoadingContext/LoadingContext";
import useDocTitle from "../../ui/hooks/useDocTitle";

import CalendarTimelineView from "../views/CalendarTimelineView/CalendarTimelineView";

export default function Calendar() {
	const { t } = useTranslation();
	const { organization } = useOutletContext();
	const { productionId } = useParams();

	useDocTitle(t("calendar", "Calendar"));

	return (
		<LoadingContext loading={!organization}>
			<CalendarTimelineView productionId={productionId} organization={organization} />
		</LoadingContext>
	);
}
