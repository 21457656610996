import { useMemo } from "react";
import { useTranslation } from "react-i18next";

export default function useProductionTagTranslations() {
	const { t } = useTranslation();

	return useMemo(
		() => ({
			audiodrama: t("audiodrama", "Audiodrama"),
			onevoice: t("onevoice", "Onevoice"),
			podcast: t("podcast", "Podcast"),
		}),
		[t],
	);
}
