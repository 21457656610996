import { useCallback, useMemo } from "react";

import storage from "astrid-firebase/src/storage";
import arrayChunk from "astrid-helpers/src/arrayChunk";
import { isLive } from "astrid-helpers/src/env";
import { getFileDuration } from "astrid-helpers/src/fileReader";

export default function useBucket(bucket) {
	const ref = useMemo(() => bucket && storage.refFromURL(`gs://${isLive ? "" : "stage-"}${bucket}`), [bucket]);

	const write = useCallback((file, data, meta) => ref.child(file).put(data, meta), [ref]);

	const putFile = useCallback(
		async (path, sourceFile) => {
			console.info(`Uploading file to ${ref.bucket}/${path}`);

			const fileRef = ref.child(path);

			const [duration] = await Promise.all([getFileDuration(sourceFile), fileRef.put(sourceFile)]);

			const [url, metaData] = await Promise.all([fileRef.getDownloadURL(), fileRef.getMetadata()]);

			return { url, duration, ...metaData };
		},
		[ref],
	);

	const deleteFile = useCallback(
		async (file) => {
			try {
				console.info(`Deleting file from ${ref.bucket}/${file}`);

				return await ref.child(file?.fullPath || file).delete();
			} catch (error) {
				return false;
			}
		},
		[ref],
	);

	const deleteFiles = useCallback(
		(files) => {
			return Promise.all(files.map((file) => deleteFile(file.fullPath)));
		},
		[ref, deleteFile],
	);

	const deleteFolder = useCallback(
		async (folderId) => {
			try {
				const folderRef = ref.child(folderId);
				const { items, prefixes } = await folderRef.listAll();

				await Promise.all(items.map((itemRef) => itemRef.delete()));
				await Promise.all(prefixes.map((folderRef) => deleteFolder(folderRef.fullPath)));
			} catch (error) {
				throw new Error(`Failed to delete folder ${folderId}: ${error.message}`);
			}
		},
		[ref],
	);

	const putFiles = useCallback(
		async (path, sourceFiles, getName = (file) => file.name) => {
			const result = [];

			const files = sourceFiles.map((file) => ({
				path: path ? `${path}/${getName(file)}` : getName(file),
				file,
			}));

			try {
				for (const chunk of arrayChunk(files, 10)) {
					const chunkResult = await Promise.all(chunk.map(({ path, file }) => putFile(path, file)));

					result.push(...chunkResult);
				}
			} catch (error) {
				await deleteFiles(result);

				throw error;
			}

			return result;
		},
		[ref, putFile, deleteFiles],
	);

	const downloadFile = useCallback(async (file) => {
		const response = await fetch(file.url);

		if (!response.ok) {
			throw new Error(`Failed to download file from ${file.url}`);
		}

		const blob = await response.blob();
		const blobUrl = URL.createObjectURL(blob);
		const a = document.createElement("a");
		a.href = blobUrl;
		a.download = file.name;
		document.body.appendChild(a);
		a.click();
		document.body.removeChild(a);

		URL.revokeObjectURL(blobUrl);
	}, []);

	const downloadFiles = useCallback(
		async (files) => {
			for (const file of files) {
				await downloadFile(file);
			}
		},
		[downloadFile],
	);

	const downloadFolder = useCallback(
		async (folderId) => {
			const folderRef = ref.child(folderId);
			try {
				const { items } = await folderRef.listAll();
				const files = await Promise.all(
					items.map(async (fileRef) => {
						const url = await fileRef.getDownloadURL();
						return {
							name: fileRef.name,
							url,
						};
					}),
				);
				await downloadFiles(files);
			} catch (error) {
				throw new Error(`Failed to download files from folder: ${error.message}`);
			}
		},
		[ref, downloadFiles],
	);

	return useMemo(
		() => ({
			ref,
			write,
			putFile,
			putFiles,
			deleteFile,
			deleteFiles,
			downloadFile,
			downloadFiles,
			downloadFolder,
			deleteFolder,
		}),
		[
			ref,
			write,
			putFile,
			putFiles,
			deleteFile,
			deleteFiles,
			downloadFile,
			downloadFiles,
			downloadFolder,
			deleteFolder,
		],
	);
}
