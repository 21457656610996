import { useOutletContext } from "react-router-dom";

import Unauthorized from "../../ui/components/Unauthorized/Unauthorized";

import useHasRight from "../../authorization/hooks/useHasRight";

import ProductionsView from "../views/ProductionsView/ProductionsView";

export default function Productions() {
	const { organization } = useOutletContext();

	const hasRight = useHasRight();

	if (!hasRight("list-productions.beta")) {
		return <Unauthorized />;
	}

	return <ProductionsView organization={organization} />;
}
