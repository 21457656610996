import React from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

import Flex from "../../../../components/Flex/Flex";
import InstantSearch from "../../../ui/components/InstantSearch/InstantSearch";
import InstantSearchBox from "../../../ui/components/InstantSearch/InstantSearchBox";
import InstantSearchClear from "../../../ui/components/InstantSearch/InstantSearchClear";
import InstantSearchHitsDataTable from "../../../ui/components/InstantSearch/InstantSearchHitsDataTable";
import InstantSearchHitsPerPage from "../../../ui/components/InstantSearch/InstantSearchHitsPerPage";
import InstantSearchPagination from "../../../ui/components/InstantSearch/InstantSearchPagination";
import InstantSearchPresets from "../../../ui/components/InstantSearch/InstantSearchPresets";
import InstantSearchRefinements from "../../../ui/components/InstantSearch/InstantSearchRefinements";
import PageView from "../../../ui/views/PageView/PageView";

import CreateWorkButton from "../../components/CreateWorkForm/CreateWorkButton";
import useWorksRefinements from "../../hooks/useWorksRefinements";
import useWorksTableColumns from "../../hooks/useWorksTableColumns";

export default function WorksView({ organization }) {
	const { t } = useTranslation();

	const navigate = useNavigate();

	const columns = useWorksTableColumns({ organization });
	const refinements = useWorksRefinements({ organization });

	const presetsPath = `works-${organization?.id || "admin"}`;

	const configure = {
		filters: organization && `publisher.id:${organization.id}`,
	};

	return (
		<InstantSearch routing indexName="works" configure={configure}>
			<PageView
				header={t("works", "Works")}
				extra={<CreateWorkButton publisher={organization} />}
				sidebar={
					<>
						<InstantSearchBox />
						<InstantSearchRefinements refinements={refinements} />
					</>
				}
			>
				<Flex gap={10} justifyContent="flex-start">
					<Flex gap={10} justifyContent="flex-start">
						<InstantSearchPresets path={presetsPath} refinements={refinements} />
						<InstantSearchClear />
					</Flex>
					<InstantSearchHitsPerPage />
				</Flex>

				<InstantSearchHitsDataTable selectable={false} columns={columns} onClick={({ id }) => navigate(id)} />

				<InstantSearchPagination />
			</PageView>
		</InstantSearch>
	);
}
