import { useState } from "react";
import { useTranslation } from "react-i18next";

import { dropdownMenuColumn } from "../../../../ui/utils/columns";

import ArticleDeliveryStatus from "../../ArticleDeliveryStatus/ArticleDeliveryStatus";
import DistributeArticlesForm from "../../DistributeArticles/DistributeArticlesForm";

import useArticleDistributionChannelDropdownMenuOptions from "./useArticleDistributionChannelDropdownMenuOptions";

export default function useArticleDistributionChannelsTableColumns({ article, channels, setSelectedChannel }) {
	const { t } = useTranslation();

	const dropdownMenuOptions = useArticleDistributionChannelDropdownMenuOptions({ article, setSelectedChannel });

	return [
		{
			id: "name",
			Header: t("channel", "Channel"),
			Cell: ({ value }) => {
				return value;
			},
		},
		{
			id: "announcementDate",
			Header: t("announceAt", "Announce"),
			accessor: ({ id }) => article.channels[id]?.announcementDate,
		},
		{
			id: "releaseDate",
			Header: t("release", "Release"),
			accessor: ({ id }) => article.channels[id]?.releaseDate,
		},
		{
			id: "takedownDate",
			Header: t("takedownAt", "Unpublish"),
			accessor: ({ id }) => article.channels[id]?.takedownDate,
		},
		{
			id: "status",
			collapsing: true,
			disableSortBy: true,
			Header: t("status", "Status"),
			Cell: ({ row }) => {
				const [clickedChannel, setClickedChannel] = useState(false);
				return (
					<>
						<ArticleDeliveryStatus
							article={article}
							channel={row.original}
							onClick={(e, { label }) => {
								e.stopPropagation();
								setClickedChannel({ id: row.original.id, label });
							}}
						/>
						{clickedChannel && (
							<DistributeArticlesForm
								articles={[article]}
								channels={channels}
								clickedChannel={clickedChannel}
								onClose={() => setClickedChannel(false)}
							/>
						)}
					</>
				);
			},
		},
		dropdownMenuColumn(dropdownMenuOptions),
	];
}
