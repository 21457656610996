import DistributeArticlesButton from "../../DistributeArticles/DistributeArticlesButton";

import ArticleDistributionChannelsEditButton from "./ArticleDistributionChannelsEditButton";

export default function ArticleDistributionFooter({ article }) {
	return (
		<>
			<ArticleDistributionChannelsEditButton article={article} />

			<DistributeArticlesButton articles={[article]} />
		</>
	);
}
