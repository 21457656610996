import { Modal } from "semantic-ui-react";

import { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";

import arrayChunk from "astrid-helpers/src/arrayChunk";
import useBucket from "astrid-hooks/src/useBucket";

import AsyncButton from "../../../ui/components/AsyncButton/AsyncButton";
import Button from "../../../ui/components/Buttons/Button";
import FlexTable from "../../../ui/components/FlexTable/FlexTable";
import Label from "../../../ui/components/Label/Label";
import ErrorMessage from "../../../ui/components/Messages/ErrorMessage";
import useFileInput from "../../../ui/hooks/useFileInput";

import readWavAudioFormat from "../ArtifactManager/utils/readWavAudioFormat";

export default function CreateArtifactForm({ auto, bucketName, bucketPath, onSuccess, onClose }) {
	const { t } = useTranslation();

	const { putFile } = useBucket(bucketName);

	const [error, setError] = useState(null);
	const [fileStatus, setFileStatus] = useState({});

	const uploadedFiles = useRef([]);

	const { files, input, onClick } = useFileInput({
		multiple: true,
	});

	const statusLabels = {
		pending: {
			content: t("pending", "Pending"),
			color: "grey",
		},
		uploading: {
			content: t("uploading", "Uploading"),
			color: "blue",
		},
		uploaded: {
			content: t("uploaded", "Uploaded"),
			color: "green",
		},
		error: {
			content: t("error", "Error"),
			color: "red",
		},
	};

	const columns = [
		{
			id: "name",
		},
		{
			id: "status",
			Cell: ({ row }) => {
				const status = fileStatus[row.original.name] || "pending";

				return <Label {...statusLabels[status]} />;
			},
		},
	];

	const uploadFile = async (file) => {
		try {
			setFileStatus((prev) => ({
				...prev,
				[file.name]: "uploading",
			}));

			const [format, metadata] = await Promise.all([
				readWavAudioFormat(file),
				putFile(`${bucketPath}/${file.name}`, file),
			]);

			uploadedFiles.current.push({ ...metadata, format });

			setFileStatus((prev) => ({
				...prev,
				[file.name]: "uploaded",
			}));
		} catch (error) {
			setFileStatus((prev) => ({
				...prev,
				[file.name]: "error",
			}));

			throw error;
		}
	};

	const onSubmit = async () => {
		try {
			for (const chunk of arrayChunk(files, 5)) {
				await Promise.all(chunk.map(uploadFile));
			}

			onSuccess(uploadedFiles.current);
			onClose();
		} catch (error) {
			setError(error);
		}
	};

	const isUploading =
		Object.keys(fileStatus).length > 0 && Object.values(fileStatus).some((status) => status === "uploading");

	const isUploaded =
		Object.keys(fileStatus).length > 0 && Object.values(fileStatus).every((status) => status === "uploaded");

	useEffect(() => {
		if (auto) {
			onClick();
		}
	}, [auto, onClick]);

	return (
		<Modal open size="tiny" onClose={onClose} closeOnDimmerClick>
			<Modal.Header>{t("uploadFiles", "Upload files")}</Modal.Header>

			<Modal.Content scrolling>
				{files.length > 0 ? (
					<FlexTable data={files} columns={columns} />
				) : (
					<AsyncButton onClick={onClick}>{t("chooseFiles")}</AsyncButton>
				)}

				{error && <ErrorMessage error={error} />}
			</Modal.Content>

			{!isUploading && (
				<Modal.Actions>
					<Button content={t("cancel")} onClick={onClose} />

					<Button primary disabled={files.length === 0} onClick={onSubmit}>
						{t("upload", "Upload")}
					</Button>
				</Modal.Actions>
			)}

			{isUploaded && (
				<Modal.Actions>
					<Button onClick={onClose}>{t("close", "Close")}</Button>
				</Modal.Actions>
			)}

			{input}
		</Modal>
	);
}
