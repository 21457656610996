import PageView from "../../../ui/views/PageView/PageView";

import PublisherAgreements from "./components/PublisherAgreements";
import PublisherPrices from "./components/PublisherPrices";
import PublisherUsers from "./components/PublisherUsers";

export default function PublisherView({ publisher, organization }) {
	return (
		<PageView header={publisher.name}>
			{/*<PublisherInfo publisher={publisher} />*/}

			<PublisherPrices publisher={publisher} organization={organization} />

			<PublisherAgreements publisher={publisher} organization={organization} />

			<PublisherUsers publisher={publisher} />
		</PageView>
	);
}
