import { useMemo } from "react";

import productionStatuses from "astrid-firestore/src/api/productions/constants/productionStatuses";

import useProductionStatusTranslations from "./useProductionStatusTranslations";

export default function useProductionStatuses() {
	const translations = useProductionStatusTranslations();

	return useMemo(
		() => ({
			[productionStatuses.DRAFT]: {
				content: translations[productionStatuses.DRAFT],
				color: "grey",
			},
			[productionStatuses.OFFER]: {
				content: translations[productionStatuses.OFFER],
				color: "blue",
			},
			[productionStatuses.PLANNING]: {
				content: translations[productionStatuses.PLANNING],
				color: "blue",
			},
			[productionStatuses.ACCEPTED]: {
				content: translations[productionStatuses.ACCEPTED],
				color: "orange",
			},
			[productionStatuses.PRODUCTION]: {
				content: translations[productionStatuses.PRODUCTION],
				color: "orange",
			},
			[productionStatuses.DONE]: {
				content: translations[productionStatuses.DONE],
				color: "green",
			},
		}),
		[translations],
	);
}
