import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

import useArticleMetadataFields from "../../../../articles/hooks/useArticleMetadataFields";
import { getOrganizationPath } from "../../../../routing/utils/getOrganizationPath";

export function useImportArticleTableColumns() {
	const { t } = useTranslation();

	const metadataFields = useArticleMetadataFields();

	return ({ after }) => [
		{
			id: "id",
			Header: <Link to={getOrganizationPath(`/articles/${after.id}`)}>{after?.name}</Link>,
			accessor: ({ id }) => metadataFields[id]?.label,
		},
		{
			Header: t("before", "Before"),
			accessor: ({ id, before }) => metadataFields[id]?.accessor(before),
			Cell: ({ value }) => (value ? value : <span style={{ opacity: 0.3, fontStyle: "italic" }}>Empty</span>),
		},
		{
			Header: t("after", "After"),
			accessor: ({ id, after }) => metadataFields[id]?.accessor(after),
			Cell: ({ value }) => (value ? value : <span style={{ opacity: 0.3, fontStyle: "italic" }}>Empty</span>),
		},
	];
}
