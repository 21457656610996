import capitalize from "lodash/capitalize";
import upperCase from "lodash/upperCase";
import moment from "moment";
import "moment/dist/locale/en-gb";
import "moment/dist/locale/sv";

import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import { updateDocument } from "astrid-firestore/src/helpers";

import i18n from "../../../../../i18n";
import { getProfile } from "../../../../authentication/state/profile";
import DropdownMenu from "../../../../ui/components/DropdownMenu/DropdownMenu";
import Label from "../../../../ui/components/Label/Label";

const changeLanguage = (languageCode) => {
	return (e) => {
		e.preventDefault();
		i18n.changeLanguage(languageCode);
		document.body.click();
	};
};

export default function LanguageMenu() {
	const [languages, setLanguages] = useState();
	const { i18n } = useTranslation();

	useEffect(() => {
		// get languages from locize.com
		i18n.services.backendConnector.backend.getLanguages((error, langs) => {
			const isLive = window.location.hostname === "astrid.fm";
			const langArray = Object.entries(langs);

			// all languages on dev, stage and beta. only languages translated more that 90% on live.
			const availableLangs = isLive
				? langArray.filter(([code, lang]) => lang?.translated?.live > 0.9)
				: langArray;

			setLanguages(availableLangs);
		});
	}, [i18n]);

	useEffect(() => {
		moment.locale(i18n.language === "en" ? "en-gb" : i18n.language);

		const profile = getProfile();

		if (profile && profile.languageCode !== i18n.language) {
			updateDocument(profile.ref, { languageCode: i18n.language });
		}
	}, [i18n.language]);

	return (
		<DropdownMenu
			trigger={
				<Label size="tiny" horizontal style={{ display: "block", margin: 0 }}>
					{upperCase(i18n.language)}
				</Label>
			}
			icon={false}
			options={languages?.map(([code, lang]) => ({
				text: capitalize(lang.nativeName),
				active: i18n.language === code,
				onClick: changeLanguage(code),
			}))}
		/>
	);
}
