import { Outlet, useOutletContext } from "react-router-dom";

import Unauthorized from "../../../ui/components/Unauthorized/Unauthorized";

import useHasRight from "../../../authorization/hooks/useHasRight";

import OrganizationFinanceView from "../../views/OrganizationFinanceView/OrganizationFinanceView";

export default function OrganizationFinance() {
	const hasRight = useHasRight();

	const { organization } = useOutletContext();

	if (!hasRight("admin")) {
		return <Unauthorized />;
	}

	return (
		<OrganizationFinanceView organization={organization}>
			<Outlet context={{ organization }} />
		</OrganizationFinanceView>
	);
}
