import { useMemo } from "react";
import { useTranslation } from "react-i18next";

import productionTypes from "astrid-firestore/src/api/productions/constants/productionTypes";

export default function useProductionTypeTranslations() {
	const { t } = useTranslation();

	return useMemo(
		() => ({
			[productionTypes.INTERNAL]: t("internal", "Internal"),
			[productionTypes.EXTERNAL]: t("external", "External"),
			[productionTypes.BACKLIST]: t("backlist", "Backlist"),
			[productionTypes.SUBCONTRACT]: t("subcontract", "Subcontract"),

			// TODO: Remove this when the production types are fixed in algolia
			internal: t("internal", "Internal"),
			subcontractor: t("subcontract", "Subcontract"),
		}),
		[t],
	);
}
