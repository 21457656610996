import React from "react";
import { useTranslation } from "react-i18next";

import AccountLanguages from "astrid-web/src/features/account/views/AccountSettingsView/components/AccountLanguages";

import Anchor from "../../../ui/components/Anchor/Anchor";
import VerticalMenu from "../../../ui/components/VerticalMenu/VerticalMenu";
import PageView from "../../../ui/views/PageView/PageView";

import VendorSettings from "../../../vendors/components/VendorSettings/VendorSettings";

import AccountAudioSamples from "./components/AccountAudioSamples";
import AccountGeneral from "./components/AccountGeneral";
import AccountInfo from "./components/AccountInfo";
import AccountNarration from "./components/AccountNarration";
import useAccountSettingsMenuOptions from "./hooks/useAccountSettingsMenuOptions";

export default function AccountSettingsView({ user }) {
	const { t } = useTranslation();
	const options = useAccountSettingsMenuOptions();

	const isReader = user.permissions?.reader;

	return (
		<PageView header={t("settings", "Settings")} sidebar={<VerticalMenu options={options} />}>
			{user && (
				<>
					<AccountInfo user={user} />

					<Anchor id="general" />
					<AccountGeneral user={user} />

					<Anchor id="languages" />
					<AccountLanguages user={user} />

					{isReader && (
						<>
							<Anchor id="narration" />
							<AccountNarration user={user} />
						</>
					)}

					{isReader && (
						<>
							<Anchor id="samples" />
							<AccountAudioSamples user={user} />
						</>
					)}

					<Anchor id="vendors" />

					<VendorSettings uid={user.id} type="user" currentVendors={user.vendors} />
				</>
			)}
		</PageView>
	);
}
