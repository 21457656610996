import getExportPipeline from "astrid-web/src/features/articles/views/ArticleView/utils/getExportPipeline";

import createDocumentData from "../../utils/createDocumentData";

import { Export } from "./types/Export";

export default function createExport(firebase, article, data) {
	const now = new Date();
	const oneMonthLater = new Date(now.setMonth(now.getMonth() + 1));

	const exportDoc = Export.parse(
		createDocumentData(firebase, "exports", {
			...data,
			expires: firebase.firestore.Timestamp.fromDate(oneMonthLater),
			pipeline: getExportPipeline({ data }),
		}),
	);

	return exportDoc.ref.set(exportDoc);
}
