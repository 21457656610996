import { useState } from "react";

import ConfirmationModal from "./ConfirmationModal";

export default function ConfirmationModalTrigger({ onConfirm, header, text, trigger, disabled }) {
	const [open, setOpen] = useState(false);

	return (
		<>
			<trigger.type {...trigger.props} onClick={disabled ? onConfirm : () => setOpen(true)} />

			{open && (
				<ConfirmationModal header={header} text={text} onConfirm={onConfirm} onClose={() => setOpen(false)} />
			)}
		</>
	);
}
