import { useNavigate } from "react-router-dom";

import DataTable from "../../../ui/components/DataTable/DataTable";

import useApproveAgreementsColumns from "./hooks/useApproveAgreementsColumns";

export default function SuperAdminApproveAgreementsView({ agreements, loading, error }) {
	const navigate = useNavigate();
	const columns = useApproveAgreementsColumns();

	return (
		<DataTable
			data={agreements}
			error={error}
			loading={loading}
			columns={columns}
			onClick={({ id, firstParty }) => navigate(`/${firstParty.type}/${firstParty.id}/finance/agreements/${id}`)}
		/>
	);
}
