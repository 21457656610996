import firebase from "firebase/app";

import getDistributionChannelsQuery from "astrid-firestore/src/api/distribution/getDistributionChannelsQuery";
import getArticlePresetChannels from "astrid-firestore/src/api/distribution/utils/getArticlePresetChannels";
import useCollectionData from "astrid-firestore/src/hooks/useCollectionData";
import useDocumentData from "astrid-firestore/src/hooks/useDocumentData";
import useLoadingValues from "astrid-firestore/src/hooks/useLoadingValues";

export default function useArticlePresetChannels({ article }) {
	const [[publisher, allChannels], loading, error] = useLoadingValues(
		useDocumentData(article.publisher.ref),
		useCollectionData(getDistributionChannelsQuery(firebase)),
	);

	const articlePresetChannels = getArticlePresetChannels({ article, publisher, allChannels }) || [];

	return [articlePresetChannels, loading, error];
}
