import { useTranslation } from "react-i18next";

import articleDeliveryStatuses from "astrid-firestore/src/api/articles/constants/articleDeliveryStatuses";
import { languageName } from "astrid-helpers/src/languages";

import useArticleStatusTranslations from "./useArticleStatusTranslations";
import useArticleTypeTranslations from "./useArticleTypeTranslations";

export default function useArticlesRefinements({ organization }) {
	const { t } = useTranslation();

	const articleTypeTranslations = useArticleTypeTranslations();
	const articleStatusTranslations = useArticleStatusTranslations();

	return [
		{
			header: t("type", "Type"),
			attribute: "type",
			renderItem: ({ label }) => articleTypeTranslations[label],
		},
		{
			header: t("status", "Status"),
			attribute: "status",
			collapsed: true,
			renderItem: ({ label }) => articleStatusTranslations[label],
		},
		{
			header: t("deliveryStatus", "Delivery status") + ` (${t("meta", "Meta")})`,
			attribute: "delivery.metadata.status",
			collapsed: true,
			groupItems: [
				{
					label: t("deliveryNeeded", "Delivery needed"),
					values: [articleDeliveryStatuses.DELIVERY_NEEDED, articleDeliveryStatuses.DELIVERY_FAILED],
				},
				{
					label: t("scheduledPlural", "Scheduled"),
					values: [articleDeliveryStatuses.DELIVERY_PENDING, articleDeliveryStatuses.DELIVERY_IN_PROGRESS],
				},
				{
					label: t("deliveredPlural", "Delivered"),
					values: [articleDeliveryStatuses.DELIVERY_COMPLETED],
				},
				{
					label: t("notDeliveredPlural", "Not delivered"),
					values: [
						articleDeliveryStatuses.DELIVERY_UNSUPPORTED,
						articleDeliveryStatuses.DELIVERY_NOT_STARTED,
					],
				},
			],
		},
		{
			header: t("deliveryStatus", "Delivery status") + ` (${t("files", "Files")})`,
			attribute: "delivery.artifact.status",
			collapsed: true,
			groupItems: [
				{
					label: t("deliveryNeeded", "Delivery needed"),
					values: [articleDeliveryStatuses.DELIVERY_NEEDED, articleDeliveryStatuses.DELIVERY_FAILED],
				},
				{
					label: t("scheduledPlural", "Scheduled"),
					values: [articleDeliveryStatuses.DELIVERY_PENDING, articleDeliveryStatuses.DELIVERY_IN_PROGRESS],
				},
				{
					label: t("deliveredPlural", "Delivered"),
					values: [articleDeliveryStatuses.DELIVERY_COMPLETED],
				},
				{
					label: t("notDeliveredPlural", "Not delivered"),
					values: [
						articleDeliveryStatuses.DELIVERY_UNSUPPORTED,
						articleDeliveryStatuses.DELIVERY_NOT_STARTED,
					],
				},
			],
		},
		{
			header: t("publisher", "Publisher"),
			attribute: "publisher.name",
			searchable: true,
			collapsed: true,
			hidden: !organization,
		},
		{
			header: t("imprint", "Imprint"),
			attribute: "imprint.name",
			searchable: true,
			collapsed: true,
		},
		{
			header: t("language", "Language"),
			attribute: "language",
			searchable: true,
			collapsed: true,
			renderItem: ({ label }) => languageName(label),
		},
		{
			header: t("author", "Author"),
			attribute: "authors",
			searchable: true,
			collapsed: true,
		},
		{
			header: t("narrator", "Narrator"),
			attribute: "narrators",
			searchable: true,
			collapsed: true,
		},
		{
			header: t("translator", "Translator"),
			attribute: "translators",
			searchable: true,
			collapsed: true,
		},
		{
			header: t("serie", "Series"),
			attribute: "serie.name",
			searchable: true,
			collapsed: true,
		},
		{
			header: t("tags", "Tags"),
			attribute: "tags",
			searchable: true,
			collapsed: true,
		},
		{
			header: t("created", "Created"),
			attribute: "created._seconds",
			type: "dateRange",
			collapsed: true,
		},
		{
			header: t("releaseDate", "Release date"),
			attribute: "releaseDates",
			type: "dateRange",
			collapsed: true,
		},
		{
			header: t("takedownDate", "Takedown date"),
			attribute: "takedownDates",
			type: "dateRange",
			collapsed: true,
		},
		{
			header: t("announcementDate", "Announcement date"),
			attribute: "announcementDates",
			type: "dateRange",
			collapsed: true,
		},
	];
}
