import equal from "fast-deep-equal";

import React from "react";
import { useTranslation } from "react-i18next";

import { db, firebase } from "astrid-firebase";
import { createImprintData } from "astrid-firestore/src/api/imprints/createImprintData";

import Form from "../../../../forms/components/Form/Form";

import ArticleMetaDataLabel from "./ArticleMetaDataLabel";

export default function ArticleMetaDataImprintSelect({ work, article, ...props }) {
	const { t } = useTranslation();

	return (
		<Form.CollectionSelect
			name="imprint"
			label={
				<ArticleMetaDataLabel
					text={t("imprint", "Imprint")}
					condition={work && !equal(article.imprint, work.imprint)}
				/>
			}
			query={db
				.collection("imprints")
				.where("publisher.id", "==", article.publisher.id)
				.where("deleted", "==", false)}
			onAddDocument={(name) => {
				return createImprintData(firebase, { name });
			}}
			allowAdditions
			allowEmpty
			search
			{...props}
		/>
	);
}
