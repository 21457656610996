import { Label } from "semantic-ui-react";

import { useTranslation } from "react-i18next";

import Flex from "../../../../../components/Flex/Flex";

export default function CalendarStudioResourceTitle({ resource }) {
	const { t } = useTranslation();
	const { studio } = resource.extendedProps;

	if (!studio) {
		return t("noStudio", "No studio");
	}

	return (
		<Flex style={{ justifyContent: "flex-start", display: "inline-flex", gap: 5 }}>
			<Label circular style={{ backgroundColor: studio.color }} empty /> <div>{studio.name}</div>
			{studio.version && <div>({studio.version})</div>}
		</Flex>
	);
}
