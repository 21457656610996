import runTransaction from "../../utils/runTransaction";

import articleDeliveryStatuses from "../articles/constants/articleDeliveryStatuses";
import artifactStatuses from "../artifacts/constants/artifactStatuses";

import { createDistributionData } from "./createDistributionData";
import getTriggerDistributionQueueData from "./getTriggerDistributionQueueData";
import { DistributionRef } from "./types/DistributionRef";
import { canDeliverArticleToChannel } from "./utils/deliverArticlesToChannels";
import getArticlePresetChannels from "./utils/getArticlePresetChannels";

function deliveryInProgress(delivery) {
	return [articleDeliveryStatuses.DELIVERY_PENDING, articleDeliveryStatuses.DELIVERY_IN_PROGRESS].includes(
		delivery.status,
	);
}

async function validateDistribution({ article, channel, delivery }) {
	const { metadata: orderedDeliveryOfMetadata, artifact: orderedDeliveryOfArtifact } = delivery;
	const { metadata, artifact } = article?.channels?.[channel.id]?.delivery || {};

	if (orderedDeliveryOfMetadata) {
		if (deliveryInProgress(metadata)) {
			throw new Error(`Delivery of metadata is already in progress for ${channel.name}`);
		}
	}

	if (orderedDeliveryOfArtifact) {
		const theArtifact = article.artifact?.ref && (await article.artifact?.ref?.get());
		const artifactStatus = theArtifact?.data?.()?.status;

		if (artifactStatus === artifactStatuses.PENDING) {
			throw new Error(
				"The artifact for this article is not ready for distribution. We are currently validating it. Please try again later.",
			);
		}

		if ([artifactStatuses.FAILED, artifactStatuses.INVALID].includes(artifactStatus)) {
			throw new Error("The artifact for this article is not valid for distribution.");
		}

		if (deliveryInProgress(artifact)) {
			throw new Error(`Delivery of artifact is already in progress for ${channel.name}`);
		}
	}
}

export default async function distributeArticles(firebase, { articles, channels, delivery }) {
	const { publisher } = articles[0];
	return runTransaction(
		firebase,
		[publisher, articles, channels],
		async (transaction, [publisher, articles, channels]) => {
			let distributionDocs = [];
			for (const article of articles) {
				const presetChannels = getArticlePresetChannels({
					article,
					publisher,
					allChannels: channels,
				});
				const distributionChannels = presetChannels?.filter((channel) =>
					canDeliverArticleToChannel({ article, channel }),
				);
				for (const channel of distributionChannels) {
					if (Object.values(delivery[channel.id]).some(Boolean)) {
						await validateDistribution({ article, channel, delivery: delivery[channel.id] });

						const distribution = createDistributionData(firebase, {
							publisher,
							article,
							channel,
							delivery: delivery[channel.id],
						});
						distributionDocs.push(distribution);

						transaction.set(distribution.ref, distribution);

						// Link the distribution document to the article channels and set the delivery status to pending
						transaction.update(
							article.ref,
							Object.entries(delivery[channel.id])
								.filter(([, value]) => value)
								.reduce(
									(acc, [key]) => ({
										...acc,
										[`channels.${channel.id}.delivery.${key}.distribution`]:
											DistributionRef.parse(distribution),
										[`channels.${channel.id}.delivery.${key}.status`]:
											articleDeliveryStatuses.DELIVERY_PENDING,
									}),
									{},
								),
						);

						// trigger check of distribution queue
						const triggerDistributionQueueData = getTriggerDistributionQueueData(firebase);
						transaction.update(triggerDistributionQueueData.ref, triggerDistributionQueueData);
					}
				}
			}
			return distributionDocs;
		},
	);
}
