import React, { useState } from "react";
import { useTranslation } from "react-i18next";

import AsyncButton from "../../../ui/components/AsyncButton/AsyncButton";
import Button from "../../../ui/components/Buttons/Button";
import ConfirmationButton from "../../../ui/components/ConfirmationButton/ConfirmationButton";
import Toast from "../../../ui/components/Toast/Toast";

import Form from "../../../forms/components/Form/Form";

import ArtifactAudioPlayer from "../../components/ArtifactAudioPlayer/ArtifactAudioPlayer";

import ArtifactFilesDataTable from "../ArtifactFilesDataTable.jsx/ArtifactFilesDataTable";
import CreateArtifactForm from "../CreateArtifactForm/CreateArtifactForm";

import useArtifactManagerFiles from "./hooks/useArtifactManagerFiles";
import useArtifactManagerForm from "./hooks/useArtifactManagerForm";
import useArtifactManagerMarkers from "./hooks/useArtifactManagerMarkers";

export default function ArtifactManager({ artifact }) {
	const { t } = useTranslation();

	const [selectedFiles, setSelectedFiles] = useState([]);
	const [showUploadModal, setShowUploadModal] = useState(false);

	const { form, formState, onSubmit, onReset } = useArtifactManagerForm({
		artifact,
	});

	const { markers, onAddMarker, onUpdateMarker, onRemoveMarker } = useArtifactManagerMarkers({ form });

	const {
		files,
		isFilesSorted,
		hasAudioFiles,
		onAddFiles,
		onSortFiles,
		onReorderFiles,
		onDownloadFiles,
		onDeleteFiles,
	} = useArtifactManagerFiles({
		form,
		artifact,
	});

	return (
		<Form form={form}>
			{hasAudioFiles && (
				<ArtifactAudioPlayer
					files={files}
					markers={markers}
					onAddMarker={onAddMarker}
					onUpdateMarker={onUpdateMarker}
					onRemoveMarker={onRemoveMarker}
				/>
			)}

			<ArtifactFilesDataTable
				artifact={artifact}
				files={files}
				onReorder={onReorderFiles}
				onSelect={setSelectedFiles}
				footer={
					<>
						<AsyncButton onClick={() => setShowUploadModal(true)} content={t("add", "Add")} />

						<Button onClick={onSortFiles} disabled={isFilesSorted}>
							{t("sort", "Sort")}
						</Button>

						<Button onClick={onDownloadFiles} disabled={selectedFiles.length === 0}>
							{t("download", "Download")}
						</Button>

						<ConfirmationButton
							header={t("delete", "Delete")}
							text={selectedFiles?.map((artifact) => artifact.name).join("\n")}
							negative={selectedFiles.length > 0}
							disabled={selectedFiles.length === 0}
							onConfirm={() => onDeleteFiles(selectedFiles)}
						>
							{t("delete", "Delete")}
						</ConfirmationButton>
					</>
				}
			/>

			<Toast open={formState.isDirty}>
				<Button onClick={onReset} disabled={!formState.isDirty || formState.isSubmitting}>
					{t("reset", "Reset")}
				</Button>

				<Button primary onClick={onSubmit} disabled={!formState.isDirty || formState.isSubmitting}>
					{t("saveChanges", "Save changes")}
				</Button>
			</Toast>

			{showUploadModal && (
				<CreateArtifactForm
					auto
					bucketName="earselect-artifacts"
					bucketPath={artifact.id}
					onSuccess={onAddFiles}
					onClose={() => setShowUploadModal(false)}
				/>
			)}

			<Form.Blocker />
		</Form>
	);
}
