import { useNavigate } from "react-router-dom";

import CollectionDataTable from "../../../ui/components/CollectionDataTable/CollectionDataTable";

import AdminHeader from "../../../admin/components/AdminHeader/AdminHeader";

import useOrdersQuery from "../../hooks/useOrdersQuery";

import useOrdersTableColumns from "./hooks/useOrdersTableColumns";

export default function OrdersView({ header, expense, firstPartyId }) {
	const navigate = useNavigate();

	const query = useOrdersQuery({ expense, firstPartyId });
	const columns = useOrdersTableColumns();

	return (
		<>
			<AdminHeader>{header}</AdminHeader>

			<CollectionDataTable
				persist
				filterable
				query={query}
				columns={columns}
				onClick={({ id }) => navigate(id)}
				sortable
				sortBy={{
					id: "created",
					desc: true,
				}}
			/>
		</>
	);
}
