import { Checkbox } from "semantic-ui-react";

import React, { useContext } from "react";

import TableContext from "./TableContext";
import TableHeaderCell from "./TableHeaderCell";

export default function TableHeaderCellCheckbox() {
	const { data, selectedRowIds, setSelectedRowIds } = useContext(TableContext);

	return (
		<TableHeaderCell
			first
			sticky
			collapsing
			onClick={() => {
				setSelectedRowIds((selectedRowIds) =>
					selectedRowIds.length === data.length ? [] : data.map(({ id }) => id),
				);
			}}
		>
			<Checkbox
				checked={selectedRowIds.length === data.length}
				indeterminate={selectedRowIds.length > 0 && selectedRowIds.length < data.length}
				// onChange={(_, { checked }) => {
				// 	setSelectedRowIds(checked ? data.map(({ id }) => id) : []);
				// }}
			/>
		</TableHeaderCell>
	);
}
