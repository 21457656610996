import equal from "fast-deep-equal";

import React from "react";
import { useTranslation } from "react-i18next";

import Form from "../../../../forms/components/Form/Form";

import useArticleContributorRolesOptions from "../../../hooks/useArticleContributorRolesOptions";

import ArticleMetaDataLabel from "./ArticleMetaDataLabel";

export default function ArticleMetaDataContributors({ work, article, ...props }) {
	const { t } = useTranslation();

	const contributorRolesOptions = useArticleContributorRolesOptions();

	return (
		<Form.FormFieldArrayTable
			name="contributors"
			header={
				<ArticleMetaDataLabel
					text={t("contributors", "Contributors")}
					condition={work && !equal(article.contributors, work.contributors)}
				/>
			}
			{...props}
		>
			{({ role, firstName, lastName }, index) => (
				<Form.Group widths="equal" style={{ margin: 0 }}>
					<Form.Select
						name={`contributors.${index}.role`}
						value={role}
						placeholder={t("role", "Role")}
						options={contributorRolesOptions}
					/>
					<Form.Input
						name={`contributors.${index}.firstName`}
						value={firstName}
						placeholder={t("firstName", "First name")}
					/>
					<Form.Input
						name={`contributors.${index}.lastName`}
						value={lastName}
						placeholder={t("lastName", "Last name")}
					/>
				</Form.Group>
			)}
		</Form.FormFieldArrayTable>
	);
}
