import { useMemo } from "react";

import productionTypes from "astrid-firestore/src/api/productions/constants/productionTypes";

import useProductionTypeTranslations from "./useProductionTypeTranslations";

export default function useProductionTypeOptions() {
	const productionTypeTranslations = useProductionTypeTranslations();

	return useMemo(() => {
		return [
			{
				key: productionTypes.INTERNAL,
				value: productionTypes.INTERNAL,
				text: productionTypeTranslations[productionTypes.INTERNAL],
			},
			{
				key: productionTypes.BACKLIST,
				value: productionTypes.BACKLIST,
				text: productionTypeTranslations[productionTypes.BACKLIST],
			},
			{
				key: productionTypes.EXTERNAL,
				value: productionTypes.EXTERNAL,
				text: productionTypeTranslations[productionTypes.EXTERNAL],
			},
			{
				key: productionTypes.SUBCONTRACT,
				value: productionTypes.SUBCONTRACT,
				text: productionTypeTranslations[productionTypes.SUBCONTRACT],
			},
		];
	}, [productionTypeTranslations]);
}
