import FirestoreDocument from "../../../types/FirestoreDocument";
import FirestoreLifecycle from "../../../types/FirestoreLifecycle";
import { enumValues, nullable, string, timestamp } from "../../../types/_types";

import { ArticleRef } from "../../articles/types/ArticleRef";
import distributionStatuses from "../../distribution/constants/distributionStatuses";
import { Pipeline } from "../../pipelines/types/Pipeline";

const exportStatus = enumValues(distributionStatuses).default(distributionStatuses.PENDING);

export const Export = FirestoreDocument.extend({
	article: ArticleRef,
	error: nullable(string()),
	pipeline: Pipeline,
	status: exportStatus,
	expires: timestamp(),
	startedAt: nullable(timestamp()),
	stoppedAt: nullable(timestamp()),
}).merge(FirestoreLifecycle);
