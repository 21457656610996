import { Button, Icon } from "semantic-ui-react";

export default function CalendarViewTypeButton({ children, viewPeriod, navigation, viewType, disableTools }) {
	const resourceView = `resourceTimeline${viewPeriod}`;
	const gridView = `timeGrid${viewPeriod}`;

	return (
		<Button.Group basic size="tiny" style={{ marginRight: 5 }}>
			<Button
				onClick={() => navigation.changeView(disableTools ? gridView : resourceView)}
				active={viewType.includes(viewPeriod)}
			>
				{children}
			</Button>

			{!disableTools && (
				<>
					<Button
						icon
						onClick={() => navigation.changeView(resourceView)}
						active={viewType === resourceView ? "blue" : undefined}
					>
						<Icon name="bars" />
					</Button>

					<Button icon onClick={() => navigation.changeView(gridView)} active={viewType === gridView}>
						<Icon name="th" />
					</Button>
				</>
			)}
		</Button.Group>
	);
}
