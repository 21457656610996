import {
	AudioMp3StorageFile,
	AudioWavStorageFile,
	EpubStorageFile,
	StorageFile,
} from "astrid-firestore/src/types/StorageFile";

export default function createStorageFile({ file, metadata }) {
	const { name, fullPath, url, size, duration } = file;

	switch (metadata.contentType) {
		case "audio/wav":
			return AudioWavStorageFile.parse({
				...metadata,
				name,
				fullPath,
				url,
				size,
				duration,
			});
		case "audio/mp3":
			return AudioMp3StorageFile.parse({
				...metadata,
				name,
				fullPath,
				url,
				size,
				duration,
			});
		case "application/epub+zip":
			return EpubStorageFile.parse({
				...metadata,
				name,
				fullPath,
				url,
				size,
			});
		default:
			return StorageFile.parse({
				...metadata,
				fullPath,
				url,
				size,
			});
	}
}
