import { Divider, Label, Popup } from "semantic-ui-react";

import React from "react";
import { useTranslation } from "react-i18next";

import Flex from "../../../../../components/Flex/Flex";
import useArticleContributorRolesTranslations from "../../../../articles/hooks/useArticleContributorRolesTranslations";

export default function WorkPresentationContributors({ value }) {
	const { t } = useTranslation();

	const contributorTranslations = useArticleContributorRolesTranslations();

	if (value.length === 0) {
		return null;
	}

	return (
		<>
			<Divider />

			<strong>{t("contributors")}</strong>

			<Flex style={{ marginTop: 10 }} justifyContent="flex-start" gap={5}>
				{value?.map(({ role, firstName, lastName }) => (
					<Popup
						key={role}
						trigger={
							<Label basic style={{ marginLeft: 0 }}>
								{firstName + " " + lastName}
							</Label>
						}
						content={contributorTranslations[role]}
						size="tiny"
						position="top center"
					/>
				))}
			</Flex>
		</>
	);
}
