import firebase from "firebase/app";

import { useTranslation } from "react-i18next";

import distributionStatuses from "astrid-firestore/src/api/distribution/constants/distributionStatuses";
import softDeleteDocument from "astrid-firestore/src/api/documents/softDeleteDocument";

import { useProfile } from "../../authentication/state/profile";
import { getOrganizationPath } from "../../routing/utils/getOrganizationPath";

const retryPipeline = firebase.functions().httpsCallable("distribution-retryPipeline");

export default function useDistributionDropdownMenuOptions(distribution) {
	const user = useProfile();

	const { t } = useTranslation();

	return [
		{
			text: t("view", "View"),
			linkTo: getOrganizationPath(`/distributions/${distribution.id}`),
		},
		{
			text: t("viewArticle", "View article"),
			linkTo: getOrganizationPath(`/articles/${distribution.article.id}`),
		},
		{
			text: t("retry", "Retry"),
			hidden: !distribution?.jobId || distribution?.status !== distributionStatuses.FAILED,
			action: () => {
				return retryPipeline({ collectionId: "distributions", documentId: distribution.id });
			},
		},
		{
			text: t("delete", "Delete"),
			action: () => {
				return softDeleteDocument(firebase, distribution, user);
			},
		},
	];
}
