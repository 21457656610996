import { Icon } from "semantic-ui-react";

import React from "react";
import { useTranslation } from "react-i18next";
import { useSortBy } from "react-instantsearch";

import Button from "../Buttons/Button";
import DropdownMenu from "../DropdownMenu/DropdownMenu";

export default function InstantSearchSorting({ items }) {
	const { t } = useTranslation();

	const { options, refine, currentRefinement } = useSortBy({ items });

	const currentText = options.find((option) => option.value === currentRefinement)?.text;

	return (
		<DropdownMenu
			options={options.map((option) => ({
				...option,
				active: currentRefinement === option.value,
				onClick: () => refine(option.value),
			}))}
			trigger={
				<Button basic circular size="small" active={currentRefinement !== options[0].value}>
					{t("sorting", "Sorting")}: {currentText}
					<Icon size="small" name="chevron down" fitted style={{ paddingLeft: 5 }} />
				</Button>
			}
		/>
	);
}
