import { useTranslation } from "react-i18next";

import articleStatuses from "astrid-firestore/src/api/articles/constants/articleStatuses";

import OpenButton from "../../../ui/components/OpenButton/OpenButton";

import DistributeArticles from "./DistributeArticles";
import DistributeArticlesDraftForm from "./DistributeArticlesDraftForm";

export default function DistributeArticlesButton({ articles, onSuccess, ...props }) {
	const { t } = useTranslation();

	const article = articles[0];

	return (
		<OpenButton text={t("distribute", "Distribute")} disabled={articles.length === 0} {...props}>
			{({ setOpen }) => {
				if (article.status === articleStatuses.DRAFT) {
					return <DistributeArticlesDraftForm article={article} onClose={() => setOpen(false)} />;
				} else {
					return (
						<DistributeArticles articles={articles} onClose={() => setOpen(false)} onSuccess={onSuccess} />
					);
				}
			}}
		</OpenButton>
	);
}
