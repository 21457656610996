import { Table } from "semantic-ui-react";

import Flex from "../../../components/Flex/Flex";

import getCellStyle from "../utils/getCellStyle";

export default function TableCell({
	first,
	sticky,
	noWrap,
	children,
	collapsing,
	onClick,
	textAlign = "left",
	verticalAlign = "middle",
	...props
}) {
	return (
		<Table.Cell
			onClick={onClick}
			textAlign={textAlign}
			collapsing={collapsing}
			verticalAlign={verticalAlign}
			style={getCellStyle({ first, sticky, noWrap, onClick })}
			{...props}
		>
			<Flex style={{ gap: 15, alignItems: "center" }}>{children}</Flex>
		</Table.Cell>
	);
}
