import firebase from "firebase";

import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";

import createArtifact from "astrid-firestore/src/api/artifacts/createArtifact";

import OpenButton from "../../../ui/components/OpenButton/OpenButton";

import { useProfile } from "../../../authentication/state/profile";

import CreateArtifactForm from "./CreateArtifactForm";

export default function CreateArtifactButton({ work, article, publisher, production, ...props }) {
	const user = useProfile();

	const { t } = useTranslation();

	const { id } = useMemo(() => firebase.firestore().collection("artifacts").doc(), []);

	const onSuccess = (files) => {
		createArtifact(firebase, {
			id,
			files,
			name: id,
			createdBy: user,
			work: work || article?.work || null,
			article: article || null,
			publisher: publisher || null,
			production: production || null,
		});
	};

	return (
		<OpenButton text={t("uploadFiles", "Upload files")} {...props}>
			{({ setOpen }) => (
				<CreateArtifactForm
					auto
					bucketName="earselect-artifacts"
					bucketPath={id}
					onSuccess={onSuccess}
					onClose={() => setOpen(false)}
				/>
			)}
		</OpenButton>
	);
}
