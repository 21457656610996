import uniqid from "uniqid";

export default function useArtifactManagerMarkers({ form }) {
	const { watch, setValue } = form;

	const markers = watch("markers");

	const onAddMarker = ({ type, position }) => {
		setValue(
			"markers",
			[
				...markers,
				{
					id: uniqid(),
					type,
					position,
					text: "",
				},
			].sort((a, b) => a.position - b.position),
			{ shouldDirty: true },
		);
	};

	const onUpdateMarker = ({ marker, position }) => {
		setValue(
			"markers",
			markers.map((m) => (m.id === marker.id ? { ...m, position } : m)).sort((a, b) => a.position - b.position),
			{ shouldDirty: true },
		);
	};

	const onRemoveMarker = ({ marker }) => {
		setValue(
			"markers",
			markers.filter((m) => m.id !== marker.id),
			{ shouldDirty: true },
		);
	};

	return {
		markers,
		onAddMarker,
		onUpdateMarker,
		onRemoveMarker,
	};
}
