import { useMemo } from "react";

import productionStatuses from "astrid-firestore/src/api/productions/constants/productionStatuses";

import useProductionStatusTranslations from "./useProductionStatusTranslations";

export default function useProductionStatusOptions() {
	const productionStatusTranslations = useProductionStatusTranslations();

	return useMemo(() => {
		return [
			{
				key: productionStatuses.DRAFT,
				value: productionStatuses.DRAFT,
				text: productionStatusTranslations[productionStatuses.DRAFT],
			},
			{
				key: productionStatuses.OFFER,
				value: productionStatuses.OFFER,
				text: productionStatusTranslations[productionStatuses.OFFER],
			},
			{
				key: productionStatuses.PLANNING,
				value: productionStatuses.PLANNING,
				text: productionStatusTranslations[productionStatuses.PLANNING],
			},
			{
				key: productionStatuses.ACCEPTED,
				value: productionStatuses.ACCEPTED,
				text: productionStatusTranslations[productionStatuses.ACCEPTED],
			},
			{
				key: productionStatuses.PRODUCTION,
				value: productionStatuses.PRODUCTION,
				text: productionStatusTranslations[productionStatuses.PRODUCTION],
			},
			{
				key: productionStatuses.DONE,
				value: productionStatuses.DONE,
				text: productionStatusTranslations[productionStatuses.DONE],
			},
		];
	}, [productionStatusTranslations]);
}
