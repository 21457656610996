import { useTranslation } from "react-i18next";
import { useRefinementList } from "react-instantsearch";

import articleDeliveryStatuses from "astrid-firestore/src/api/articles/constants/articleDeliveryStatuses";
import articleStatuses from "astrid-firestore/src/api/articles/constants/articleStatuses";

import themeColors from "../../ui/constants/themeColors";

export default function usePublisherDashboardStatistics() {
	const { t } = useTranslation();

	const statusRefinement = useRefinementList({
		attribute: "status",
	});

	const deliveryStatusRefinement = useRefinementList({
		attribute: "delivery.metadata.status",
	});

	const counts = [...statusRefinement.items, ...deliveryStatusRefinement.items].reduce(
		(acc, item) => ({
			...acc,
			[item.value]: item.count,
		}),
		{
			[articleDeliveryStatuses.DELIVERY_COMPLETED]: 0,
			[articleDeliveryStatuses.DELIVERY_NEEDED]: 0,
			[articleDeliveryStatuses.DELIVERY_FAILED]: 0,
			[articleDeliveryStatuses.DELIVERY_PENDING]: 0,
			[articleDeliveryStatuses.DELIVERY_IN_PROGRESS]: 0,
			[articleStatuses.DRAFT]: 0,
			[articleDeliveryStatuses.DELIVERY_NOT_STARTED]: 0,
		},
	);

	const statistics = [
		{
			total: counts[articleDeliveryStatuses.DELIVERY_COMPLETED] || 0,
			label: t("deliveredPlural", "Delivered"),
			color: "green",
			detailColor: themeColors.GREEN,
			link: `articles?articles%5BrefinementList%5D%5Bdelivery.metadata.status%5D%5B0%5D=${articleDeliveryStatuses.DELIVERY_COMPLETED}`,
		},
		{
			total:
				counts[articleDeliveryStatuses.DELIVERY_NEEDED] + counts[articleDeliveryStatuses.DELIVERY_FAILED] || 0,
			label: t("deliveryNeeded", "Delivery needed"),
			color: "orange",
			detailColor: themeColors.ORANGE,
			link: `articles?articles%5BrefinementList%5D%5Bdelivery.metadata.status%5D%5B0%5D=${articleDeliveryStatuses.DELIVERY_NEEDED}&articles%5BrefinementList%5D%5Bdelivery.metadata.status%5D%5B1%5D=${articleDeliveryStatuses.DELIVERY_FAILED}`,
		},
		{
			total:
				counts[articleDeliveryStatuses.DELIVERY_PENDING] +
					counts[articleDeliveryStatuses.DELIVERY_IN_PROGRESS] || 0,
			label: t("scheduled", "Scheduled"),
			color: "blue",
			detailColor: themeColors.BLUE,
			link: `articles?articles%5BrefinementList%5D%5Bdelivery.metadata.status%5D%5B0%5D=${articleDeliveryStatuses.DELIVERY_PENDING}&articles%5BrefinementList%5D%5Bdelivery.metadata.status%5D%5B1%5D=${articleDeliveryStatuses.DELIVERY_IN_PROGRESS}`,
		},
		{
			total: counts[articleStatuses.DRAFT] || 0,
			label: t("drafts", "Drafts"),
			color: "grey",
			detailColor: themeColors.GREY,
			link: `articles?articles%5BrefinementList%5D%5Bstatus%5D%5B0%5D=${articleStatuses.DRAFT}`,
		},
		{
			total: counts[articleDeliveryStatuses.DELIVERY_NOT_STARTED] || 0,
			label: t("notDeliveredPlural", "Not delivered"),
			color: "grey",
			detailColor: themeColors.GREY,
			link: `articles?articles%5BrefinementList%5D%5Bdelivery.metadata.status%5D%5B0%5D=${articleDeliveryStatuses.DELIVERY_NOT_STARTED}`,
		},
	];

	return statistics;
}
