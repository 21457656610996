import equal from "fast-deep-equal";

import articleDeliveryStatuses from "../constants/articleDeliveryStatuses";
import { ArticleChannelMetaData } from "../types/ArticleChannel";

import getUpdatedArticleDeliveryTypes from "./getUpdatedArticleDeliveryTypes";

const staleArticleDeliveryStatuses = [
	undefined,
	articleDeliveryStatuses.DELIVERY_UNSUPPORTED,
	articleDeliveryStatuses.DELIVERY_NOT_STARTED,
	articleDeliveryStatuses.DELIVERY_NEEDED,
];

export default function getArticleDeliveryNeededData(article, data, artifactIsUpdated = false) {
	const { deliveryTypes, updatedKeys } = getUpdatedArticleDeliveryTypes(article, data, artifactIsUpdated);

	if (deliveryTypes.length === 0) {
		return;
	}

	const dataToUpdate = deliveryTypes.reduce((acc, deliveryType) => {
		const channelIds = Object.entries(article.channels || {})
			// Only update channels that are not in a stale status
			.filter(([, { delivery }]) => !staleArticleDeliveryStatuses.includes(delivery?.[deliveryType]?.status))
			.filter(([channelId, channelData]) => {
				// If change of channel specific metadata, only update the channels that have changed (e.g countries or prices)
				if (updatedKeys.length === 1 && updatedKeys[0] === "channels") {
					const channelMetaDataBefore = ArticleChannelMetaData.parse(channelData);
					const channelMetaDataAfter = ArticleChannelMetaData.parse(data.channels?.[channelId] || {});

					const updatedKeys = Object.keys(channelMetaDataAfter).filter(
						(key) => !equal(channelMetaDataBefore[key], channelMetaDataAfter[key]),
					);

					return updatedKeys.length > 0;
				}

				return true;
			})

			.map(([channelId]) => channelId);

		if (channelIds.length === 0) {
			return acc;
		}

		const articleDeliveryStatus = [
			articleDeliveryStatuses.DELIVERY_IN_PROGRESS,
			articleDeliveryStatuses.DELIVERY_FAILED,
		].includes(article.delivery[deliveryType].status)
			? article.delivery[deliveryType].status
			: articleDeliveryStatuses.DELIVERY_NEEDED;

		return channelIds.reduce(
			(acc, channelId) => {
				return {
					...acc,
					[`channels.${channelId}.delivery.${deliveryType}.status`]: articleDeliveryStatuses.DELIVERY_NEEDED,
				};
			},
			{
				...acc,
				[`delivery.${deliveryType}.status`]: articleDeliveryStatus,
				[`delivery.${deliveryType}.updatedAt`]: new Date(),
			},
		);
	}, {});

	if (Object.keys(dataToUpdate).length === 0) {
		return;
	}

	return dataToUpdate;
}
