import firebase from "firebase";

import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";

import { db } from "astrid-firebase";
import getArticlesQuery from "astrid-firestore/src/api/articles/getArticlesQuery";
import getArtifactsQuery from "astrid-firestore/src/api/artifacts/getArtifactsQuery";
import { useCollectionCount } from "astrid-firestore/src/hooks";

export default function useArticleMenuOptions({ article }) {
	const { t } = useTranslation();

	const location = useLocation();

	const { pathname } = location;

	const [artifactsCount] = useCollectionCount(getArtifactsQuery(firebase, { article }));
	const [importsCount] = useCollectionCount(db.collection("imports").where("article.id", "==", article.id));
	const [bundleCount] = useCollectionCount(article.work && getArticlesQuery(firebase, { work: article.work }));
	const [productionsCount] = useCollectionCount(
		db.collection("productions").where("articleIds", "array-contains", article.id).where("deleted", "==", false),
	);

	return [
		{
			to: "",
			active: pathname.endsWith(article.id),
			text: t("metaData", "Metadata"),
		},
		{
			to: "artifacts",
			active: pathname.endsWith("/artifacts"),
			text: t("files", "Files") + (artifactsCount ? ` (${artifactsCount})` : ""),
		},
		{
			to: "imports",
			active: pathname.endsWith("/imports"),
			text: t("imports", "Imports") + (importsCount ? ` (${importsCount})` : ""),
		},
		{
			to: "bundle",
			active: pathname.endsWith("/bundle"),
			text: t("relatedArticles", "Related articles") + (bundleCount ? ` (${bundleCount})` : ""),
			hidden: !article.work,
		},
		{
			to: "distributions",
			active: pathname.endsWith("/distributions"),
			text: t("distributions", "Distributions"),
		},
		{
			to: "productions",
			active: pathname.endsWith("/productions"),
			text: t("productions", "Productions") + (productionsCount ? ` (${productionsCount})` : ""),
		},
		{
			to: "versions",
			active: pathname.endsWith("/versions"),
			text: t("versions", "Versions"),
		},
		{
			to: "exports",
			active: pathname.endsWith("/exports"),
			text: t("exports", "Exports"),
		},
	];
}
