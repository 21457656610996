import { useMemo } from "react";
import { useTranslation } from "react-i18next";

export default function useProductionOfferTypes() {
	const { t } = useTranslation();

	return useMemo(
		() => ({
			default: t("offerProducer"),
			subcontract: t("offerSubcontractor"),
		}),
		[t],
	);
}
