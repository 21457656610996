import { getDocumentData } from "astrid-firestore/src/helpers";

export default async function fetchMetadata({ document }) {
	const { article } = document;
	const linkedArticle = await getDocumentData(article.linkedArticle?.ref);

	return {
		...document,
		linkedArticle: linkedArticle || null,
	};
}
