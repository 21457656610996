import { useTranslation } from "react-i18next";

export default function useCalendarStudioResources({ studios }) {
	const { t } = useTranslation();

	const studioGroups = [
		...new Set(
			studios
				.map((studio) => studio.groupTags?.[0] || t("studioGroupMissing", "Studio group missing"))
				.filter((studioGroup) => studioGroup),
		),
	];

	const studioResources = studios
		.map((studio) => {
			const group = studio.groupTags?.[0] || t("studioGroupMissing", "Studio group missing");

			return {
				id: studio.id,
				title: studio.name,
				eventColor: studio.color,
				group,
				groupSorting: group === "Stockholm" ? 1 : group === "Sverige" ? 2 : group === "Mobile" ? 3 : 4,
				studio,
			};
		})
		.sort((a, b) => a.groupSorting - b.groupSorting);

	const groupResources = studioGroups.map((studioGroup) => ({
		id: studioGroup,
		group: studioGroup,
		noStudio: true,
		groupSorting: 0,
	}));

	const resourceColors = studioResources.reduce(
		(acc, resource) => ({ ...acc, [resource.id]: resource.eventColor }),
		{},
	);

	return {
		resources: [...studioResources, ...groupResources],
		resourceColors,
	};
}
