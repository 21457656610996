import React from "react";
import { useTranslation } from "react-i18next";

import Flex from "../../../components/Flex/Flex";
import CoverImage from "../../ui/components/CoverImage/CoverImage";
import themeColors from "../../ui/constants/themeColors";
import { timestampColumn } from "../../ui/utils/columns";

import ArticleDeliveryStatus from "../components/ArticleDeliveryStatus/ArticleDeliveryStatus";
import ArticleISBN from "../components/ArticleISBN/ArticleISBN";

import useArticleTypeTranslations from "./useArticleTypeTranslations";

export default function useArticlesTableColumns({ publisher } = {}) {
	const { t } = useTranslation();

	const articleTypeTranslations = useArticleTypeTranslations();

	return [
		{
			id: "name",
			collapsing: true,
			Header: t("title", "Title"),
			Cell: ({ value, row }) => {
				return (
					<Flex justifyContent="flex-start">
						<div style={{ marginRight: "1em", height: 45, width: 45 }}>
							<CoverImage thumb rounded data={row} size={45} style={{ margin: "0 auto" }} />
						</div>

						<div>
							{value} {row.episodeName && ` - ${row.episodeName}`}
							<div style={{ color: themeColors.GREY }}>{row.subtitle}</div>
						</div>
					</Flex>
				);
			},
		},
		{
			id: "type",
			filter: "select",
			Header: t("type", "Type"),
			collapsing: true,
			accessor: ({ type }) => articleTypeTranslations[type],
		},
		{
			id: "isbn",
			Header: t("isbn", "ISBN"),
			collapsing: true,
			Cell: ArticleISBN,
		},
		{
			id: "status",
			collapsing: true,
			disableSortBy: true,
			Header: t("status", "Status"),
			Cell: ({ row }) => {
				return <ArticleDeliveryStatus article={row} />;
			},
		},
		!publisher && {
			id: "publisher.name",
			Header: t("publisher", "Publisher"),
			collapsing: true,
		},
		{
			id: "serie",
			Header: t("serie", "Serie"),
			collapsing: true,
			Cell: ({ row }) => {
				const { serie, season, serieNumber } = row;

				return (
					<>
						{serie && `${serie.name}`}
						{season && `, ${season.name}`}
						{serieNumber && `, ${t("part", "Part")} ${serieNumber}`}
					</>
				);
			},
		},
		timestampColumn({ id: "deliveryDate", Header: t("deliveryDate", "Delivery date"), time: false }),
		timestampColumn({ id: "updated", Header: t("updated", "Updated"), time: false }),
		timestampColumn({ id: "created", Header: t("created", "Created"), time: false }),
		// hasRight("articles.view") && dropdownMenuColumn(dropdownMenuOptions),
	];
}
