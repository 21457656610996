import { useTranslation } from "react-i18next";

import distributionStatuses from "astrid-firestore/src/api/distribution/constants/distributionStatuses";

export default function usePipelineStatusTranslations() {
	const { t } = useTranslation();

	return {
		[distributionStatuses.QUEUED]: t("queued", "Queued"),
		[distributionStatuses.PENDING]: t("pending", "Pending"),
		[distributionStatuses.SCHEDULED]: t("scheduled", "Scheduled"),
		[distributionStatuses.INITIALIZED]: t("initialized", "Initialized"),
		[distributionStatuses.RUNNING]: t("running", "Running"),
		[distributionStatuses.CANCELLED]: t("cancelled", "Cancelled"),
		[distributionStatuses.COMPLETED]: t("completed", "Completed"),
		[distributionStatuses.FAILED]: t("failed", "Failed"),
	};
}
