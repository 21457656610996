import AsyncButton from "../AsyncButton/AsyncButton";
import ConfirmationModalTrigger from "../ConfirmationModal/ConfirmationModalTrigger";

export default function ConfirmationButton({ onConfirm, header, text, ...props }) {
	return (
		<ConfirmationModalTrigger
			header={header}
			text={text}
			onConfirm={onConfirm}
			trigger={<AsyncButton {...props} />}
		/>
	);
}
