import firebase from "firebase/app";
import uniqid from "uniqid";

import { Button, Card } from "semantic-ui-react";

import React, { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import db from "astrid-firebase/src/db";
import { updateDocument } from "astrid-firestore/src/helpers";

import AdminCard from "../../../admin/components/AdminCard/AdminCard";

import DisplayVendor from "./DisplayVendor";
import EditVendor from "./EditVendor";

const typeMapping = {
	user: "users",
	organization: "organizations",
};

function disablePrimaryChoice(vendors) {
	return !!Object.values(vendors).find((vendor) => vendor.primary);
}

function getNewSortOrder(vendors) {
	const sorted = Object.values(vendors).sort((a, b) => a.sortOrder - b.sortOrder);
	if (sorted.length) {
		return sorted[sorted.length - 1].sortOrder + 1;
	}
	return 0;
}

function Vendor(props) {
	if (props.isEditing || props.vendor.notYetStored) {
		return <EditVendor {...props} />;
	}
	return <DisplayVendor {...props} />;
}

export default function VendorSettings({ currentVendors, uid, type, isProducer }) {
	const { t } = useTranslation();
	const [vendors, setVendors] = useState({});
	const [isEditing, setIsEditing] = useState(null);

	const notYetStored = Object.values(vendors).some((vendor) => vendor.notYetStored);

	useEffect(() => {
		if (!!currentVendors) {
			setVendors(
				Object.entries(currentVendors)
					.sort(([, a], [, b]) => a.sortOrder - b.sortOrder)
					.reduce((acc, [vendorKey, vendor]) => {
						return {
							...acc,
							[vendorKey]: vendor,
						};
					}, {}),
			);
		}
	}, [currentVendors]);

	const saveVendor = useCallback(
		(vendorKey, vendor) => {
			const { notYetStored, ...restVendor } = vendor;
			updateDocument(db.collection(typeMapping[type]).doc(uid), {
				[`vendors.${vendorKey}`]: restVendor,
			});
		},
		[uid, type],
	);

	const addVendor = useCallback(() => {
		const addedVendor = {
			...vendors,
			[uniqid()]: {
				name: "",
				organizationNumber: "",
				street: "",
				secondaryStreet: "",
				city: "",
				state: "",
				country: "",
				zip: "",
				phone: "",
				email: "",
				vat: "",
				billingInstructions: "",
				notYetStored: true,
				primary: Object.keys(vendors).length === 0,
				sortOrder: getNewSortOrder(vendors),
			},
		};
		setVendors(addedVendor);
	}, [vendors]);

	const deleteVendor = useCallback(
		(vendorKey) => {
			const { [vendorKey]: remove, ...rest } = vendors;
			setVendors(rest);
			updateDocument(db.collection(typeMapping[type]).doc(uid), {
				[`vendors.${vendorKey}`]: firebase.firestore.FieldValue.delete(),
			});
		},
		[uid, type, vendors],
	);

	return (
		<AdminCard
			header={t("vendorSettings", "Invoicing details")}
			extra={
				<Button
					color="green"
					icon="plus"
					content={t("addInvoicingDetails", "Add invoicing details")}
					onClick={addVendor}
					disabled={isEditing || notYetStored}
				/>
			}
		>
			<Card.Group>
				{Object.keys(vendors).length ? (
					Object.entries(vendors).map(([vendorKey, vendor]) => {
						return (
							<Vendor
								key={vendorKey}
								vendorKey={vendorKey}
								vendor={vendor}
								isProducer={isProducer}
								saveVendor={saveVendor}
								deleteVendor={deleteVendor}
								disablePrimaryChoice={disablePrimaryChoice(vendors)}
								isEditing={isEditing === vendorKey}
								setIsEditing={(bool) => setIsEditing(bool ? vendorKey : null)}
							/>
						);
					})
				) : (
					<i>{t("missingInvoicingDetails", "Missing invoicing details")}</i>
				)}
			</Card.Group>
		</AdminCard>
	);
}
