import { useTranslation } from "react-i18next";

import useHasRight from "../../authorization/hooks/useHasRight";
import { getOrganizationPath } from "../../routing/utils/getOrganizationPath";

export default function useArticleDropdownMenuOptions() {
	const { t } = useTranslation();
	const hasRight = useHasRight();

	return (article) => [
		{
			text: t("view", "View"),
			linkTo: hasRight("super")
				? `/admin/articles/${article.id}`
				: getOrganizationPath(`/articles/${article.id}`),
		},
	];
}
