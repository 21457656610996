import React from "react";

import InstantSearch from "../../../ui/components/InstantSearch/InstantSearch";

import usePublisherDashboardStatistics from "../../hooks/usePublisherDashboardStatistics";

import Dashboard from "./Dashboard";

function PublisherDashboardStatistics() {
	const statistics = usePublisherDashboardStatistics();

	return <Dashboard statistics={statistics} />;
}

export default function PublisherDashboard({ organization }) {
	return (
		<InstantSearch indexName="articles" configure={{ filters: `publisher.id:${organization.id}` }}>
			<PublisherDashboardStatistics />
		</InstantSearch>
	);
}
