import useDocumentData from "astrid-firestore/src/hooks/useDocumentData";

import LoadingContext from "../../../ui/components/LoadingContext/LoadingContext";

import PipelineSteps from "../../../pipelines/components/Pipeline/components/PipelineSteps";

export default function Pipeline({ document }) {
	const [progressDoc, loading, error] = useDocumentData(document.ref.collection("progress").doc("progressDoc"));
	return (
		<LoadingContext data={progressDoc} loading={loading} error={error}>
			<PipelineSteps steps={document.pipeline.steps} progress={progressDoc?.progress} />
		</LoadingContext>
	);
}
