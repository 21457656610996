import { Segment, Transition } from "semantic-ui-react";

import Flex from "../../../../components/Flex/Flex";

function ToastContent({ children, className, style }) {
	// console.log("ToastContent", props);

	const shadowStrength = 0.5;

	return (
		<div
			style={{
				position: "fixed",
				left: "50%",
				bottom: 15,
				zIndex: 1000,
				transform: "translateX(-50%)",
			}}
		>
			<Segment
				className={className}
				style={{
					...style,
					boxShadow: `
					0 16px 40px -15px rgba(0, 0, 0, ${shadowStrength})
				`,
				}}
			>
				<Flex justifyContent="center" gap={12}>
					{children}
				</Flex>
			</Segment>
		</div>
	);
}

export default function Toast({ open, children }) {
	return (
		<Transition.Group duration={300} animation="fade up">
			{open && <ToastContent>{children}</ToastContent>}
		</Transition.Group>
	);
}
