import { Button, Icon } from "semantic-ui-react";

import React, { useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";

import Flex from "../../../../components/Flex/Flex";
import Popup from "../../../ui/components/Popup/Popup";

export default function CalendarResourceHeaderButtons({
	resourcesOpen,
	setShowAllResources,
	setResourcesOpen,
	showAllResources,
	setResourceType,
	resourceType,
}) {
	const { t } = useTranslation();

	const ref = useRef(resourcesOpen);

	useEffect(() => {
		if (resourcesOpen !== ref.current) {
			ref.current = resourcesOpen;

			const nodes = document.querySelectorAll(".fc-resource-group .fc-datagrid-expander");

			nodes.forEach((node) => {
				const icon = node.querySelector(!resourcesOpen ? ".fc-icon-minus-square" : ".fc-icon-plus-square");

				if (icon) {
					node.click();
				}
			});
		}
	}, [resourcesOpen]);

	return (
		<Flex gap={10} justifyContent="center">
			<Button.Group icon basic size="tiny">
				<Popup
					size="mini"
					content={t("studios", "Studios")}
					trigger={
						<Button active={resourceType === "studios"} onClick={() => setResourceType("studios")}>
							<Icon name="cube" />
						</Button>
					}
				/>

				<Popup
					size="mini"
					content={t("recorders", "Recording engineers")}
					trigger={
						<Button active={resourceType === "engineers"} onClick={() => setResourceType("engineers")}>
							<Icon name="headphones" />
						</Button>
					}
				/>

				<Popup
					size="mini"
					content={t("productions", "Productions")}
					trigger={
						<Button active={resourceType === "productions"} onClick={() => setResourceType("productions")}>
							<Icon name="microphone" />
						</Button>
					}
				/>
			</Button.Group>

			<Button.Group icon basic size="tiny" style={{ marginRight: 5 }}>
				<Popup
					size="mini"
					content={
						resourcesOpen
							? t("collapseResources", "Collapse resources")
							: t("expandResources", "Expand resources")
					}
					trigger={
						<Button active={!resourcesOpen} onClick={() => setResourcesOpen((value) => !value)}>
							<Icon name={resourcesOpen ? "minus" : "plus"} />
						</Button>
					}
				/>

				<Popup
					size="mini"
					content={
						showAllResources
							? t("hideUnbookedResources", "Hide unbooked resources")
							: t("showUnbookedResources", "Show unbooked resources")
					}
					trigger={
						<Button active={showAllResources} onClick={() => setShowAllResources((value) => !value)}>
							<Icon name={showAllResources ? "eye slash" : "eye"} />
						</Button>
					}
				/>
			</Button.Group>
		</Flex>
	);
}
