import equal from "fast-deep-equal";

import { useTranslation } from "react-i18next";

import { db, firebase } from "astrid-firebase";
import { createSerieData } from "astrid-firestore/src/api/series/createSerieData";

import Form from "../../../../forms/components/Form/Form";

import ArticleMetaDataLabel from "./ArticleMetaDataLabel";

export default function ArticleMetaDataSerieSelect({ work, article, ...props }) {
	const { t } = useTranslation();

	const { setValue } = Form.useFormContext();

	return (
		<Form.CollectionSelect
			name="serie"
			label={
				<ArticleMetaDataLabel
					text={t("serie", "Series")}
					condition={work && !equal(work.serie, article.serie)}
				/>
			}
			query={db
				.collection("series")
				.where("publisher.id", "==", article.publisher.id)
				.where("deleted", "==", false)}
			onAddDocument={(name) => {
				return createSerieData(firebase, { name });
			}}
			onChangeOption={() => {
				setValue("season", null);
			}}
			allowAdditions
			allowEmpty
			search
			{...props}
		/>
	);
}
