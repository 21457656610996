import Label from "../../../ui/components/Label/Label";

import useImportStatuses from "../../hooks/useImportStatuses";

export default function ImportStatusLabel({ importt, ...props }) {
	const importStatuses = useImportStatuses();

	const { text, color } = importStatuses[importt.status];

	return (
		<Label color={color} {...props}>
			{text}
		</Label>
	);
}
