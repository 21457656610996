import React from "react";
import { useOutletContext, useParams } from "react-router-dom";

import { db } from "astrid-firebase";
import useDocumentData from "astrid-firestore/src/hooks/useDocumentData";

import LoadingContext from "../../ui/components/LoadingContext/LoadingContext";

import featureFlags from "../../authorization/constants/featureFlags";

import ImportView from "../views/ImportView/ImportView";

export default function Import() {
	const params = useParams();
	const { article } = useOutletContext();

	const [importt, loading, error] = useDocumentData(db.collection("imports").doc(params.import));

	return (
		<LoadingContext data={importt} loading={loading} error={error} featureFlag={featureFlags.FLATTENING}>
			<ImportView article={article} importt={importt} />
		</LoadingContext>
	);
}
