import React from "react";

import DataTable from "../../../ui/components/DataTable/DataTable";

import useArtifactFilesTableColumns from "../../hooks/useArtifactFilesTableColumns";

export default function ArtifactFilesDataTable({ artifact, files, onReorder, onSelect, ...props }) {
	const columns = useArtifactFilesTableColumns({ artifact, onReorder });

	return (
		<DataTable
			allowEmpty
			selectable
			autoResetSelectedRows
			data={files}
			columns={columns}
			onSelectRows={onSelect}
			{...props}
		/>
	);
}
