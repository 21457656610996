import { useTranslation } from "react-i18next";

import pipelineStepStatuses from "astrid-firestore/src/api/pipelines/constants/pipelineStepStatuses";

export default function usePipelineStatusTranslations() {
	const { t } = useTranslation();

	return {
		[pipelineStepStatuses.DISABLED]: t("disabled", "Disabled"),
		[pipelineStepStatuses.PENDING]: t("pending", "Pending"),
		[pipelineStepStatuses.RUNNING]: t("running", "Running"),
		[pipelineStepStatuses.COMPLETED]: t("completed", "Completed"),
		[pipelineStepStatuses.FAILED]: t("failed", "Failed"),
	};
}
