import { Icon } from "semantic-ui-react";

import Flex from "../../../../../components/Flex/Flex";

export default function ArticleMetaDataLabel({ text, condition }) {
	return (
		<label>
			<Flex justifyContent="flex-start">
				{text}

				<div style={{ position: "relative" }}>
					{condition && (
						<Icon
							size="small"
							color="blue"
							name="asterisk"
							style={{ fontSize: 8, position: "absolute", bottom: 0 }}
						/>
					)}
				</div>
			</Flex>
		</label>
	);
}
