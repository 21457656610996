import { useState } from "react";

import organizationTypes from "astrid-firestore/src/api/organizations/constants/organizationTypes";
import useCollectionData from "astrid-firestore/src/hooks/useCollectionData";

import LoadingContext from "../../../ui/components/LoadingContext/LoadingContext";
import PageView from "../../../ui/views/PageView/PageView";

import useCostsQuery from "../../../costs/hooks/useCostsQuery";
import { useOrganizationType } from "../../../organizations/state/organizationType";

import AccountCostsActions from "./components/AccountCostsActions";
import AccountCostsTable from "./components/AccountCostsTable";

export default function AccountCostsView({ header, ...props }) {
	const orgType = useOrganizationType();
	const [isSelecting, setIsSelecting] = useState(false);

	const costsQuery = useCostsQuery({
		expense: true,
		orderId: null,
		processed: false,
		...props,
	});

	const [costs = [], loading, error] = useCollectionData(
		orgType === organizationTypes.NARRATOR ? costsQuery.where("created", ">=", new Date("2025-01-23")) : costsQuery,
	);

	return (
		<PageView header={header} extra={<AccountCostsActions costs={costs} disabled={isSelecting} />}>
			<LoadingContext data={costs} loading={loading} error={error}>
				<AccountCostsTable data={costs} onSelectRows={(rows) => setIsSelecting(rows.length > 0)} />
			</LoadingContext>
		</PageView>
	);
}
