import sessionStatuses from "astrid-firestore/src/api/sessions/constants/sessionStatuses";

export default function useCalendarBackgroundEvents({
	modifiedSession,
	sessions = [],
	checkOverlap,
	getOverlappingResourceIds,
}) {
	if (!modifiedSession) {
		return [];
	}

	const filteredSessions = sessions.filter(
		(session) =>
			session.status !== sessionStatuses.CANCELLED &&
			session.studio &&
			session.id !== modifiedSession.id &&
			checkOverlap?.(session, modifiedSession),
	);

	return filteredSessions.map((session) => ({
		resourceIds: getOverlappingResourceIds?.(modifiedSession) || [],
		start: session.start.toDate(),
		end: session.end.toDate(),
		display: "background",
		backgroundColor: "red",
		borderColor: "red",
	}));
}
