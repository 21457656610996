import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

import { db } from "astrid-firebase";

import CollectionDataTable from "../../../ui/components/CollectionDataTable/CollectionDataTable";
import useSearchInput from "../../../ui/hooks/useSearchInput";
import PageView from "../../../ui/views/PageView/PageView";

import CreateImportButton from "../../components/CreateImportForm/CreateImportButton";
import useImportsTableColumns from "../../hooks/useImportsTableColumns";

export default function ImportsView({ organization }) {
	const { t } = useTranslation();
	const navigate = useNavigate();

	const query = db.collection("imports").orderBy("created", "desc");

	const [searchInput, searchQuery] = useSearchInput();

	const columns = useImportsTableColumns();

	return (
		<PageView header={t("imports", "Imports")} sidebar={searchInput} extra={<CreateImportButton />}>
			<CollectionDataTable
				persist
				filterable={false}
				query={query}
				columns={columns}
				onClick={({ id }) => navigate(id)}
				searchQuery={searchQuery}
				searchableKeys={["source"]}
				sortBy={{
					id: "name",
					desc: false,
				}}
			/>
		</PageView>
	);
}
