import { useTranslation } from "react-i18next";

import useOrganizationRoleTranslations from "../../organizations/hooks/useOrganizationRoleTranslations";

import useUserOrganizationTypeTranslations from "./useUserOrganizationTypeTranslations";

export default function useUsersRefinements({ organization, indexName }) {
	const { t } = useTranslation(["common", "language"]);

	const organizationTypes = useUserOrganizationTypeTranslations();
	const organizationRoles = useOrganizationRoleTranslations();

	return [
		{
			header: t("type", "Type"),
			attribute: "organizationTypes",
			renderItem: ({ label }) => organizationTypes[label],
			hidden: !!organization,
		},
		{
			header: t("roles", "Roles"),
			attribute: "organizationRoles",
			transformItems: (items) => {
				return items.filter(
					({ value }) => organizationRoles[value.split(":")[1]] && value.startsWith(organization.id),
				);
			},
			renderItem: ({ value }) => organizationRoles[value.split(":")[1]],
			hidden: !organization || indexName === "readers",
		},

		{
			header: t("languages", "Languages"),
			attribute: "organizationLanguages",
			transformItems: (items) => {
				return items.filter(({ value }) => value.split(":")[1] && value.startsWith(organization.id));
			},
			renderItem: ({ value }) => t(`language:${value.split(":")[1]}`),
			hidden: !organization || indexName === "readers",
		},
	];
}
