import { Icon } from "semantic-ui-react";

import { useContext } from "react";

import DropdownMenu from "../../ui/components/DropdownMenu/DropdownMenu";

import TableContext from "./TableContext";
import TableHeaderCell from "./TableHeaderCell";

export default function TableColumnSelector() {
	const { columns, visibleColumns, setVisibleColumns } = useContext(TableContext);

	return (
		<TableHeaderCell sticky collapsing>
			<DropdownMenu
				maxHeight={260}
				pointing="right"
				direction={false}
				menuStyle={{ marginRight: 25 }}
				trigger={
					<Icon fitted name="columns" color={visibleColumns.length !== columns.length ? "blue" : undefined} />
				}
				options={columns.map(({ id, Header }) => ({
					content: Header,
					icon: visibleColumns.includes(id) ? "check" : " ",
					onClick: () => {
						setVisibleColumns((visibleColumns) =>
							visibleColumns.includes(id)
								? visibleColumns.filter((column) => column !== id)
								: [...visibleColumns, id],
						);
					},
				}))}
			/>
		</TableHeaderCell>
	);
}
