import FirestoreDocument from "../../../types/FirestoreDocument";
import FirestoreLifecycle from "../../../types/FirestoreLifecycle";
import { StorageFile } from "../../../types/StorageFile";
import { array, nullable, string } from "../../../types/_types";

import { OrganizationRef } from "../../organizations/types/OrganizationRef";

import { WorkMetaData } from "./WorkMetaData";

export const Work = WorkMetaData.extend({
	publisher: OrganizationRef,
	articleIds: array(string()),
	coverThumb: nullable(StorageFile),
})
	.merge(FirestoreDocument)
	.merge(FirestoreLifecycle);
