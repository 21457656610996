import React from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

import InstantSearch from "../../../ui/components/InstantSearch/InstantSearch";
import InstantSearchBox from "../../../ui/components/InstantSearch/InstantSearchBox";
import InstantSearchHitsDataTable from "../../../ui/components/InstantSearch/InstantSearchHitsDataTable";
import InstantSearchPagination from "../../../ui/components/InstantSearch/InstantSearchPagination";
import InstantSearchRefinements from "../../../ui/components/InstantSearch/InstantSearchRefinements";
import InstantSearchRefresh from "../../../ui/components/InstantSearch/InstantSearchRefresh";
import PageView from "../../../ui/views/PageView/PageView";

import useDistributionsRefinements from "../../hooks/useDistributionsRefinements";
import useDistributionsTableColumns from "../../hooks/useDistributionsTableColumns";

export default function DistributionsView({ publisher }) {
	const { t } = useTranslation();

	const navigate = useNavigate();

	const columns = useDistributionsTableColumns({ publisher });
	const refinements = useDistributionsRefinements({ publisher });

	const configure = {
		filters: publisher ? `publisher.id:${publisher.id}` : undefined,
	};

	return (
		<InstantSearch indexName="distributions" configure={configure}>
			<InstantSearchRefresh refreshInterval={5000} />
			<PageView
				header={t("distributions", "Distributions")}
				sidebar={
					<>
						<InstantSearchBox />
						<InstantSearchRefinements refinements={refinements} />
					</>
				}
			>
				<InstantSearchHitsDataTable
					columns={columns}
					onClick={({ id }) => navigate(id)}
					hiddenColumns={publisher ? ["publisher.name"] : []}
				/>

				<InstantSearchPagination />
			</PageView>
		</InstantSearch>
	);
}
