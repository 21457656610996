import React from "react";

import InstantSearch from "../../../ui/components/InstantSearch/InstantSearch";

import useProducerDashboardStatistics from "../../hooks/useProducerDashboardStatistics";

import Dashboard from "./Dashboard";

function ProducerDashboardStatistics({ organization }) {
	const statistics = useProducerDashboardStatistics({ organization });

	return <Dashboard statistics={statistics} />;
}

export default function ProducerDashboard({ organization }) {
	return (
		<InstantSearch indexName="productions" configure={{ filters: `producer:${organization.id}` }}>
			<ProducerDashboardStatistics organization={organization} />
		</InstantSearch>
	);
}
