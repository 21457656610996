import { Divider, Grid, Header } from "semantic-ui-react";

import React from "react";
import { useTranslation } from "react-i18next";
import useWindowSize from "react-use/lib/useWindowSize";

import { languageName } from "astrid-helpers/src/languages";

import Flex from "../../../../components/Flex/Flex";
import CoverImageStack from "../../../ui/components/CoverImage/CoverImageStack";
import DropdownMenu from "../../../ui/components/DropdownMenu/DropdownMenu";

import useHasRight from "../../../authorization/hooks/useHasRight";

import WorkPresentationContributors from "./components/WorkPresentationContributors";
import WorkPresentationDetail from "./components/WorkPresentationDetail";
import WorkPresentationLabel from "./components/WorkPresentationLabel";

export default function WorkPresentation({ work }) {
	const { t } = useTranslation();
	const hasRight = useHasRight();

	const {
		synopsis,
		publisher,
		imprint,
		language,
		originalLanguage,
		originalName,
		serie,
		serieNumber,
		themaCodes,
		contributors,
		additionalLanguages,
		season,
		tags,
	} = work;

	const { width } = useWindowSize();

	const desktop = width > 600;

	const dropdownOptions = [
		{
			text: t("editInOrganization", "Edit in {{organization}}", { organization: publisher?.name }),
			linkTo: `/publisher/${publisher?.id}/works/${work?.id}`,
			hidden: !hasRight("works.view"),
		},
	];

	return (
		<Grid style={{ margin: "0 auto" }}>
			<Grid.Row style={{ paddingTop: desktop ? 0 : 10 }}>
				<Grid.Column>
					<Flex>
						<Flex justifyContent="flex-start" gap={10}>
							<CoverImageStack data={work} size={50} />

							<div>
								<Header as="h3" style={{ fontWeight: 400 }}>
									{work?.name}
									<Header.Subheader>{work?.subtitle}</Header.Subheader>
								</Header>
							</div>
						</Flex>

						<DropdownMenu iconStyle={{ fontSize: 15 }} options={dropdownOptions} />
					</Flex>

					<Flex style={{ marginTop: 15 }} gap={10} justifyContent="flex-start">
						<WorkPresentationDetail label={t("titleOriginal", "Original title")} value={originalName} />

						<WorkPresentationDetail label={t("publisher", "Publisher")} value={publisher?.name} />

						<WorkPresentationDetail label={t("imprint", "Imprint")} value={imprint?.name} />
					</Flex>

					<Flex style={{ marginTop: 5 }} gap={10} justifyContent="flex-start">
						<WorkPresentationDetail label={t("language", "Language")} value={languageName(language)} />

						<WorkPresentationDetail
							label={t("originalLanguage", "Original language")}
							value={languageName(originalLanguage)}
						/>

						<WorkPresentationDetail
							label={t("otherLanguages", "Other occuring languages")}
							value={additionalLanguages?.map((lang) => languageName(lang)).join(", ")}
						/>
					</Flex>

					<Flex style={{ marginTop: 5 }} gap={10} justifyContent="flex-start">
						<WorkPresentationDetail
							label={t("serie", "Series")}
							value={serieNumber ? `${serie?.name} (Nr. ${serieNumber})` : serie?.name}
						/>

						<WorkPresentationDetail label={t("season", "Season")} value={season?.name} />
					</Flex>

					{synopsis && (
						<div>
							<Divider />

							{synopsis}
						</div>
					)}

					<WorkPresentationContributors value={contributors} />

					<WorkPresentationLabel value={tags} header={t("tags", "Tags")} />

					<WorkPresentationLabel value={themaCodes} header={t("themaCodes", "Thema codes")} />
				</Grid.Column>
			</Grid.Row>
		</Grid>
	);
}
