import React from "react";

import useDocumentData from "astrid-firestore/src/hooks/useDocumentData";

import LoadingContext from "../../../ui/components/LoadingContext/LoadingContext";

import WorkPresentation from "../../../works/components/WorkPresentation/WorkPresentation";

export default function ProductionOverviewView({ production }) {
	const [work, loading, error] = useDocumentData(production.work?.ref);

	return (
		<LoadingContext data={work} loading={loading} error={error}>
			{work && <WorkPresentation work={work} />}
		</LoadingContext>
	);
}
