import { useMemo, useState } from "react";

import useBucket from "astrid-hooks/src/useBucket";

import Form from "../../../../forms/components/Form/Form";

import sortArtifactFiles from "../../../utils/sortArtifactFiles";

export default function useArtifactManagerFiles({ form, artifact }) {
	const files = form.watch("files");

	const { downloadFiles, deleteFiles } = useBucket("earselect-artifacts");

	const [selectedFiles, onSelectFiles] = useState([]);

	const { swap, replace, append } = Form.useFieldArray({ name: "files", control: form.control });

	const hasAudioFiles = files.some((file) => file.contentType.includes("audio"));

	const sortedFiles = useMemo(() => sortArtifactFiles(files), [files]);

	const isFilesSorted = useMemo(() => JSON.stringify(files) === JSON.stringify(sortedFiles), [files, sortedFiles]);

	const onAddFiles = (files) => {
		append(files);
	};

	const onSortFiles = () => {
		replace(sortedFiles);
	};

	const onReorderFiles = (from, to) => {
		swap(from, to);
	};

	const onDownloadFiles = () => {
		return downloadFiles(selectedFiles);
	};

	const onDeleteFiles = (filesToDelete) => {
		replace(files.filter((file) => !filesToDelete.includes(file)));

		deleteFiles(filesToDelete.filter((file) => !artifact.files.some(({ name }) => name === file.name)));
	};

	return {
		files,
		sortedFiles,
		selectedFiles,
		isFilesSorted,
		hasAudioFiles,
		onAddFiles,
		onSortFiles,
		onSelectFiles,
		onReorderFiles,
		onDownloadFiles,
		onDeleteFiles,
	};
}
