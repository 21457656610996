import firebase from "firebase";

import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";

import { db } from "astrid-firebase";
import getArticlesQuery from "astrid-firestore/src/api/articles/getArticlesQuery";
import getArtifactsQuery from "astrid-firestore/src/api/artifacts/getArtifactsQuery";
import { useCollectionCount } from "astrid-firestore/src/hooks";

export default function useWorkMenuOptions({ work }) {
	const { t } = useTranslation();

	const location = useLocation();

	const { pathname } = location;

	const [articlesCount] = useCollectionCount(getArticlesQuery(firebase, { work }));
	const [artifactsCount] = useCollectionCount(getArtifactsQuery(firebase, { work }));
	const [productionsCount] = useCollectionCount(
		db.collection("productions").where("work.id", "==", work.id).where("deleted", "==", false),
	);

	return [
		{
			to: "",
			active: pathname.endsWith(work.id),
			text: t("metaData", "Meta data"),
		},
		{
			to: "articles",
			active: pathname.endsWith("/articles"),
			text: t("articles", "Articles") + (articlesCount ? ` (${articlesCount})` : ""),
		},
		{
			to: "artifacts",
			active: pathname.endsWith("/artifacts"),
			text: t("files", "Files") + (artifactsCount ? ` (${artifactsCount})` : ""),
		},
		{
			to: "productions",
			active: pathname.endsWith("/productions"),
			text: t("productions", "Productions") + (productionsCount ? ` (${productionsCount})` : ""),
		},
	];
}
