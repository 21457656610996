export default function useCalendarTimelineNavigation({ ref }) {
	const onTodayClick = () => {
		ref.current.calendar.today();
	};

	const onLeftNavigationClick = () => {
		ref.current.calendar.prev();
	};

	const onRightNavigationClick = () => {
		ref.current.calendar.next();
	};

	const changeView = (view) => {
		ref.current.calendar.changeView(view);
	};

	return {
		onTodayClick,
		onLeftNavigationClick,
		onRightNavigationClick,
		changeView,
	};
}
