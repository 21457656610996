import React from "react";

import CoverImage from "./CoverImage";

export default function CoverImageStack({ data, size = 80, ...props }) {
	return (
		<>
			<div className="image-stack" {...props}>
				<CoverImage thumb rounded data={data} size={size} />
				<CoverImage thumb rounded data={data} size={size} modal />
			</div>
		</>
	);
}
