import React from "react";
import { useTranslation } from "react-i18next";

import { firebase } from "astrid-firebase";
import { Work } from "astrid-firestore/src/api/works/types/Work";
import updateWork from "astrid-firestore/src/api/works/updateWork";

import AdminForm from "../../../../admin/components/AdminForm/AdminForm";
import ArticleMetaDataContributors from "../../../../articles/components/ArticleMetaDataForm/components/ArticleMetaDataContributors";
import ArticleMetaDataImprintSelect from "../../../../articles/components/ArticleMetaDataForm/components/ArticleMetaDataImprintSelect";
import ArticleMetaDataSeasonSelect from "../../../../articles/components/ArticleMetaDataForm/components/ArticleMetaDataSeasonSelect";
import ArticleMetaDataSerieNumberSelect from "../../../../articles/components/ArticleMetaDataForm/components/ArticleMetaDataSerieNumberSelect";
import ArticleMetaDataSerieSelect from "../../../../articles/components/ArticleMetaDataForm/components/ArticleMetaDataSerieSelect";
import ArticleMetaDataThemaCodes from "../../../../articles/components/ArticleMetaDataForm/components/ArticleMetaDataThemaCodes";
import useArticleAbridgedOptions from "../../../../articles/hooks/useArticleAbridgedOptions";
import Form from "../../../../forms/components/Form/Form";
import useSimpleForm from "../../../../forms/hooks/useSimpleForm";

import WorkTags from "../../../components/Worktags/WorkTags";

export default function WorkMetadataForm({ work, onSuccess }) {
	const { t } = useTranslation();

	const form = useSimpleForm({
		schema: Work,
		values: {
			ref: work.ref,
			abridged: work.abridged || false,
			additionalLanguages: work.additionalLanguages || null,
			contributors: work.contributors || [],
			imprint: work.imprint || null,
			language: work.language || null,
			name: work.name || null,
			subtitle: work.subtitle || null,
			originalLanguage: work.originalLanguage || null,
			originalName: work.originalName || null,
			serie: work.serie || null,
			season: work.season || null,
			serieNumber: work.serieNumber || null,
			synopsis: work.synopsis || null,
			tags: work.tags || [],
			themaCodes: work.themaCodes || null,
		},
		onSubmit: (data) => {
			return updateWork(firebase, data);
		},
		onSuccess,
	});

	const { watch } = form;

	const language = watch("language");

	const abridgedOptions = useArticleAbridgedOptions();

	return (
		<AdminForm header={t("metaData", "Meta data")} {...form}>
			<Form.Group widths="equal">
				<Form.Input name="name" label={t("title", "Title")} />
				<Form.Input name="subtitle" label={t("subtitle", "Subtitle")} />
				<Form.Input name="originalName" label={t("originalTitle", "Original title")} />
			</Form.Group>

			<Form.Group widths="equal">
				<ArticleMetaDataImprintSelect article={work} />

				<Form.Select name="abridged" label={t("abridged", "Abridged")} options={abridgedOptions} />
			</Form.Group>

			<Form.TextArea name="synopsis" label={t("synopsis", "Description")} />

			<Form.Group widths="equal">
				<ArticleMetaDataSerieSelect article={work} />
				<ArticleMetaDataSeasonSelect article={work} />
				<ArticleMetaDataSerieNumberSelect article={work} />
			</Form.Group>

			<Form.Group widths="equal">
				<Form.LanguageSelect name="language" label={t("language", "Language")} allowEmpty />

				<Form.LanguageSelect
					name="originalLanguage"
					label={t("originalLanguage", "Original language")}
					allowEmpty
				/>

				<Form.LanguageSelect
					name="additionalLanguages"
					label={t("otherLanguages", "Other occuring languages")}
					filterOptions={(option) => option.value !== language}
					multiple
				/>
			</Form.Group>

			<Form.Group widths="equal">
				<ArticleMetaDataThemaCodes article={work} />

				<Form.Field name="tags">
					<WorkTags work={work} />
				</Form.Field>
			</Form.Group>

			<ArticleMetaDataContributors article={work} />

			<Form.Blocker />
		</AdminForm>
	);
}
