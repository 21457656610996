import firebase from "firebase/app";

import { Button, Icon, Select } from "semantic-ui-react";

import React, { useState } from "react";
import { useTranslation } from "react-i18next";

import Flex from "astrid-components/lib/components/Layout/Flex";
import { updateDocument } from "astrid-firestore/src/helpers";
import AccountAudioSamplesModal from "astrid-web/src/features/account/views/AccountSettingsView/components/AccountAudioSamplesModal";
import ConfirmationModalTrigger from "astrid-web/src/features/ui/components/ConfirmationModal/ConfirmationModalTrigger";

import AdminCard from "../../../../admin/components/AdminCard/AdminCard";

export default function AccountAudioSamples({ user }) {
	const { t } = useTranslation();

	const [openModal, setOpenModal] = useState(false);

	const samples = Object.entries(user?.readerData?.samples || {});

	const removeSample = async (id) => {
		try {
			await updateDocument(user.ref, {
				[`readerData.samples.${id}`]: firebase.firestore.FieldValue.delete(),
			});
		} catch (error) {
			console.log(error);
		}
	};

	return (
		<AdminCard
			header={t("soundSamples", "Audio samples")}
			extra={
				<Button primary onClick={() => setOpenModal(true)}>
					{t("add", "Add")}
				</Button>
			}
		>
			{samples.map(([id, { file, genre }]) => {
				return (
					<Flex key={id} gap={15} style={{ marginTop: 10 }}>
						<audio
							style={{
								width: "100%",
								height: 39,
							}}
							src={file}
							controls
							onPlay={(e) => {
								const allAudio = document.querySelectorAll("audio");

								for (const audio of allAudio) {
									if (audio !== e.target) audio.pause();
								}
							}}
						/>
						<Select options={[{ key: genre, value: genre, text: t(genre) }]} value={genre} disabled />
						<ConfirmationModalTrigger
							trigger={<Icon name="trash" />}
							onConfirm={() => removeSample(id)}
							text={t("confirmDeleteSoundSample", "Are you sure you want to delete the sample?")}
						/>
					</Flex>
				);
			})}

			{openModal && <AccountAudioSamplesModal user={user} onClose={() => setOpenModal(false)} />}
		</AdminCard>
	);
}
