import { useTranslation } from "react-i18next";

import importStatuses from "astrid-firestore/src/api/imports/constants/importStatuses";

export default function useImportDropdownMenuOptions() {
	const { t } = useTranslation();

	return (articleImport) => [
		{
			text: t("retry", "Retry"),
			disabled: articleImport.status !== importStatuses.FAILED,
			action: () => {
				return articleImport.ref.update({
					status: importStatuses.PENDING,
				});
			},
		},
		{
			text: t("markAsCompleted", "Mark as completed"),
			disabled: articleImport.status === importStatuses.COMPLETED,
			action: () => {
				return articleImport.ref.update({
					status: importStatuses.COMPLETED,
				});
			},
		},
	];
}
