import { useMemo } from "react";

import useDocumentData from "astrid-firestore/src/hooks/useDocumentData";

export default function useAutoApprovalRules(organization) {
	const [data = {}, loading, error] = useDocumentData(
		useMemo(() => organization.ref.collection("settings").doc("autoApprovePrices"), [organization]),
	);

	const { id, ref, exists, ...settings } = data;

	const autoApprovalRules = Object.values(settings);

	return [autoApprovalRules, loading, error];
}
