import { Button, Modal } from "semantic-ui-react";

import { useEffect } from "react";
import { useTranslation } from "react-i18next";

import { db } from "astrid-firebase";
import { teamRoles } from "astrid-firestore/src/constants/teamRoles";
import { useDocumentData } from "astrid-firestore/src/hooks";

import Form from "../../../forms/components/Form/Form";
import useForm from "../../../forms/hooks/useForm";
import ProductionInstantSearchSelect from "../../../productions/components/ProductionInstantSearchSelect/ProductionInstantSearchSelect";
import ProductionMemberUserSelect from "../../../productions/components/ProductionMemberUserSelect/ProductionMemberUserSelect";
import ReaderInstantSearchSelect from "../../../readers/components/ReaderInstantSearchSelect/ReaderInstantSearchSelect";

import useSessionOptions from "../../hooks/useSessionOptions";

import CalendarRecorderPriceSelect from "./components/CalendarRecorderPriceSelect";

export default function CalendarBookingAssistant({
	organization,
	defaultValues,
	setOpenBookingAssistant,
	onStartBooking,
	defaultProductionId,
	onClose,
}) {
	const { t } = useTranslation();

	const form = useForm({
		defaultValues: {
			price: null,
			agreement: null,
			productionId: defaultProductionId || null,
			readerId: null,
			recorderId: null,
			recorderPriceId: null,
			polish: false,
			...defaultValues,
		},
		values: {
			productionId: defaultProductionId,
		},
	});

	const { watch, formState, setValue, handleSubmit } = form;

	const { readerId, productionId, recorderId } = watch();

	const [production] = useDocumentData(productionId && db.collection("productions").doc(productionId));

	const [{ recorderOptions, recorderPriceOptions }] = useSessionOptions({ production, recorderId });

	useEffect(() => {
		if (readerId) {
			setValue("productionId", defaultProductionId || null);
		}
	}, [defaultProductionId, readerId, setValue]);

	const onSubmit = ({ readerId, recorderId, ...values }) => {
		onStartBooking({ reader: [readerId], recorder: recorderId, ...values });
	};

	return (
		<Modal size="small" open closeOnDimmerClick onClose={onClose}>
			<Modal.Header>{t("bookSessions", "Book session(s)")}</Modal.Header>

			<Modal.Content>
				<Form form={form}>
					<Form.Field name="readerId">
						{defaultProductionId ? (
							<ProductionMemberUserSelect
								label={t("reader", "Narrator")}
								production={production}
								role={teamRoles.READER}
								autoSelect
							/>
						) : (
							<ReaderInstantSearchSelect />
						)}
					</Form.Field>

					<Form.Field name="productionId">
						<ProductionInstantSearchSelect
							organizationId={organization.id}
							productionId={defaultProductionId}
							readerId={readerId}
							disabled={defaultProductionId || !readerId}
						/>
					</Form.Field>

					<Form.Group widths="equal">
						<Form.Select
							name="recorderId"
							label={t("producerRecorder", "Recording engineer")}
							options={recorderOptions}
							disabled={!production}
						/>

						<CalendarRecorderPriceSelect options={recorderPriceOptions} />
					</Form.Group>

					<Form.Checkbox
						disabled={!production}
						name="polish"
						label={t("polishSession", "Correction session")}
						rules={{ required: false }}
					/>
				</Form>
			</Modal.Content>

			<Modal.Actions>
				<Button onClick={() => setOpenBookingAssistant(false)}>{t("cancel", "Cancel")}</Button>

				<Button primary disabled={!formState.isValid} onClick={handleSubmit(onSubmit)}>
					{t("startBooking", "Start booking")}
				</Button>
			</Modal.Actions>
		</Modal>
	);
}
