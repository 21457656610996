import { Route } from "react-router-dom";

import { db } from "astrid-firebase";

import ForwardContext from "../../../layouts/ForwardContext";

import { artifact } from "../../artifacts/routes/artifacts";
import { distribution } from "../../distribution/routes/distributions";
import { exports } from "../../exports/routes/exports";
import FirestoreDocumentElement from "../../firebase/components/FirestoreDocumentElement";
import { importt } from "../../imports/routes/imports";

import Article from "../pages/Article";
import ArticleArtifacts from "../pages/ArticleArtifacts";
import ArticleDistribution from "../pages/ArticleDistribution";
import ArticleImports from "../pages/ArticleImports";
import ArticleMetaData from "../pages/ArticleMetaData";
import ArticleProductions from "../pages/ArticleProductions";
import ArticleRelatedArticles from "../pages/ArticleRelatedArticles";
import ArticleVersion from "../pages/ArticleVersion";
import ArticleVersionChange from "../pages/ArticleVersionChange";
import ArticleVersions from "../pages/ArticleVersions";
import Articles from "../pages/Articles";

export const article = (
	<Route
		path=":article"
		element={<FirestoreDocumentElement param="article" collection={db.collection("articles")} />}
		handle={{ crumb: ({ article }) => article?.name }}
	>
		<Route element={<Article />}>
			<Route index element={<ArticleMetaData />} handle={{ crumb: ({ t }) => t("metaData", "Meta data") }} />

			<Route
				path="bundle"
				element={<ArticleRelatedArticles />}
				handle={{ crumb: ({ t }) => t("bundle", "Bundle") }}
			/>

			<Route
				path="distributions"
				element={<ArticleDistribution />}
				handle={{ crumb: ({ t }) => t("distributions", "distributions") }}
			/>

			<Route path="artifacts" handle={{ crumb: ({ t }) => t("files", "Files") }}>
				<Route index element={<ArticleArtifacts />} />
			</Route>

			<Route
				path="productions"
				element={<ArticleProductions />}
				handle={{ crumb: ({ t }) => t("productions", "Productions") }}
			/>

			<Route path="versions" handle={{ crumb: ({ t }) => t("versions", "Versions") }}>
				<Route index element={<ArticleVersions />} />

				<Route path=":version" element={<ArticleVersion />} handle={{ crumb: ({ version }) => version?.id }}>
					<Route path="after" element={<ArticleVersionChange change="after" />} />

					<Route path="before" element={<ArticleVersionChange change="before" />} />

					<Route path="revert" element={<ArticleVersionChange change="after" revert />} />
				</Route>
			</Route>

			<Route path="imports" element={<ArticleImports />} handle={{ crumb: ({ t }) => t("imports", "Imports") }} />

			{exports}
		</Route>

		<Route path="artifacts" handle={{ crumb: ({ t }) => t("files", "Files") }}>
			{artifact}
		</Route>

		<Route path="distributions" handle={{ crumb: ({ t }) => t("distributions", "Distributions") }}>
			{distribution}
		</Route>

		<Route path="imports" handle={{ crumb: ({ t }) => t("imports", "Imports") }}>
			{importt}
		</Route>
	</Route>
);

export const articles = (
	<Route path="articles" element={<ForwardContext />} handle={{ crumb: ({ t }) => t("articles", "Articles") }}>
		<Route index element={<Articles />} />

		{article}
	</Route>
);
