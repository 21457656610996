import React from "react";
import { useOutletContext } from "react-router-dom";

import ProductionSettingsView from "../../views/ProductionSettingsView/ProductionSettingsView";

export default function ProductionSettings() {
	const { production } = useOutletContext();

	return <ProductionSettingsView production={production} />;
}
