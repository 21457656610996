import { Route } from "react-router-dom";

import { db } from "astrid-firebase";

import ForwardContext from "../../../layouts/ForwardContext";

import ArticleExports from "../../articles/pages/ArticleExports";
import FirestoreDocumentElement from "../../firebase/components/FirestoreDocumentElement";

import ExportPipeline from "../pages/ExportPipeline";

export const exports = (
	<>
		<Route path="exports" element={<ArticleExports />} handle={{ crumb: ({ t }) => t("exports", "Exports") }} />

		<Route path="exports" element={<ForwardContext />} handle={{ crumb: ({ t }) => t("exports", "Exports") }}>
			<Route
				path=":export"
				element={<FirestoreDocumentElement param="export" collection={db.collection("exports")} />}
				handle={{ crumb: (props) => props.export?.id }}
			>
				<Route index element={<ExportPipeline />} />
			</Route>
		</Route>
	</>
);
