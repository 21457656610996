import { db } from "astrid-firebase";
import organizationTypes from "astrid-firestore/src/api/organizations/constants/organizationTypes";
import sessionOfferTypes from "astrid-firestore/src/constants/sessionOfferTypes";
import { useCollectionData } from "astrid-firestore/src/hooks";

import { useProfile } from "../../../../authentication/state/profile";
import useHasRight from "../../../../authorization/hooks/useHasRight";

const getSessionsQuery = ({ organization, hasRight, user }) => {
	if (organization.type === organizationTypes.NARRATOR) {
		return db.collection("sessions").where("reader", "array-contains", organization.id);
	}

	if (!hasRight("calendar.viewAllSessions")) {
		return db.collection("sessions").where("teamUserIds", "array-contains", user.id);
	}

	return db.collection("sessions").where("producer", "==", organization.id);
};

export default function useCalendarSessions({ startDate, organization, endDate, modifiedSessions, viewType, filters }) {
	const hasRight = useHasRight();
	const user = useProfile();

	const [sessions = [], loading, error] = useCollectionData(
		startDate &&
			endDate &&
			getSessionsQuery({ organization, hasRight, user })
				.where("start", ">=", startDate)
				.where("start", "<", endDate),
	);

	if (error) {
		console.error(error);
	}

	let filteredSessions = sessions.filter((session) => session.end.toDate() < endDate);

	if (filters.productions.length > 0) {
		filteredSessions = filteredSessions.filter(
			(session) => modifiedSessions?.[session.id] || filters.productions.includes(session.production),
		);
	}

	if (filters.readers.length > 0) {
		filteredSessions = filteredSessions.filter(
			(session) =>
				modifiedSessions?.[session.id] || session.reader.find((reader) => filters.readers.includes(reader)),
		);
	}

	if (filters.recorders.length > 0) {
		filteredSessions = filteredSessions.filter((session) => {
			const isAnyRecorderInTeam = session.recorder === sessionOfferTypes.ANY_RECORDER_IN_TEAM;
			const isOpenPosition = session.recorder === sessionOfferTypes.OPEN_POSITION_RECORDER;

			return (
				modifiedSessions?.[session.id] ||
				filters.recorders.includes(session.recorder) ||
				(filters.recorders.includes("noTechnicianBooked") &&
					(isOpenPosition ||
						(isAnyRecorderInTeam && (!filters.hideUnavailable || session.teamUserIds?.includes(user.id)))))
			);
		});
	}

	if (filters.managers.length > 0) {
		filteredSessions = filteredSessions.filter(
			(session) =>
				modifiedSessions?.[session.id] ||
				session.managerIds?.find((manager) => filters.managers.includes(manager)),
		);
	}

	if (filters.studios.length > 0 && viewType.includes("timeGrid")) {
		filteredSessions = filteredSessions.filter(
			(session) => modifiedSessions?.[session.id] || filters.studios?.includes(session.studio),
		);
	}

	if (filters.statusFilters.booked || filters.statusFilters.cancelled || filters.statusFilters.completed) {
		filteredSessions = filteredSessions.filter(
			(session) =>
				modifiedSessions?.[session.id] ||
				(filters.statusFilters.booked && session.status === "booked") ||
				(filters.statusFilters.cancelled && session.status === "cancelled") ||
				(filters.statusFilters.completed && session.status === "done"),
		);
	}

	return [sessions, filteredSessions, loading, error];
}
