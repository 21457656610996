import { useOutletContext } from "react-router-dom";

import organizationTypes from "astrid-firestore/src/api/organizations/constants/organizationTypes";

import Unauthorized from "../../ui/components/Unauthorized/Unauthorized";

import featureFlags from "../../authorization/constants/featureFlags";
import useFeatureFlag from "../../authorization/hooks/useFeatureFlag";
import useHasRight from "../../authorization/hooks/useHasRight";

import DistributionsView from "../views/DistributionsView/DistributionsView";

export default function Distributions() {
	const { organization } = useOutletContext();

	const hasRight = useHasRight();
	const hasFlatteningFeature = useFeatureFlag(featureFlags.FLATTENING);

	if (!hasFlatteningFeature || !hasRight("distributions.view")) {
		return <Unauthorized />;
	}

	const publisher = organization?.type === organizationTypes.PUBLISHER ? organization : undefined;

	return <DistributionsView publisher={publisher} />;
}
