import { useMemo } from "react";
import { useTranslation } from "react-i18next";

import db from "astrid-firebase/src/db";
import useDocumentDataOnce from "astrid-firestore/src/hooks/useDocumentDataOnce";

export default function useProductionTagOptions() {
	const { t } = useTranslation();

	const [productionTags, loading, error] = useDocumentDataOnce(
		useMemo(() => {
			return db.collection("settings").doc("tags");
		}, []),
		{ idField: "id" },
	);

	const options = useMemo(
		() =>
			productionTags
				? productionTags?.production.map((tag) => ({
						key: tag,
						value: tag,
						text: t(`tag-${tag}`, tag),
				  }))
				: [],
		[productionTags, t],
	);

	return [options, loading, error];
}
