import { Icon } from "semantic-ui-react";

import React from "react";

import Flex from "../../../components/Flex/Flex";

import DropdownMenu from "../components/DropdownMenu/DropdownMenu";

export function timestampColumn({ id, Header, time = true, ...props }) {
	return {
		id,
		Header,
		noWrap: true,
		accessor: (data) => data[id]?.toDate?.().getTime() || "",
		Cell: ({ value }) =>
			value ? (
				<span className="monospace">
					{time ? new Date(value).toLocaleString() : new Date(value).toLocaleDateString()}
				</span>
			) : null,
		...props,
	};
}

export function dropdownMenuColumn(getDropdownMenuOptions, ...props) {
	return {
		id: "menu",
		sticky: true,
		collapsing: true,
		disableSortBy: true,
		onClick: () => {
			// Just so that the row click is disabled
		},
		Cell: ({ row, rows }) => {
			const upward = row.index > rows.length - 3;

			return (
				<DropdownMenu
					upward={upward}
					direction={false}
					pointing="right"
					options={getDropdownMenuOptions(row.original)}
				/>
			);
		},
		...props,
	};
}

export function reorderColumn({ onReorder, ...props }) {
	if (!onReorder) {
		return null;
	}

	return {
		id: "order",
		sticky: true,
		collapsing: true,
		disableSortBy: true,
		onClick: () => {
			// Just so that the row click is disabled
		},
		Cell: ({ row, data }) => {
			const rowIndex = row.index;

			const canMoveUp = rowIndex > 0;
			const canMoveDown = rowIndex < data.length - 1;

			return (
				<Flex>
					<div style={{ padding: "0px 8px" }} onClick={() => canMoveUp && onReorder(rowIndex, rowIndex - 1)}>
						<Icon name="arrow up" fitted link={canMoveUp} disabled={!canMoveUp} />
					</div>
					<div
						style={{ padding: "0px 8px" }}
						onClick={() => canMoveDown && onReorder(rowIndex, rowIndex + 1)}
					>
						<Icon name="arrow down" fitted link={canMoveDown} disabled={!canMoveDown} />
					</div>
				</Flex>
			);
		},
		...props,
	};
}
