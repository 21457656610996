import { useTranslation } from "react-i18next";

import offerStatuses from "astrid-firestore/src/api/offers/constants/offerStatuses";
import todoTypes from "astrid-firestore/src/constants/todoTypes";

import useRoleTranslations from "../../../../productions/hooks/useRoleTranslations";

export default function NotificationContent({ todo }) {
	const { t } = useTranslation();
	const roleTranslations = useRoleTranslations();
	const { type, content } = todo;

	switch (type) {
		case todoTypes.READER_INQUIRY_STATUS_CHANGED:
			if (content.offer.status === offerStatuses.INTERESTED) {
				return <i>{t("readerIsInterested", "{{reader}} is interested", { reader: content.reader.name })}</i>;
			}

			if (content.offer.status === offerStatuses.DECLINED) {
				return <i>{t("readerHasDeclined", "{{reader}} has declined", { reader: content.reader.name })}</i>;
			}

			return null;

		case todoTypes.OFFER_STATUS_CHANGED:
			if (content.offer.status === offerStatuses.ACCEPTED) {
				return (
					<i>
						{t("recipientHasAcceptedAs", "{{recipient}} has accepted as {{role}}", {
							recipient: content.secondParty.name,
							role: roleTranslations[content.offer.role],
						})}
					</i>
				);
			}

			if (content.offer.status === offerStatuses.DECLINED) {
				return (
					<i>
						{t("recipientHasDeclinedAs", "{{recipient}} has declined as {{role}}", {
							recipient: content.secondParty.name,
							role: roleTranslations[content.offer.role],
						})}
					</i>
				);
			}

			return null;

		case todoTypes.NEW_COMMENT_ON_OFFER:
			return (
				<i>
					{t("userWroteComment", "{{user}} wrote: {{text}}", {
						user: content.user.name,
						text: content.comment.text,
					})}
				</i>
			);

		case todoTypes.SUBCONTRACTOR_UPLOADED_FILES:
			return (
				<i>
					{Object.entries(content.files || {})
						.map(
							([article, { length, external }]) =>
								t("filesWereUploadedTo", "{{amount}} files were uploaded to {{article}}", {
									amount: length,
									article,
								}) + (external ? ` (${t("external", "External")})` : ""),
						)

						.join(", ")}
				</i>
			);
		default:
			return null;
	}
}
