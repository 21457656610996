import moment from "moment/min/moment-with-locales";

import { Icon, Popup } from "semantic-ui-react";

import React from "react";

const DeliveryDatesPopup = ({ production, t }) => {
	return (
		<Popup trigger={<Icon color="grey" name="truck" size="large" className={"settings-popup-icon "} />} on="click">
			<Popup
				inverted
				size="huge"
				trigger={
					<div>
						<p>
							{production.deliveryDate && production.deliveryDate.toDate
								? t("audioBook") + ": " + moment(production.deliveryDate.toDate()).format("YYYY-MM-DD")
								: ""}
						</p>
						<p>
							{production.deliveryEbook &&
							production.deliveryEbook.delivery &&
							production.deliveryEbook.delivery.toDate
								? t("eBook") +
								  ": " +
								  moment(production.deliveryEbook.delivery.toDate()).format("YYYY-MM-DD")
								: ""}
						</p>
						<p>
							{production.deliveryCD &&
							production.deliveryCD.delivery &&
							production.deliveryCD.delivery.toDate
								? t("CD") + ": " + moment(production.deliveryCD.delivery.toDate()).format("YYYY-MM-DD")
								: ""}
						</p>
						<p>
							{production.deliveryMP3CD &&
							production.deliveryMP3CD.delivery &&
							production.deliveryMP3CD.delivery.toDate
								? t("mp3cd") +
								  ": " +
								  moment(production.deliveryMP3CD.delivery.toDate()).format("YYYY-MM-DD")
								: ""}
						</p>
						{production && production.deliveryParts
							? production.deliveryParts.map((part, index) => {
									return part.delivery && part.delivery.toDate ? (
										<p>
											{t("part") +
												`:${index + 1} ` +
												moment(part.delivery.toDate()).format("YYYY-MM-DD")}
										</p>
									) : null;
							  })
							: null}
					</div>
				}
			/>
		</Popup>
	);
};

export default DeliveryDatesPopup;
