import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

import { db } from "astrid-firebase";

import CollectionDataTable from "../../../ui/components/CollectionDataTable/CollectionDataTable";

import AdminCard from "../../../admin/components/AdminCard/AdminCard";
import useImportsTableColumns from "../../../imports/hooks/useImportsTableColumns";

export default function ArticleImportsView({ article }) {
	const { t } = useTranslation();

	const navigate = useNavigate();

	const columns = useImportsTableColumns();

	return (
		<AdminCard transparent header={t("imports", "Imports")}>
			<CollectionDataTable
				query={db.collection("imports").where("article.id", "==", article.id)}
				columns={columns}
				onClick={({ id }) => navigate(id)}
				sortBy={{
					id: "created",
					desc: true,
				}}
			/>
		</AdminCard>
	);
}
