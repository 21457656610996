import equal from "fast-deep-equal";

import { Header, Segment } from "semantic-ui-react";

import React from "react";
import { useTranslation } from "react-i18next";

import { ArticleMetaData } from "astrid-firestore/src/api/articles/types/ArticleMetaData";

import DataTable from "../../../ui/components/DataTable/DataTable";
import DropdownMenu from "../../../ui/components/DropdownMenu/DropdownMenu";
import ErrorMessage from "../../../ui/components/Messages/ErrorMessage";
import useBreadcrumbs from "../../../ui/hooks/useBreadcrumbs";
import PageView from "../../../ui/views/PageView/PageView";

import ImportStatusLabel from "../../components/ImportStatus/ImportStatusLabel";
import useImportDropdownMenuOptions from "../../hooks/useImportDropdownMenuOptions";

import { useImportArticleTableColumns } from "./hooks/useImportArticleTableColumns";

function isMetaDataChanged({ id, before, after }) {
	switch (id) {
		case "cover":
			return before?.md5Hash !== after?.md5Hash;
		default:
			return !equal(before ?? null, after ?? null);
	}
}

export default function ImportView({ article, importt }) {
	const { t } = useTranslation();

	const breadcrumbs = useBreadcrumbs({ article, importt });

	const dropdownMenuOptions = useImportDropdownMenuOptions();
	const importArticleColumns = useImportArticleTableColumns();

	return (
		<PageView
			breadcrumbs={breadcrumbs}
			header={importt.created.toDate().toLocaleString()}
			subheader={importt.source}
			extra={
				<>
					<ImportStatusLabel importt={importt} />
					<DropdownMenu options={dropdownMenuOptions(importt)} />
				</>
			}
		>
			{importt.error && <ErrorMessage error={importt.error} />}

			{importt.article && (
				<>
					<Header as="h3">{t("updatedMetadata", "Updated metadata")}</Header>
					<DataTable
						columns={importArticleColumns(importt.article)}
						noDataAvailableMessage={t("noMetadataChanges", "No metadata changes")}
						data={Object.keys(ArticleMetaData.shape)
							.map((id) => ({
								id,
								before: importt.article.before?.[id],
								after: importt.article.after?.[id],
							}))
							.filter(isMetaDataChanged)}
					/>
				</>
			)}

			<Header as="h3">{t("sourceData", "Source data")}</Header>
			<Segment inverted style={{ marginBottom: 7, overflowX: "auto" }}>
				<pre>{JSON.stringify(importt.data, null, "  ")}</pre>
			</Segment>
		</PageView>
	);
}
