import { Label } from "semantic-ui-react";

import { useTranslation } from "react-i18next";

import pipelineStepStatuses from "astrid-firestore/src/api/pipelines/constants/pipelineStepStatuses";

import Flex from "../../../../../components/Flex/Flex";
import FlexRow from "../../../../../components/Flex/FlexRow";
import Accordion from "../../../../ui/components/Accordion/Accordion";
import ProgressLabel from "../../../../ui/components/ProgressLabel/ProgressLabel";

import pipelineStatusColors from "../../../utils/pipelineStatusColors";

import PipelineStatus from "../../PipelineStatus/PipelineStatus";

export default function PipelineStepTitle({ steps, step, active, progress, onClick }) {
	const { t } = useTranslation();

	const stepProgress = progress?.[step.key];

	const status = step.disabled ? pipelineStepStatuses.DISABLED : stepProgress?.status || pipelineStepStatuses.PENDING;

	const progressInPercent = Math.round(
		((stepProgress?.processing + stepProgress?.processed) / stepProgress?.total) * 100,
	);

	const showProgress = status === pipelineStepStatuses.RUNNING;

	return (
		<Accordion.Title active={active} onClick={onClick}>
			<Flex style={{ gap: 10 }}>
				<div>{step.name}</div>

				<FlexRow style={{ gap: 10 }}>
					{step.needs && (
						<Label basic>
							{step.forEach ? t("forEach", "For each") : t("needs", "Needs")}

							<Label.Detail>{steps?.find((s) => s.key === step.needs)?.name}</Label.Detail>
						</Label>
					)}

					{progress && (
						<ProgressLabel
							color={pipelineStatusColors[status]}
							percent={progressInPercent || null}
							active={showProgress}
						>
							<PipelineStatus status={status} />

							{showProgress && !!progressInPercent && ` ${progressInPercent} %`}
						</ProgressLabel>
					)}
				</FlexRow>
			</Flex>
		</Accordion.Title>
	);
}
