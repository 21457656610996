import { Button, Icon, Label } from "semantic-ui-react";

import { useTranslation } from "react-i18next";

import Flex from "../../../../../components/Flex/Flex";
import useHasRight from "../../../../authorization/hooks/useHasRight";
import SecondaryButton from "../../../../ui/components/Buttons/SecondaryButton";

import CalendarDoneOrNotifyButton from "./CalendarDoneOrNotifyButton";

export default function CalendarHeaderToolbarTools({
	desktop,
	tablet,
	onClickFilter,
	filterForm,
	isEditing,
	onClickEdit,
	isBooking,
	onClickBook,
	onStopBooking,
	modifiedSessions,
	onOpenNotifyModal,
	resourceType,
}) {
	const { t } = useTranslation();
	const hasRight = useHasRight();

	const canEditSessions = hasRight("calendar.editAllSessions") || hasRight("calendar.editOwnSessions");

	return (
		<Flex justifyContent="flex-start" style={{ flex: 0, whiteSpace: "nowrap" }}>
			{tablet && (
				<div style={{ position: "relative" }}>
					<Button basic size="tiny" onClick={onClickFilter} style={{ paddingLeft: 16, paddingRight: 16 }}>
						<Icon name="filter" />
						{desktop && t("filters", "Filters")}
					</Button>

					{filterForm.activeFilters > 0 && (
						<Label color="blue" floating circular size="tiny">
							{filterForm.activeFilters}
						</Label>
					)}
				</div>
			)}

			{canEditSessions && !isEditing && (
				<SecondaryButton icon size="tiny" onClick={onClickEdit}>
					<Icon name="pencil" />
					{desktop && t("editSessions", "Edit sessions")}
				</SecondaryButton>
			)}

			{!isBooking && !isEditing && canEditSessions && (
				<SecondaryButton
					disabled={isEditing || resourceType !== "studios"}
					size="tiny"
					onClick={onClickBook}
					basic
					icon
				>
					<Icon name="calendar plus outline" />
					{desktop && t("book", "Book")}
				</SecondaryButton>
			)}

			{(isBooking || isEditing) && (
				<CalendarDoneOrNotifyButton
					onOpenNotifyModal={onOpenNotifyModal}
					onStopBooking={onStopBooking}
					modifiedSessions={modifiedSessions}
				/>
			)}
		</Flex>
	);
}
