import getCollectionData from "../../utils/getCollectionData";

export default async function validateArticleISBN({ ref, isbn }) {
	if (isbn) {
		const articles = await getCollectionData(ref.parent.where("isbn", "==", isbn).limit(2));

		if (articles.find((article) => article.id !== ref.id)) {
			throw new Error("An article with the same ISBN already exists");
		}
	}
}
