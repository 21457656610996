import React from "react";
import { useTranslation } from "react-i18next";

import Flex from "../../../components/Flex/Flex";
import CoverImageStack from "../../ui/components/CoverImage/CoverImageStack";
import themeColors from "../../ui/constants/themeColors";
import { dropdownMenuColumn, timestampColumn } from "../../ui/utils/columns";

import useWorksTableDropdownMenuOptions from "./useWorksTableDropdownMenuOptions";

export default function useWorksTableColumns() {
	const { t } = useTranslation();

	const dropdownMenuOptions = useWorksTableDropdownMenuOptions();

	return [
		{
			id: "name",
			collapsing: true,
			Header: t("title", "Title"),
			Cell: ({ value, row }) => {
				return (
					<Flex justifyContent="flex-start">
						<Flex style={{ marginRight: "1.1em", height: 45, width: 45 }}>
							<CoverImageStack thumb rounded data={row.original} size={45} style={{ margin: "0 auto" }} />
						</Flex>

						<div>
							{value}
							<div style={{ color: themeColors.GREY }}>{row.original.subtitle}</div>
						</div>
					</Flex>
				);
			},
		},
		{
			id: "contributors",
			Header: t("authors", "Authors"),
			Cell: ({ value }) => {
				return value
					.filter(({ role }) => role === "author")
					.map(({ firstName, lastName }) => `${firstName} ${lastName}`)
					.join(", ");
			},
		},
		{
			id: "serie",
			disableSortBy: true,
			Header: t("series", "Series"),
			accessor: ({ serie }) => serie?.name,
		},
		timestampColumn({ id: "updated", Header: t("updated", "Updated"), time: false }),
		timestampColumn({ id: "created", Header: t("created", "Created"), time: false }),
		dropdownMenuColumn(dropdownMenuOptions),
	];
}
