import { Segment, Table as SemanticTable, TableBody, TableHeader, TableRow } from "semantic-ui-react";

import React from "react";

import renderChildren from "../../../utils/renderChildren";

import useTable from "../hooks/useTable";

import TableCell from "./TableCell";
import TableCellCheckbox from "./TableCellCheckbox";
import TableCellDropdownMenu from "./TableCellDropdownMenu";
import TableCellValue from "./TableCellValue";
import TableColumnSelector from "./TableColumnSelector";
import TableContext from "./TableContext";
import TableHeaderCell from "./TableHeaderCell";
import TableHeaderCellCheckbox from "./TableHeaderCellCheckbox";

export default function Table({
	data,
	columns,
	onClick,
	children,
	enableCheckboxes,
	enableColumnSelector,
	getDropdownMenuOptions,
}) {
	const table = useTable({ data, columns });

	const { filteredColumns } = table;

	return (
		<TableContext.Provider value={table}>
			<Segment style={{ padding: 0, overflowX: "auto", scrollbarWidth: "thin" }}>
				<SemanticTable padded unstackable selectable={!!onClick} style={{ border: "none" }}>
					<TableHeader>
						<TableRow>
							{enableCheckboxes && <TableHeaderCellCheckbox />}

							{filteredColumns.map((column) => (
								<TableHeaderCell key={column.id} {...column}>
									{renderChildren(column.Header, { column })}
								</TableHeaderCell>
							))}

							{enableColumnSelector && <TableColumnSelector />}

							{!enableColumnSelector && getDropdownMenuOptions && <TableHeaderCell />}
						</TableRow>
					</TableHeader>

					<TableBody>
						{data.map((row) => {
							return (
								<TableRow key={row.id}>
									{enableCheckboxes && <TableCellCheckbox row={row} />}

									{filteredColumns.map((column) => (
										<TableCell key={column.id} onClick={() => onClick(row)} {...column}>
											<TableCellValue row={row} column={column} />
										</TableCell>
									))}

									{getDropdownMenuOptions && (
										<TableCellDropdownMenu
											upward={row.index > data.length - 3}
											options={getDropdownMenuOptions(row)}
										/>
									)}

									{!getDropdownMenuOptions && enableColumnSelector && <TableHeaderCell />}
								</TableRow>
							);
						})}
					</TableBody>
				</SemanticTable>
			</Segment>

			{renderChildren(children, table)}
		</TableContext.Provider>
	);
}
