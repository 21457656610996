import { Icon } from "semantic-ui-react";

import { useTranslation } from "react-i18next";

import organizationTypes from "astrid-firestore/src/api/organizations/constants/organizationTypes";
import { languageName } from "astrid-helpers/src/languages";

import ProgressBar from "../../../../../components/progressbar/ProgressBar";
import truncateString from "../../../../ui/utils/truncateString";

import ProductionStatusLabel from "../../../components/ProductionStatus/ProductionStatusLabel";
import useProductionTagTranslations from "../../../hooks/useProductionTagTranslations";
import useProductionTypeTranslations from "../../../hooks/useProductionTypeTranslations";

export default function useProductionsColumns({ organization }) {
	const { t } = useTranslation();

	const tagTranslations = useProductionTagTranslations();
	const typeTranslations = useProductionTypeTranslations();

	return [
		{
			id: "status",
			Header: t("status"),
			collapsing: true,
			Cell: ({ value }) => <ProductionStatusLabel status={value} />,
		},
		{
			id: "title",
			Header: t("title"),
			collapsing: true,
			accessor: ({ title }) => truncateString(title, 30),
		},
		{
			id: "productionType",
			Header: t("type", "Type"),
			accessor: ({ productionType }) => typeTranslations[productionType],
		},
		{
			id: "progressBar",
			Header: t("progress"),
			Cell: ({ value }) => {
				return <ProgressBar {...value} />;
			},
		},
		{
			id: "publisherName",
			Header: t("publisher", "Publisher"),
			collapsing: true,
		},
		{
			id: "producerName",
			Header: t("producer", "Producer"),
			collapsing: true,
		},
		{
			id: "author",
			Header: t("author", "Author"),
			accessor: ({ author }) => truncateString(author?.join(",") || "", 30),
			collapsing: true,
		},
		{
			id: "isbn",
			Header: t("isbn", "ISBN"),
			collapsing: true,
			accessor: ({ isbn, ebookISBN, partISBN }) =>
				[isbn, ebookISBN, ...(partISBN || [])].filter(Boolean).join(", "),
		},
		{
			id: "productionTags",
			Header: t("tags", "Tags"),
			accessor: ({ productionTags }) => productionTags?.map((tag) => tagTranslations[tag]).join(", "),
		},
		{
			id: "series",
			Header: t("serie", "Series"),
			accessor: ({ series, part }) => `${series || ""} ${part || ""}`,
		},
		{
			id: "language",
			Header: t("language", "Language"),
			collapsing: true,
			accessor: ({ language }) => languageName(language, false),
		},
		{
			id: "noOfCorrections",
			Header: t("noOfCorrections"),
			accessor: ({ progressBar }) => progressBar?.corrected?.progressText,
		},
		{
			id: "publisherManagerNames",
			Header: t("publisherManager"),
			accessor: ({ publisherManagerNames }) => publisherManagerNames?.join(", "),
		},
		{
			id: "readerNames",
			Header: t("narrator"),
			accessor: ({ readerNames }) => readerNames?.join(", "),
		},
		{
			id: "voiceNames",
			Header: t("voices", "Voices"),
			accessor: ({ voiceNames }) => voiceNames?.join(", "),
		},
		{
			id: "editorNames",
			Header: t("editor"),
			accessor: ({ editorNames }) => editorNames?.join(", "),
		},
		{
			id: "prooferNames",
			Header: t("proofer"),
			accessor: ({ prooferNames }) => prooferNames?.join(", "),
		},
		{
			id: "managerNames",
			Header: t("productionManager"),
			accessor: ({ managerNames }) => managerNames?.join(", "),
		},
		{
			id: "subcontractorName",
			Header: t("subcontractor"),
		},
		{
			id: "duration",
			Header: t("duration"),
			collapsing: true,
			accessor: ({ duration }) =>
				duration?.time && duration?.isEstimated ? `${duration.time} *` : duration?.time,
		},
		{
			id: "scriptDate",
			Header: t("script"),
			collapsing: true,
			Cell: ({ value }) => {
				return (
					<p style={{ display: "flex", alignItems: "baseline" }}>
						<span style={{ whiteSpace: "nowrap" }}>{value?.date}</span>
						{value?.date && value?.isUploaded && (
							<Icon style={{ marginLeft: "5px" }} name="check" color="green" />
						)}
					</p>
				);
			},
		},
		{
			id: "firstDoneDate",
			Header: t("doneDate"),
			collapsing: true,
		},
		{
			id: "created",
			Header: t("created"),
			collapsing: true,
		},
		{
			id: "updated",
			Header: t("updated"),
			collapsing: true,
		},
		{
			id: "deliveryDate",
			Header: t("deliveryDate"),
			collapsing: true,
		},
		{
			id: "productionDate",
			Header: t("prodDate"),
			hidden: organization?.type === organizationTypes.PUBLISHER,
			collapsing: true,
		},
	];
}
