import { Dropdown } from "semantic-ui-react";

import DropdownMenu from "../../../ui/components/DropdownMenu/DropdownMenu";

import CalendarResourceHeaderButtons from "./CalendarResourceHeaderButtons";

export default function CalendarResourceHeaderDropdown(props) {
	return (
		<DropdownMenu direction="right">
			{() => (
				<>
					<Dropdown.Item>
						<CalendarResourceHeaderButtons {...props} />
					</Dropdown.Item>
				</>
			)}
		</DropdownMenu>
	);
}
