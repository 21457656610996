import { useTranslation } from "react-i18next";

import organizationTypes from "astrid-firestore/src/api/organizations/constants/organizationTypes";

import featureFlags from "../../../../authorization/constants/featureFlags";
import useFeatureFlag from "../../../../authorization/hooks/useFeatureFlag";

export default function useOrganizationSettingsMenuOptions({ organization }) {
	const { t } = useTranslation();

	const hasFlatteningFeature = useFeatureFlag(featureFlags.FLATTENING);

	if (organization?.type === organizationTypes.PRODUCER) {
		return [
			{
				path: "vendors",
				name: t("vendorSettings"),
			},
			{
				path: "language",
				name: t("language", "Language"),
			},
			{
				path: "finance",
				name: t("finance", "Finance"),
			},
			{
				path: "studio",
				name: t("studio", "Studio"),
			},
		];
	}

	if (organization?.type === organizationTypes.PUBLISHER) {
		return [
			{
				path: "vendors",
				name: t("vendorSettings"),
			},
			{
				path: "distribution",
				name: t("distribution", "Distribution"),
				hidden: !hasFlatteningFeature,
			},
			{
				path: "finance",
				name: t("finance", "Finance"),
			},
			{
				path: "language",
				name: t("language", "Language"),
			},
		];
	}

	return [];
}
