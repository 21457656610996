import equal from "fast-deep-equal";

import React, { useState } from "react";
import { useTranslation } from "react-i18next";

import Form from "../../../../forms/components/Form/Form";
import ThemaModal from "../../../../thema/components/ThemaModal";

import ArticleMetaDataLabel from "./ArticleMetaDataLabel";

export default function ArticleMetaDataThemaCodes({ work, article, ...props }) {
	const { t } = useTranslation();

	const { watch, setValue } = Form.useFormContext();

	const themaCodes = watch("themaCodes");

	const [open, setOpen] = useState(false);

	return (
		<>
			<Form.Select
				name="themaCodes"
				label={
					<ArticleMetaDataLabel
						text={t("themaCodes", "Thema codes")}
						condition={work && !equal(article.themaCodes, work.themaCodes)}
					/>
				}
				options={themaCodes?.map((code) => ({
					key: code,
					text: code,
					value: code,
				}))}
				onClick={() => setOpen(true)}
				allowEmpty
				multiple
				{...props}
			/>

			{open && (
				<ThemaModal
					value={themaCodes}
					onClose={({ themaCodes }) => {
						setValue("themaCodes", themaCodes, { shouldDirty: true });
						setOpen(false);
					}}
				/>
			)}
		</>
	);
}
