import { useTranslation } from "react-i18next";

import formatBytes from "astrid-helpers/src/formatBytes";
import msToTime from "astrid-helpers/src/msToTime";

import { reorderColumn } from "../../ui/utils/columns";

export default function useArtifactFilesTableColumns({ onReorder } = {}) {
	const { t } = useTranslation();

	return [
		{
			id: "name",
			filter: "text",
			Header: t("Name", "Name"),
		},
		{
			id: "size",
			Header: t("size", "Size"),
			collapsing: true,
			accessor: ({ size }) => formatBytes(size),
		},
		{
			id: "contentType",
			Header: t("contentType", "Content-Type"),
		},
		{
			id: "duration",
			Header: t("duration", "Duration"),
			accessor: ({ duration }) => (duration ? msToTime(duration * 1000, false) : null),
		},
		{
			id: "channels",
			Header: t("channels", "Channels"),
			accessor: ({ format }) => (format ? (format.channels > 1 ? "Stereo" : "Mono") : null),
		},
		{
			id: "sampleRate",
			Header: t("sampleRate", "Sample Rate"),
			accessor: ({ format }) => (format ? `${format.sampleRate / 1000} kHz` : null),
		},
		{
			id: "bitDepth",
			Header: t("bitDepth", "Bit Depth"),
			accessor: ({ format }) => format?.bitDepth || null,
		},
		{
			id: "sequence",
			Header: t("sequence", "Sequence"),
			Cell: ({ row }) => {
				const index = row.index + 1;
				return index.toString().padStart(3, "0");
			},
		},
		reorderColumn({ onReorder }),
	];
}
