import React from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

import Button from "../../../ui/components/Buttons/Button";

export default function DashboardStatisticButton({ organization, link, color }) {
	const { t } = useTranslation();

	return (
		<Button
			as={Link}
			to={organization ? `/${organization?.type}/${organization?.id}/${link}` : link}
			fluid
			circular
			color={color}
			size="tiny"
			style={{ marginTop: 10 }}
		>
			{t("view", "View")}
		</Button>
	);
}
