import React from "react";
import { useTranslation } from "react-i18next";

import Form from "../../../../forms/components/Form/Form";
import useNumberOptions from "../../../../ui/hooks/useNumberOptions";

import ArticleMetaDataLabel from "./ArticleMetaDataLabel";

export default function ArticleMetaDataSerieNumberSelect({ work, article, ...props }) {
	const { t } = useTranslation();

	const { watch } = Form.useFormContext();

	const serie = watch("serie");

	const numberOptions = useNumberOptions();

	return (
		<Form.Select
			name="serieNumber"
			label={
				<ArticleMetaDataLabel
					text={t("part", "Part")}
					condition={work && article.serieNumber !== work.serieNumber}
				/>
			}
			options={numberOptions}
			disabled={!serie}
			allowEmpty
			search
			{...props}
		/>
	);
}
