import { useMemo } from "react";

import useDocumentData from "astrid-firestore/src/hooks/useDocumentData";

export default function useSeasonOptions(serie) {
	const [data, loading, error] = useDocumentData(serie?.ref);

	const options = useMemo(() => {
		return (
			data?.seasons?.map((season) => ({
				key: season.id,
				value: season.id,
				text: season.name,
				data: season,
			})) || []
		);
	}, [data?.seasons]);

	return [options, loading, error];
}
