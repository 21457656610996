import firebase from "firebase";
import uniqid from "uniqid";

import artifactStatuses from "astrid-firestore/src/api/artifacts/constants/artifactStatuses";
import updateArtifact from "astrid-firestore/src/api/artifacts/updateArtifact";
import useBucket from "astrid-hooks/src/useBucket";

import { useProfile } from "../../../../authentication/state/profile";
import useSimpleForm from "../../../../forms/hooks/useSimpleForm";

export default function useArtifactManagerForm({ artifact }) {
	const user = useProfile();

	const defaultValues = {
		files: artifact.files,
		markers: artifact.markers
			.sort((a, b) => a.position - b.position)
			.map((marker) => ({ ...marker, id: String(marker.id) || uniqid() })),
	};

	const { deleteFiles } = useBucket("earselect-artifacts");

	const { form, formState, reset, onSubmit } = useSimpleForm({
		defaultValues,
		onSubmit: async ({ files, markers }) => {
			const filesToDelete = artifact.files.filter((file) => !files.some(({ name }) => name === file.name));

			await updateArtifact(firebase, {
				ref: artifact.ref,
				article: artifact.article,
				files,
				markers,
				status: artifactStatuses.PENDING,
				updatedBy: user,
			});

			if (filesToDelete?.length) {
				await deleteFiles(filesToDelete);
			}
		},
	});

	const onReset = () => {
		reset(defaultValues);
	};

	return {
		form,
		formState,
		onSubmit,
		onReset,
	};
}
