import { Header } from "semantic-ui-react";

import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";

import organizationTypes from "astrid-firestore/src/api/organizations/constants/organizationTypes";
import { OrganizationRef } from "astrid-firestore/src/api/organizations/types/OrganizationRef";
import { nullable, object, record, string } from "astrid-firestore/src/types/_types";

import AdminForm from "../../../../admin/components/AdminForm/AdminForm";
import useBiblioImprints from "../../../../biblio/hooks/useBiblioImprints";
import Form from "../../../../forms/components/Form/Form";
import useSimpleForm from "../../../../forms/hooks/useSimpleForm";
import useOrganizationOptions from "../../../../organizations/hooks/useOrganizationOptions";
import FlexTable from "../../../../ui/components/FlexTable/FlexTable";
import LoadingContext from "../../../../ui/components/LoadingContext/LoadingContext";

import useBiblioPublishersColumns from "../hooks/useBiblioPublishersColumns";

const schema = object({
	defaultProducerId: nullable(string()),
	publishers: record(
		string(),
		object({
			name: nullable(string()),
			publisher: nullable(OrganizationRef),
		}),
	),
});

export default function BiblioSettingsForm({ settings }) {
	const { t } = useTranslation();

	const { form, error, onSubmit } = useSimpleForm({
		defaultValues: {
			defaultProducerId: settings.defaultProducerId || null,
			publishers: settings.publishers || {},
		},
		onSubmit: (values) => {
			return settings.ref.set(schema.parse(values), { merge: true });
		},
	});

	const [data, loading, loadingError] = useBiblioImprints({ limit: 1000 });

	const publishers = useMemo(
		() => Object.values(data?.reduce((acc, { publisher }) => ({ ...acc, [publisher.id]: publisher }), {}) || {}),
		[data],
	);

	const publishersColumns = useBiblioPublishersColumns(form);
	const producerOptions = useOrganizationOptions({ type: organizationTypes.PRODUCER });

	return (
		<AdminForm form={form} error={error} onSubmit={onSubmit}>
			<Form.Group>
				<Form.Select
					name="defaultProducerId"
					label={t("defaultProducer", "Default producer")}
					options={producerOptions}
				/>
			</Form.Group>

			<Header as="h4">{t("publishers", "Publishers")}</Header>
			<LoadingContext data={data} loading={loading} error={loadingError}>
				<FlexTable data={publishers} columns={publishersColumns} />
			</LoadingContext>
		</AdminForm>
	);
}
