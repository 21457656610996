import { Route } from "react-router-dom";

import Production from "../pages/Production/Production";
import ProductionArticles from "../pages/Production/ProductionArticles";
import ProductionAudiobook from "../pages/Production/ProductionAudiobook";
import ProductionDiscussions from "../pages/Production/ProductionDiscussions";
import ProductionFinance from "../pages/Production/ProductionFinance";
import ProductionOverview from "../pages/Production/ProductionOverview";
import ProductionReaders from "../pages/Production/ProductionReaders";
import ProductionSettings from "../pages/Production/ProductionSettings";
import ProductionTeam from "../pages/Production/ProductionTeam";

export const production = (
	<Route path="production/:productionId" element={<Production />}>
		<Route index element={<ProductionOverview />} />
		<Route path="info" element={<ProductionOverview />}>
			<Route path=":modal" element={null} />
		</Route>

		<Route path="articles" element={<ProductionArticles />} />

		<Route path="audiobook" element={<ProductionAudiobook tab="audiobook" />} />

		<Route path="readers" element={<ProductionReaders />} />

		<Route path="team" element={<ProductionTeam />} />

		<Route path="discussions" element={<ProductionDiscussions />}>
			<Route path="internal" element={null} />
		</Route>

		<Route path="internal" element={<ProductionOverview />} />

		<Route path="finance" element={<ProductionFinance />}>
			<Route path=":partyId" element={null} />
		</Route>

		<Route path="settings" element={<ProductionSettings />} />
	</Route>
);
