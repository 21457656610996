import React from "react";

import DropdownMenu from "../../ui/components/DropdownMenu/DropdownMenu";

import TableCell from "./TableCell";

export default function TableCellDropdownMenu({ upward, options }) {
	return (
		<TableCell sticky collapsing>
			<DropdownMenu
				fitted
				upward={upward}
				direction={false}
				pointing="right"
				options={options}
				style={{
					position: "absolute",
					display: "flex",
					top: 0,
					left: 0,
					width: "100%",
					height: "100%",
					justifyContent: "center",
					alignItems: "center",
				}}
				iconStyle={{
					height: "auto",
				}}
			/>
		</TableCell>
	);
}
