import { useTranslation } from "react-i18next";

import organizationTypes from "astrid-firestore/src/api/organizations/constants/organizationTypes";
import { languageName } from "astrid-helpers/src/languages";

import useRefinementTranslations from "../../../../listproductions/views/ListProductionsView/hooks/translations/useRefinementTranslations";

import useProductionStatusTranslations from "../../../hooks/useProductionStatusTranslations";
import useProductionTagTranslations from "../../../hooks/useProductionTagTranslations";
import useProductionTypeTranslations from "../../../hooks/useProductionTypeTranslations";

export default function useProductionsRefinements({ organization }) {
	const { t } = useTranslation();

	const miscTranslations = useRefinementTranslations();
	const tagTranslations = useProductionTagTranslations();
	const typeTranslations = useProductionTypeTranslations();
	const statusTranslations = useProductionStatusTranslations();

	return [
		{
			header: t("language", "Language"),
			attribute: "language",
			renderItem: ({ label }) => languageName(label, false),
		},
		{
			header: t("status", "Status"),
			attribute: "status",
			collapsed: true,
			renderItem: ({ label }) => statusTranslations[label],
		},
		{
			header: t("type", "Type"),
			attribute: "productionType",
			collapsed: true,
			renderItem: ({ label }) => typeTranslations[label],
		},
		{
			header: t("tags", "Tags"),
			attribute: "productionTags",
			collapsed: true,
			renderItem: ({ label }) => tagTranslations[label],
		},
		{
			header: t("articles", "Articles"),
			attribute: "articles",
			collapsed: true,
			renderItem: ({ label }) => miscTranslations[label],
		},
		{
			header: t("publisher", "Publisher"),
			attribute: "publisherName",
			collapsed: true,
			searchable: true,
			hidden: organization?.type === organizationTypes.PUBLISHER,
		},
		{
			header: t("producer", "Producer"),
			attribute: "producerName",
			collapsed: true,
			searchable: true,
			hidden: organization?.type === organizationTypes.PRODUCER,
		},
		{
			header: t("publisherManager", "Publisher manager"),
			attribute: "publisherManagerNames",
			collapsed: true,
			searchable: true,
		},
		{
			header: t("narrator", "Narrator"),
			attribute: "readerNames",
			collapsed: true,
			searchable: true,
		},
		{
			header: t("voices", "Voices"),
			attribute: "voiceNames",
			collapsed: true,
			searchable: true,
		},
		{
			header: t("editor", "Editor"),
			attribute: "editorNames",
			collapsed: true,
			searchable: true,
			hidden: organization?.type !== organizationTypes.PUBLISHER,
		},
		{
			header: t("proofer", "Proofer"),
			attribute: "prooferNames",
			collapsed: true,
			searchable: true,
			hidden: organization?.type !== organizationTypes.PUBLISHER,
		},
		{
			header: t("productionManager", "Production manager"),
			attribute: "managerNames",
			collapsed: true,
			searchable: true,
		},
		{
			header: t("subcontractor", "Subcontractor"),
			attribute: "subcontractorName",
			collapsed: true,
			searchable: true,
		},
		{
			header: t("author", "Author"),
			attribute: "author",
			collapsed: true,
			searchable: true,
		},
		{
			header: t("serie", "Series"),
			attribute: "series",
			collapsed: true,
			searchable: true,
		},
		{
			header: t("prodDate"),
			type: "dateRange",
			attribute: "productionDate_timestamp",
			collapsed: true,
		},
		{
			header: t("deliveryDate"),
			type: "dateRange",
			attribute: "deliveryDate_timestamp",
			collapsed: true,
		},
		{
			header: t("doneDate"),
			type: "dateRange",
			attribute: "firstDoneDate_timestamp",
			collapsed: true,
		},
		{
			header: t("created"),
			type: "dateRange",
			attribute: "created_timestamp",
			collapsed: true,
		},
		{
			header: t("script"),
			type: "dateRange",
			attribute: "scriptDate.date_timestamp",
			collapsed: true,
		},
	];
}
