const themeColors = {
	GREY: "#777A93",
	ORANGE: "#ff9000",
	BLUE: "#2364ff",
	GREEN: "#00d357",
	RED: "#ff4a3d",
	BLACK: "#000000",
	WHITE: "#ffffff",
	BORDER: "#d4d4d5",
};

export default themeColors;
