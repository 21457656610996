import { useTranslation } from "react-i18next";
import { useRefinementList } from "react-instantsearch";

import productionStatuses from "astrid-firestore/src/api/productions/constants/productionStatuses";

import themeColors from "../../ui/constants/themeColors";

export default function useProducerDashboardStatistics({ organization }) {
	const { t } = useTranslation();

	// const { currencyDefault } = organization;
	//
	// const [exchangeRate, loadingExchangeRate] = useCurrentExchangeRate();
	// const rate = exchangeRate?.rates[currencyDefault] || 1;
	//
	// const costsQuery = db
	// 	.collection("costs")
	// 	.where("firstParty.id", "==", organization.id)
	// 	.where("orderId", "==", null)
	// 	.where("deleted", "==", false);
	//
	// const aggregate = [["total", "sum", "exchange.value"]];

	// const [revenues, loadingRevenues] = useCollectionAggregate(costsQuery.where("expense", "==", false), aggregate);
	// const [expenses, loadingExpenses] = useCollectionAggregate(costsQuery.where("expense", "==", true), aggregate);

	const statusRefinement = useRefinementList({
		attribute: "status",
	});

	const counts = [...statusRefinement.items].reduce(
		(acc, item) => ({
			...acc,
			[item.value]: item.count,
		}),
		{
			[productionStatuses.PRODUCTION]: 0,
			[productionStatuses.ACCEPTED]: 0,
			[productionStatuses.OFFER]: 0,
			[productionStatuses.PLANNING]: 0,
		},
	);

	const statistics = [
		{
			// total: money((revenues?.total || 0) * rate, currencyDefault, 0),
			total: "N/a",
			label: t("unprocessedRevenues", "Unprocessed revenues"),
			color: "green",
			detailColor: themeColors.GREEN,
			// loading: loadingExchangeRate || loadingRevenues,
			link: "finance/revenues",
		},
		{
			// total: money((expenses?.total || 0) * rate, currencyDefault, 0),
			total: "N/a",
			label: t("unprocessedExpenses", "Unprocessed expenses"),
			color: "red",
			detailColor: themeColors.RED,
			// loading: loadingExchangeRate || loadingExpenses,
			link: "finance/expenses",
		},
		{
			total: counts[productionStatuses.PRODUCTION] + counts[productionStatuses.ACCEPTED] || 0,
			label: t("ongoingProductions", "Ongoing productions"),
			color: "blue",
			detailColor: themeColors.BLUE,
			link: "list-productions?refinementList%5Bstatus%5D%5B1%5D=production&refinementList%5Bstatus%5D%5B2%5D=accepted",
		},
		{
			total: counts[productionStatuses.OFFER] + counts[productionStatuses.PLANNING] || 0,
			label: t("offeredProductions", "Offered productions"),
			color: "orange",
			detailColor: themeColors.ORANGE,
			link: "my-productions/requests",
		},
	];

	return statistics;
}
