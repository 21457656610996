import React from "react";
import { useTranslation } from "react-i18next";
import { Link, useNavigate } from "react-router-dom";

import Flex from "../../../../components/Flex/Flex";
import PrimaryButton from "../../../ui/components/Buttons/PrimaryButton";
import InstantSearch from "../../../ui/components/InstantSearch/InstantSearch";
import InstantSearchBox from "../../../ui/components/InstantSearch/InstantSearchBox";
import InstantSearchClear from "../../../ui/components/InstantSearch/InstantSearchClear";
import InstantSearchHits from "../../../ui/components/InstantSearch/InstantSearchHits";
import InstantSearchHitsPerPage from "../../../ui/components/InstantSearch/InstantSearchHitsPerPage";
import InstantSearchPagination from "../../../ui/components/InstantSearch/InstantSearchPagination";
import InstantSearchPresets from "../../../ui/components/InstantSearch/InstantSearchPresets";
import InstantSearchRefinements from "../../../ui/components/InstantSearch/InstantSearchRefinements";
import InstantSearchRefresh from "../../../ui/components/InstantSearch/InstantSearchRefresh";
import InstantSearchSorting from "../../../ui/components/InstantSearch/InstantSearchSorting";
import PageView from "../../../ui/views/PageView/PageView";

import Table from "../../../tables/components/Table";

import useProductionsColumns from "./hooks/useProductionsColumns";
import useProductionsRefinements from "./hooks/useProductionsRefinements";
import useProductionsSorting from "./hooks/useProductionsSorting";

const initialIndex = "productions";

export default function ProductionsView({ organization }) {
	const { t } = useTranslation();

	const navigate = useNavigate();

	const sorting = useProductionsSorting();
	const columns = useProductionsColumns({ organization });
	const refinements = useProductionsRefinements({ organization });

	const presetsPath = `productions-${organization?.id || "admin"}`;

	const configure = {
		filters: organization && `${organization.type}:${organization.id}`,
	};

	return (
		<InstantSearch routing indexName={initialIndex} configure={configure}>
			<InstantSearchRefresh refreshInterval={5000} />

			<PageView
				header={t("productions", "Production")}
				maxWidth={1600}
				extra={
					<>
						{organization && (
							<PrimaryButton as={Link} to={`/${organization.type}/${organization.id}/create-production`}>
								{t("createProduction", "Create production")}
							</PrimaryButton>
						)}
					</>
				}
				sidebar={
					<>
						<InstantSearchBox />
						<InstantSearchRefinements refinements={refinements} />
					</>
				}
			>
				<Flex gap={10} justifyContent="flex-start">
					<Flex gap={10} justifyContent="flex-start">
						<InstantSearchPresets path={presetsPath} refinements={refinements} />
						<InstantSearchClear />
					</Flex>
					<InstantSearchHitsPerPage />
					<InstantSearchSorting items={sorting} />
				</Flex>

				<InstantSearchHits>
					<Table
						columns={columns}
						onClick={({ id }) => navigate(`/production/${id}`)}
						enableColumnSelector
						getDropdownMenuOptions={({ id }) => [
							{
								text: t("toProduction", "To production"),
								linkTo: `/production/${id}`,
							},
						]}
					/>
				</InstantSearchHits>

				<InstantSearchPagination />
			</PageView>
		</InstantSearch>
	);
}
