const distributionStatuses = {
	SCHEDULED: "scheduled",
	QUEUED: "queued",
	PENDING: "pending",
	INITIALIZED: "initialized",
	RUNNING: "running",
	CANCELLED: "cancelled",
	COMPLETED: "completed",
	FAILED: "failed",
};

export default distributionStatuses;
