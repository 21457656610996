import { useTranslation } from "react-i18next";

import ModalPrompt from "../../../ui/components/ModalPrompt/ModalPrompt";

import Form from "../../../forms/components/Form/Form";

import useProductionOfferTypeOptions from "../../hooks/useProductionOfferTypeOptions";

import useProductionOfferForm from "./hooks/useProductionOfferForm";
import useProductionPriceOptions from "./hooks/useProductionPriceOptions";
import submitOffer from "./utils/submitOffer";

export default function ProductionOfferForm({ production, afterSubmit, ...props }) {
	const { t } = useTranslation();

	const form = useProductionOfferForm();

	const { formState, handleSubmit } = form;

	const productionTypeOptions = useProductionOfferTypeOptions();

	const [productionPriceOptions, otherPriceOptions] = useProductionPriceOptions(production);

	const onSubmit = async ({ productionType, productionPriceId, otherPriceIds }) => {
		const productionPrice = productionPriceOptions.find((option) => option.value === productionPriceId).data;

		const otherPrices = otherPriceOptions
			.filter((option) => otherPriceIds.includes(option.value))
			.map((option) => option.data);

		await submitOffer({
			production,
			productionType,
			productionPrice,
			otherPrices,
		});

		afterSubmit?.();
	};

	return (
		<ModalPrompt
			header={t("acceptAndPlan")}
			disabled={!formState.isValid}
			confirmText={t("accept")}
			onSave={handleSubmit(onSubmit)}
			{...props}
		>
			<Form form={form}>
				<Form.Select
					name="productionType"
					label={t("productionType", "Production type")}
					options={productionTypeOptions}
				/>

				<Form.Select
					name="productionPriceId"
					label={t("publisherPrice", "Publisher price")}
					options={productionPriceOptions}
					defaultValue={productionPriceOptions.length === 1 ? productionPriceOptions[0].value : undefined}
				/>

				{otherPriceOptions.length > 0 && (
					<Form.Select
						name="otherPriceIds"
						multiple
						label={t("additionalCosts", "Additional costs")}
						options={otherPriceOptions}
						rules={{ required: false }}
					/>
				)}
			</Form>
		</ModalPrompt>
	);
}
