import { useNavigate } from "react-router-dom";

import CollectionDataTable from "../../../ui/components/CollectionDataTable/CollectionDataTable";
import useSearchInput from "../../../ui/hooks/useSearchInput";
import PageView from "../../../ui/views/PageView/PageView";

import useOrganizationsQuery from "../../hooks/useOrganizationsQuery";

import useAdminOrganizationsColumns from "./hooks/useOrganizationsTableColumns";

export default function OrganizationsView({ header, type, producer }) {
	const navigate = useNavigate();

	const query = useOrganizationsQuery({ type, producer });
	const columns = useAdminOrganizationsColumns();

	const [searchInput, searchQuery] = useSearchInput();

	return (
		<PageView header={header} sidebar={searchInput}>
			<CollectionDataTable
				persist
				filterable={false}
				query={query}
				columns={columns}
				onClick={({ id }) => navigate(id)}
				searchQuery={searchQuery}
				searchableKeys={["name"]}
				sortBy={{
					id: "name",
					desc: false,
				}}
			/>
		</PageView>
	);
}
