import { useState } from "react";

export default function useTable({ data, columns }) {
	const [selectedRowIds, setSelectedRowIds] = useState([]);
	const [visibleColumns, setVisibleColumns] = useState(columns.map(({ id }) => id));

	const filteredColumns = columns.filter(({ id }) => visibleColumns.includes(id));
	const selectedRows = data.filter(({ id }) => selectedRowIds.includes(id));

	return {
		data,
		columns,
		selectedRows,
		selectedRowIds,
		setSelectedRowIds,
		filteredColumns,
		visibleColumns,
		setVisibleColumns,
	};
}
