import { Route } from "react-router-dom";

import Agreement from "../pages/Agreement";
import AgreementTemplate from "../pages/AgreementTemplate";
import AgreementTemplates from "../pages/AgreementTemplates";
import AgreementsExpiring from "../pages/AgreementsExpiring";
import AgreementsPending from "../pages/AgreementsPending";
import CreateAgreementTemplate from "../pages/CreateAgreementTemplate";
import PrepareAgreements from "../pages/PrepareAgreements";

export const agreement = <Route path=":id" element={<Agreement />} handle={{ crumb: ({ id }) => id }} />;

export const agreements = (
	<Route path="agreements">
		<Route path="pending" handle={{ crumb: ({ t }) => t("pendingAgreements", "Pending agreements") }}>
			<Route index element={<AgreementsPending />} />
			{agreement}
		</Route>

		<Route path="expiring" handle={{ crumb: ({ t }) => t("expiringAgreements", "Expiring agreements") }}>
			<Route index element={<AgreementsExpiring />} />
			{agreement}
		</Route>

		<Route path="prepare" handle={{ crumb: ({ t }) => t("prepareAgreements", "Prepare agreements") }}>
			<Route index element={<PrepareAgreements />} />
			<Route path=":templateId" element={<PrepareAgreements />} />
		</Route>

		<Route path="templates" handle={{ crumb: ({ t }) => t("agreementTemplates", "Agreement templates") }}>
			<Route index element={<AgreementTemplates />} />
			<Route path=":id" element={<AgreementTemplate />} handle={{ crumb: ({ id }) => id }} />
			<Route path="create" element={<CreateAgreementTemplate />} />
		</Route>

		{agreement}
	</Route>
);
