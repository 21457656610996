import React from "react";

import Section from "../../ui/components/Section/Section";

import useHasRight from "../../authorization/hooks/useHasRight";
import ProducerDashboard from "../../dashboard/components/Dashboard/ProducerDashboard";

import AlertsWidget from "../components/AlertsWidget/AlertsWidget";
import FavoriteProductionsWidget from "../components/FavoriteProductionsWidget/FavoriteProductionsWidget";
import MyProductionsWidget from "../components/MyProductionsWidget/MyProductionsWidget";
import NotificationsWidget from "../components/NotificationsWidget/NotificationsWidget";
import OffersWidget from "../components/OffersWidget/OffersWidget";
import TodosWidget from "../components/TodosWidget/TodosWidget";
import HomeLayout from "../layouts/HomeLayout/HomeLayout";

const maxWidth = 1300;

export default function HomeProducerView({ organization }) {
	const hasRight = useHasRight();

	return (
		<>
			{hasRight("admin") && (
				<Section maxWidth={1600}>
					<ProducerDashboard organization={organization} />
				</Section>
			)}

			<Section maxWidth={maxWidth}>
				<HomeLayout>
					<HomeLayout.LeftColumn>
						<NotificationsWidget organization={organization} />
						<TodosWidget organization={organization} />
						<OffersWidget organization={organization} />
					</HomeLayout.LeftColumn>

					<HomeLayout.RightColumn>
						<AlertsWidget organization={organization} />
						<FavoriteProductionsWidget organization={organization} />
						<MyProductionsWidget organization={organization} />
					</HomeLayout.RightColumn>
				</HomeLayout>
			</Section>
		</>
	);
}
