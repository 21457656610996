import { Button } from "semantic-ui-react";

import React, { useState } from "react";
import { useTranslation } from "react-i18next";

import { firebase } from "astrid-firebase";
import grabSession from "astrid-firestore/src/api/sessions/grabSession";

import { useProfile } from "../../../../authentication/state/profile";

export default function CalendarGrabSessionButton({ session, production, setError }) {
	const { t } = useTranslation();
	const user = useProfile();

	const [isGrabbing, setIsGrabbing] = useState(false);

	return (
		<Button
			primary
			loading={isGrabbing}
			onClick={async () => {
				setIsGrabbing(true);
				setError(null);

				try {
					await grabSession(firebase, {
						session,
						production,
						user,
					});
				} catch (error) {
					console.error(error);
					setError(error);
				}

				setIsGrabbing(false);
			}}
		>
			{t("grabSession", "Grab session")}
		</Button>
	);
}
