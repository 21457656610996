import { Route } from "react-router-dom";

import Distribution from "../pages/Distribution";
import DistributionPipeline from "../pages/DistributionPipeline";
import Distributions from "../pages/Distributions";

export const distribution = (
	<Route path=":distribution" element={<Distribution />} handle={{ crumb: ({ distribution }) => distribution?.id }}>
		<Route index element={<DistributionPipeline />} />
	</Route>
);

export const distributions = (
	<Route path="distributions" handle={{ crumb: ({ t }) => t("distributions", "Distributions") }}>
		<Route index element={<Distributions />} />

		{distribution}
	</Route>
);
