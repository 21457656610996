import equal from "fast-deep-equal";

import { Icon } from "semantic-ui-react";

import React from "react";
import { useTranslation } from "react-i18next";
import { useInstantSearch } from "react-instantsearch";

import Flex from "../../../../components/Flex/Flex";

import Button from "../Buttons/Button";
import ConfirmationModalTrigger from "../ConfirmationModal/ConfirmationModalTrigger";
import DropdownMenu from "../DropdownMenu/DropdownMenu";

import useInstantSearchPresets from "./hooks/useInstantSearchPresets";

export default function InstantSearchPresets({ path, refinements }) {
	const { t } = useTranslation();
	const { indexUiState, setIndexUiState } = useInstantSearch();

	const { presets, onAddPreset, onRemovePreset } = useInstantSearchPresets({ path });

	const { refinementList = null, sortBy = null, range = null } = indexUiState;

	const currentState = { refinementList, sortBy, range };

	const activeKeys = Object.keys({ ...refinementList, ...range });
	const isRefinementActive = activeKeys.length > 0;

	const activeRefinements = refinements?.filter(({ attribute }) => activeKeys.includes(attribute));
	const refinementText = activeRefinements?.map(({ header }) => header).join(", ");

	const [activeKey] = Object.entries(presets).find(([, preset]) => equal(currentState, preset)) || [];

	const filterText = activeKey ? activeKey : isRefinementActive ? refinementText : t("all", "All");

	const options = [
		{
			text: t("all", "All"),
			onClick: () => setIndexUiState({}),
		},
		...Object.entries(presets || {}).map(([key, preset]) => ({
			onClick: () => setIndexUiState(preset),
			active: key === activeKey,
			content: (
				<Flex gap={10}>
					{key}

					<ConfirmationModalTrigger
						text={`${t("deletePreset", "Delete preset")} "${key}"?`}
						onConfirm={() => {
							onRemovePreset(key);

							if (key === activeKey) {
								setIndexUiState({});
							}
						}}
						trigger={<Icon link name="trash" size="small" fitted />}
					/>
				</Flex>
			),
		})),
		{
			type: "divider",
		},
		{
			disabled: !isRefinementActive || activeKey,
			content: (
				<Flex gap={10}>
					{t("saveFilter", "Save filter")}

					<Icon link name="plus" size="small" fitted />
				</Flex>
			),
			onClick: () => {
				const key = prompt(t("filterName", "Filter name"), filterText);

				if (key) {
					onAddPreset(key, currentState);
				}
			},
		},
	];

	return (
		<DropdownMenu
			options={options}
			trigger={
				<Button basic circular size="small" active={!!activeKey}>
					{t("filter", "Filter")}: {filterText}
					<Icon size="small" name="chevron down" fitted style={{ paddingLeft: 5 }} />
				</Button>
			}
		/>
	);
}
