import { Icon, Loader } from "semantic-ui-react";

import React from "react";

import Label from "../../Label/Label";

export default function MenuItemContent({ icon, text, content, children, loading, label, labelColor }) {
	return (
		<>
			{icon && <Icon name={icon} />}

			{text || content || children}

			{loading && <Loader as={Icon} inline active size="tiny" style={{ marginLeft: "1em" }} />}

			{label !== undefined && (
				<Label
					size={label === true ? "mini" : "small"}
					empty={label === true}
					color={labelColor || "blue"}
					style={{ margin: "-1em 0 -1em 1em" }}
				>
					{label}
				</Label>
			)}
		</>
	);
}
