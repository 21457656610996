import { Button, Dropdown, Icon } from "semantic-ui-react";

import { useTranslation } from "react-i18next";

import DropdownMenu from "../../../../ui/components/DropdownMenu/DropdownMenu";

import CalendarViewTypeButton from "./CalendarViewTypeButton";

export default function CalendarHeaderToolbarNavigationDropdown({ navigation, viewType, disableTools }) {
	const { t } = useTranslation();

	return (
		<DropdownMenu>
			{() => (
				<>
					<Dropdown.Item>
						<Button size="tiny" onClick={navigation.onTodayClick}>
							{t("today", "Today")}
						</Button>

						<Button
							size="tiny"
							icon
							onClick={(e) => {
								e.stopPropagation();
								navigation.onLeftNavigationClick();
							}}
							style={{ marginRight: 2 }}
						>
							<Icon name="chevron left" />
						</Button>

						<Button
							size="tiny"
							icon
							onClick={(e) => {
								e.stopPropagation();
								navigation.onRightNavigationClick();
							}}
						>
							<Icon name="chevron right" />
						</Button>
					</Dropdown.Item>

					<Dropdown.Item>
						<CalendarViewTypeButton
							viewPeriod={"Day"}
							navigation={navigation}
							viewType={viewType}
							disableTools={disableTools}
						>
							{t("day", "Day")}
						</CalendarViewTypeButton>
					</Dropdown.Item>

					<Dropdown.Item>
						<CalendarViewTypeButton
							viewPeriod={"Week"}
							navigation={navigation}
							viewType={viewType}
							disableTools={disableTools}
						>
							{t("week", "Week")}
						</CalendarViewTypeButton>
					</Dropdown.Item>

					{!disableTools && (
						<Dropdown.Item>
							<Button
								size="tiny"
								onClick={() => navigation.changeView("resourceTimelineMonth")}
								primary={viewType === "resourceTimelineMonth"}
							>
								{t("month", "Month")}
							</Button>
						</Dropdown.Item>
					)}
				</>
			)}
		</DropdownMenu>
	);
}
