import pipelineStepStatuses from "astrid-firestore/src/api/pipelines/constants/pipelineStepStatuses";

const pipelineStatusColors = {
	[pipelineStepStatuses.DISABLED]: "grey",
	[pipelineStepStatuses.PENDING]: "blue",
	[pipelineStepStatuses.RUNNING]: "blue",
	[pipelineStepStatuses.COMPLETED]: "green",
	[pipelineStepStatuses.FAILED]: "red",
};

export default pipelineStatusColors;
