import firebase from "firebase/app";

import { Button, Card, Message } from "semantic-ui-react";

import React, { useState } from "react";
import { useTranslation } from "react-i18next";

import createOrUpdateSession from "astrid-firestore/src/api/sessions/createOrUpdateSession";
import notifySessions from "astrid-firestore/src/api/sessions/notifications/notifySessions";
import getSessionTimestamps from "astrid-firestore/src/api/sessions/utils/getSessionTimestamps";
import { getSessionOverlapError } from "astrid-firestore/src/api/sessions/utils/sessionOverlap";
import { teamRoles } from "astrid-firestore/src/constants/teamRoles";

import Flex from "../../../../components/Flex/Flex";

import { useProfile } from "../../../authentication/state/profile";
import Form from "../../../forms/components/Form/Form";
import useForm from "../../../forms/hooks/useForm";
import ProductionMemberUserSelect from "../../../productions/components/ProductionMemberUserSelect/ProductionMemberUserSelect";
import useStudioOptions from "../../../studios/hooks/useStudioOptions";

import useSessionErrorTranslations from "../../hooks/useSessionErrorTranslations";
import useSessionOptions from "../../hooks/useSessionOptions";

import CalendarRecorderPriceSelect from "../CalendarBookingAssistant/components/CalendarRecorderPriceSelect";

import CalendarEditSessionFormTimeInput from "./components/CalendarEditSessionFormTimeInput";

export default function CalendarEditSessionForm({ sessions, session, production }) {
	const { t } = useTranslation();
	const user = useProfile();
	const sessionErrorTranslations = useSessionErrorTranslations();

	const [error, setError] = useState(null);

	const form = useForm({
		defaultValues: {
			productionId: production.id,
			date: session.start?.toDate(),
			startTime: session.start?.toDate(),
			endTime: session.end?.toDate(),
			readerId: session.reader?.[0],
			studioId: session.studio,
			recorderId: session.recorder,
			recorderPriceId: session.price,
			price: null,
			agreement: null,
			polish: session.polish,
			notifyAffectedUsers: true,
		},
	});

	const {
		handleSubmit,
		watch,
		formState: { isValid, isSubmitting },
	} = form;

	const { readerId, recorderId, productionId, date, startTime, endTime, studioId } = watch();

	const { start, end } = getSessionTimestamps({ date, startTime, endTime });

	const [studioOptions, loadingStudioOptions] = useStudioOptions({ organizationId: production.producer });

	const filteredStudioOptions = studioOptions.map((studio) => {
		const overlapError = getSessionOverlapError({
			start,
			end,
			studio,
			reader: [readerId],
			recorder: recorderId,
			production: productionId,
			sessions: sessions.filter((s) => s.id !== session.id),
		});

		return {
			...studio,
			overlapError,
			disabled: !!overlapError,
		};
	});

	const selectedStudio = filteredStudioOptions.find((s) => s.id === studioId);

	const onSubmit = handleSubmit(
		async ({ productionId, readerId, polish, recorderId, price, agreement, notifyAffectedUsers }) => {
			setError(null);

			try {
				const studio = selectedStudio.data;

				const after = await createOrUpdateSession(firebase, {
					session,
					price,
					agreement,
					productionId,
					user,
					studio,
					reader: [readerId],
					recorder: recorderId,
					start,
					end,
					polish,
					sessions,
				});

				if (notifyAffectedUsers) {
					const userIdsToNotify = [session.reader[0], session.recorder, after.reader[0], after.recorder]
						.filter(Boolean)
						.filter((id) => id !== user.id);

					await notifySessions({ modifiedSessions: [{ before: session, after }], userIdsToNotify, t });
				}
			} catch (error) {
				setError(error);
				console.error(error);
			}
		},
	);

	const [{ recorderOptions, recorderPriceOptions }] = useSessionOptions({ production, recorderId });

	return (
		<Form form={form}>
			<Card fluid style={{ padding: 5 }}>
				<Card.Content>
					<Form.Group widths="equal">
						<Form.DateInput name="date" label={t("date", "Date")} />

						<CalendarEditSessionFormTimeInput name="startTime" label={t("start", "Start")} />

						<CalendarEditSessionFormTimeInput name="endTime" label={t("end", "End")} />
					</Form.Group>

					<Form.Group widths="equal">
						<Form.Field name="readerId" rules={{ required: false }}>
							<ProductionMemberUserSelect
								label={t("reader", "Narrator")}
								production={production}
								role={teamRoles.READER}
							/>
						</Form.Field>

						<Form.Select
							name="studioId"
							label={t("Studio", "Studio")}
							options={filteredStudioOptions}
							loading={loadingStudioOptions}
						/>
					</Form.Group>

					<Form.Group widths="equal">
						<Form.Select
							name="recorderId"
							label={t("producerRecorder", "Recording engineer")}
							options={recorderOptions}
							disabled={!production}
						/>

						<CalendarRecorderPriceSelect options={recorderPriceOptions} />
					</Form.Group>

					<Form.Checkbox name="polish" label={t("polishSession")} rules={{ required: false }} />

					{selectedStudio?.overlapError && (
						<Message negative>{sessionErrorTranslations[selectedStudio.overlapError]}</Message>
					)}

					{error && <Message negative>{t("errorMessageHeader", "Something went wrong")}</Message>}
				</Card.Content>
				<Card.Content extra>
					<Flex>
						<div>
							<Form.Checkbox
								name="notifyAffectedUsers"
								label={t("notifyAffectedUsers", "Notify affected users")}
								rules={{ required: false }}
							/>
						</div>
						<Button
							loading={isSubmitting}
							disabled={selectedStudio?.disabled || !isValid || isSubmitting}
							floated="right"
							color="green"
							onClick={onSubmit}
						>
							{t("saveChanges", "Save changes")}
						</Button>
					</Flex>
				</Card.Content>
			</Card>
		</Form>
	);
}
