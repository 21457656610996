import { useMemo } from "react";

import filterOptions from "../helpers/filterOptions";

export default function useMappedColumns(columns) {
	return useMemo(
		() =>
			columns
				.filter((c) => c)
				.map((column) => {
					if (typeof column === "function") {
						return column({ columns });
					}

					return column;
				})
				.map(({ id, filter, accessor, ...rest }) => ({
					id,
					accessor: accessor || id,
					...rest,
					...filterOptions[filter],
				})),
		[columns],
	);
}
