import { useTranslation } from "react-i18next";

import importStatuses from "astrid-firestore/src/api/imports/constants/importStatuses";

export default function useImportStatuses() {
	const { t } = useTranslation();

	return {
		[importStatuses.PENDING]: {
			text: t("pending", "Pending"),
			color: "grey",
		},
		[importStatuses.IN_PROGRESS]: {
			text: t("inProgress", "In progress"),
			color: "blue",
			active: true,
		},
		[importStatuses.COMPLETED]: {
			text: t("completed", "Completed"),
			color: "green",
		},
		[importStatuses.FAILED]: {
			text: t("failed", "Failed"),
			color: "red",
		},
		[undefined]: {
			text: t("unknown", "Unknown"),
			color: "grey",
		},
	};
}
