import Popup from "../../../../ui/components/Popup/Popup";
import ProgressLabel from "../../../../ui/components/ProgressLabel/ProgressLabel";

export default function ArticleDeliveryStatusLabel({ text, status, sent, color, active, percent, disabled, onClick }) {
	return (
		<Popup
			content={sent ? `${status}: ${sent}` : status}
			color={color}
			trigger={
				<ProgressLabel
					percent={percent}
					color={color}
					active={active}
					style={{ opacity: disabled && 0.4 }}
					onClick={onClick}
				>
					{text}
				</ProgressLabel>
			}
		/>
	);
}
