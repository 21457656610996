import Form from "../../../../forms/components/Form/Form";

export default function CalendarEditSessionFormTimeInput(props) {
	return (
		<Form.DateInput
			dateFormat={false}
			locale="sv"
			timeFormat
			timeConstraints={{ minutes: { step: 15 } }}
			{...props}
		/>
	);
}
