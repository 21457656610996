import { useTranslation } from "react-i18next";

export default function useProductionsSorting() {
	const { t } = useTranslation();

	return [
		{
			text: `${t("productionDueDate", "Due date")} (${t("ascending", "ascending")})`,
			value: "productions",
		},
		{
			text: `${t("productionDueDate", "Due date")} (${t("descending", "descending")})`,
			value: "productionDate_desc",
		},
		{
			text: `${t("deliveryDate", "Delivery date")} (${t("ascending", "ascending")})`,
			value: "deliveryDate_asc",
		},
		{
			text: `${t("deliveryDate", "Delivery date")} (${t("descending", "descending")})`,
			value: "deliveryDate_desc",
		},
	];
}
