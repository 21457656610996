import firebase from "firebase";

import { useProfile } from "../../../../authentication/state/profile";

export default function useInstantSearchPresets({ path }) {
	const user = useProfile();

	const presets = user?.instantSearchPresets?.[path] || {};

	const onAddPreset = (key, preset) => {
		user.ref.update({
			[`instantSearchPresets.${path}.${key}`]: preset,
		});
	};

	const onRemovePreset = (key) => {
		user.ref.update({
			[`instantSearchPresets.${path}.${key}`]: firebase.firestore.FieldValue.delete(),
		});
	};

	return { presets, onAddPreset, onRemovePreset };
}
