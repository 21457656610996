import { useMemo } from "react";

import usePublisherManagers from "./usePublisherManagers";

export default function usePublisherManagerOptions(publisherId) {
	const [publisherManagers, loading, error] = usePublisherManagers(publisherId);

	const options = useMemo(
		() =>
			publisherManagers?.map((user) => ({
				key: user.id,
				value: user.id,
				text: `${user.firstName} ${user.lastName}`,
				data: user,
			})) || [],

		[publisherManagers],
	);

	return [options, loading, error];
}
