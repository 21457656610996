import { Route } from "react-router-dom";

import ForwardContext from "../../../layouts/ForwardContext";

import Imprint from "../pages/Imprint/Imprint";
import Imprints from "../pages/Imprints";

export const imprints = (
	<Route path="imprints" element={<ForwardContext />} handle={{ crumb: ({ t }) => t("imprints", "Imprints") }}>
		<Route index element={<Imprints />} />

		<Route path=":imprint" element={<Imprint />} handle={{ crumb: ({ imprint }) => imprint?.name }}>
			{/* No subpages yet */}
		</Route>
	</Route>
);
