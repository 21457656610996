import { useTranslation } from "react-i18next";
import { useOutletContext } from "react-router-dom";

import Unauthorized from "../../ui/components/Unauthorized/Unauthorized";
import useDocTitle from "../../ui/hooks/useDocTitle";

import featureFlags from "../../authorization/constants/featureFlags";
import useFeatureFlag from "../../authorization/hooks/useFeatureFlag";
import useHasRight from "../../authorization/hooks/useHasRight";
import ProductionsView from "../../productions/views/ProductionsView/ProductionsView";

import ListProductionsView from "../views/ListProductionsView/ListProductionsView";

export default function ListProductions(props) {
	const { t } = useTranslation();
	const { organization } = useOutletContext();

	const hasRight = useHasRight();
	const hasBetaFlag = useFeatureFlag(featureFlags.PRODUCTIONS_BETA);

	useDocTitle(t("productions", "Productions"));

	if (!hasRight("list-productions.beta")) {
		return <Unauthorized />;
	}

	if (hasBetaFlag) {
		return <ProductionsView organization={organization} />;
	}

	return <ListProductionsView {...props} />;
}
