import getCollectionData from "../../utils/getCollectionData";
import getDocumentData from "../../utils/getDocumentData";

import getArticlePresets from "../distribution/utils/getArticlePresets";

function getEligibleDistributionChannels({ channels, article }) {
	return channels.filter(
		({ active, acceptedLanguages, acceptedArticleTypes }) =>
			active &&
			(acceptedArticleTypes?.length === 0 || acceptedArticleTypes?.includes(article.type)) &&
			(acceptedLanguages?.length === 0 || acceptedLanguages?.includes(article.language)),
	);
}

export default async function getArticleChannelPresets(firebase, article) {
	const [publisher, channels] = await Promise.all([
		getDocumentData(article.publisher.ref),
		getCollectionData(firebase.firestore().collection("distributionChannels").where("deleted", "==", false)),
	]);

	const eligibleDistributionChannels = getEligibleDistributionChannels({ channels, article });
	const presets = getArticlePresets({ article, publisher });

	const channelPresets = eligibleDistributionChannels
		.map((channel) => channel.id)
		.reduce((acc, channelId) => {
			const preset = presets.find((preset) => preset.channel.id === channelId);
			if (preset) {
				return {
					...acc,
					[channelId]: {
						...(preset?.targetData || {}),
					},
				};
			}
			return acc;
		}, {});

	return {
		channels: channelPresets,
		channelIds: Object.keys(channelPresets),
	};
}
