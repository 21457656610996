import Flex from "../../../../../components/Flex/Flex";

export default function CalendarProductionResourceTitle({ resource }) {
	const { production } = resource.extendedProps;

	if (!production) {
		return null;
	}

	return (
		<Flex style={{ justifyContent: "flex-start", display: "inline-flex", gap: 5 }}>
			<div>{production.title}</div>
		</Flex>
	);
}
