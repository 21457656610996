import firebase from "firebase/app";

import { useTranslation } from "react-i18next";

import softDeleteDocument from "astrid-firestore/src/api/documents/softDeleteDocument";
import useBucket from "astrid-hooks/src/useBucket";

import { useProfile } from "../../../../authentication/state/profile";

export default function useExportDropdownMenuOptions() {
	const user = useProfile();

	const { t } = useTranslation();
	const { downloadFolder, deleteFolder } = useBucket("earselect-exports");

	return (data) => {
		return [
			{
				text: t("download", "Download"),
				action: async () => {
					await downloadFolder(data.id);
				},
			},
			{
				text: t("delete", "Delete"),
				action: async () => {
					await softDeleteDocument(firebase, data, user);
					await deleteFolder(data.id);
				},
			},
		];
	};
}
