import { useTranslation } from "react-i18next";

import OpenButton from "../../../ui/components/OpenButton/OpenButton";

import useOrdersFromCosts from "../../hooks/useOrdersFromCosts";

import CreateOrdersModal from "../CreateOrdersModal/CreateOrdersModal";

export default function CreateOrdersButton({ expense, invoice, costs, redirectTo }) {
	const { t } = useTranslation();

	const orders = useOrdersFromCosts(costs);

	const amount = orders.length;

	const title = invoice
		? t("createAmountInvoiceData", "Create {{amount}} invoicing", { amount })
		: expense
		? t("createAmountPurchaseOrders", "Create {{amount}} purchase orders", { amount })
		: t("createAmountSalesOrders", "Create {{amount}} sales orders", { amount });

	return (
		<OpenButton text={title} disabled={costs.length === 0}>
			{({ setOpen }) => (
				<CreateOrdersModal
					title={title}
					orders={orders}
					redirectTo={redirectTo}
					onClose={() => setOpen(false)}
				/>
			)}
		</OpenButton>
	);
}
