import { useMemo } from "react";

import useProductionOfferTypes from "./useProductionOfferTypes";

export default function useProductionOfferTypeOptions() {
	const productionTypes = useProductionOfferTypes();

	return useMemo(
		() =>
			Object.entries(productionTypes).map(([value, text]) => ({
				key: value,
				value,
				text,
			})),
		[productionTypes],
	);
}
