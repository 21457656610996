import equal from "fast-deep-equal";

import React from "react";
import { useTranslation } from "react-i18next";

import { Season } from "astrid-firestore/src/api/series/types/Season";

import Form from "../../../../forms/components/Form/Form";

import useSeasonOptions from "../../../hooks/useSeasonOptions";

import ArticleMetaDataLabel from "./ArticleMetaDataLabel";

export default function ArticleMetaDataSeasonSelect({ work, article, ...props }) {
	const { t } = useTranslation();

	const { watch } = Form.useFormContext();

	const serie = watch("serie");

	const [seasonOptions] = useSeasonOptions(serie);

	return (
		<Form.DocumentSelect
			name="season"
			label={
				<ArticleMetaDataLabel
					text={t("season", "Season")}
					condition={work && !equal(article.season, work.season)}
				/>
			}
			onAddDocument={(name) => {
				return Season.parse({ name });
			}}
			options={seasonOptions}
			disabled={!serie}
			allowAdditions
			allowEmpty
			search
			{...props}
		/>
	);
}
