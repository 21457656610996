import { teamRoles } from "../../../constants/teamRoles";
import {
	getAgreementRef,
	getPriceRef,
	getProductionRef,
	getStudioRef,
	getUserRef,
} from "../../../utils/referenceMappers";

import { getTeamMembersByRole } from "../../productions/utils/team";

import { SESSION_MIN_MILLISECONDS } from "../constants/sessionRules";
import { isSessionOverlapping } from "../utils/sessionOverlap";

export default function createSessionData(
	firebase,
	{
		session,
		user,
		studio,
		group,
		reader,
		readerUsers,
		recorder,
		recorderUser,
		price,
		agreement,
		production,
		start,
		end,
		polish = false,
		sessions = [],
	},
) {
	const ref = session?.ref || firebase.firestore().collection("sessions").doc();

	// check if session is not too short
	if (end - start < SESSION_MIN_MILLISECONDS) {
		throw new Error("Session too short");
	}

	const today = new Date();
	today.setHours(0, 0, 0, 0);

	// check if session doesn't start before today
	if (start < today) {
		throw new Error("Session is in the past");
	}

	// check if session is overlapping with other sessions
	if (studio && isSessionOverlapping({ ref, sessions, studio, reader, recorder, production, start, end })) {
		throw new Error("Session is overlapping with another session");
	}

	const managers = getTeamMembersByRole({ production, role: teamRoles.MANAGER });

	return {
		id: ref.id,
		ref,
		polish,
		status: "booked",
		created: firebase.firestore.FieldValue.serverTimestamp(),
		managerIds: managers.map((manager) => manager.id),
		managers,
		recorder,
		recorderData: getUserRef(recorderUser),
		priceRef: getPriceRef(price),
		agreementRef: getAgreementRef(agreement),
		studio: studio?.id || null,
		studioGroup: group || null,
		studioData: getStudioRef(studio),
		start: firebase.firestore.Timestamp.fromDate(start),
		end: firebase.firestore.Timestamp.fromDate(end),
		production: production.id,
		productionData: getProductionRef(production, { imgThumb: production.imgThumb }),
		productionPriceRef: null,
		producer: production.producer,
		reader,
		createdBy: getUserRef(user),
		readerData:
			readerUsers.length > 0
				? readerUsers.reduce((obj, reader) => ({ ...obj, [reader.id]: getUserRef(reader) }), {})
				: null,
		teamUserIds: production.teamUserIds || [],
	};
}
