import React from "react";

import { useMetaGuide } from "../../../../components/production/parts/MetaGuide";
import ProductionInformation from "../../../../components/production/parts/ProductionInformation";
import useHistory from "../../../ui/hooks/useHistory";

export default function ProductionOverviewViewOld({ production, permissions }) {
	const history = useHistory();

	const checkMissing = useMetaGuide((state) => state.checkMissing);

	return (
		<>
			<ProductionInformation
				production={production}
				permissions={permissions}
				checkMissing={() => checkMissing({ history })}
			/>
		</>
	);
}
