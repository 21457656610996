import { useEffect } from "react";
import { useInstantSearch } from "react-instantsearch";

export default function InstantSearchRefresh({ refreshInterval = 0, activeDuration = 5 * 60 * 1000 }) {
	const { refresh } = useInstantSearch();

	useEffect(() => {
		let intervalId;
		const startTime = Date.now();

		const shouldRefresh = () => Date.now() - startTime < activeDuration;

		// only refresh when tab is focused (to prevent unnecessary requests)
		if (document.visibilityState === "visible" && shouldRefresh()) {
			refresh();
		}

		if (refreshInterval > 0) {
			intervalId = setInterval(() => {
				if (document.visibilityState === "visible" && shouldRefresh()) {
					refresh();
				} else if (!shouldRefresh()) {
					clearInterval(intervalId);
				}
			}, refreshInterval);
		}

		return () => clearInterval(intervalId);
	}, [refresh, refreshInterval, activeDuration]);

	return null;
}
