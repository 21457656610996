export default function createDocumentData(firebase, collection, data) {
	if (!firebase) {
		throw new Error("Firebase is required");
	}

	if (!collection) {
		throw new Error("Collection is required");
	}

	const db = firebase.firestore();
	const ref = data.id ? db.collection(collection).doc(data.id) : data.ref || db.collection(collection).doc();

	const created = firebase.firestore.FieldValue.serverTimestamp();
	const createdBy = data?.createdBy || null;

	return {
		exists: false,
		created,
		createdBy,
		updated: created,
		updatedBy: createdBy,
		...data,
		id: ref.id,
		ref,
	};
}
