import React, { useState } from "react";

import Timeline from "astrid-components/lib/components/Audio/Timeline";
import Divider from "astrid-components/lib/components/Layout/Divider";
import Panel from "astrid-components/lib/components/Layout/Panel";
import Theme from "astrid-components/lib/components/Theme";

import Flex from "../../../../components/Flex/Flex";

import ArtifactAudioPlayerChapters from "./components/ArtifactAudioPlayerChapters";
import ArtifactAudioPlayerTimeline from "./components/ArtifactAudioPlayerTimeline";
import ArtifactAudioPlayerToolbar from "./components/ArtifactAudioPlayerToolbar";
import useArtifactAudioPlayer from "./hooks/useArtifactAudioPlayer";
import useArtifactAudioPlayerAudioFiles from "./hooks/useArtifactAudioPlayerAudioFiles";

export default function ArtifactAudioPlayer({ files, markers, onAddMarker, onUpdateMarker, onRemoveMarker }) {
	const [activeTool, setActiveTool] = useState(null);

	const audioFiles = useArtifactAudioPlayerAudioFiles({ files });

	const { play, pause, action } = useArtifactAudioPlayer({ audioFiles });

	return (
		<Theme mode="semantic">
			<Flex>
				<Panel style={{ height: 300, flex: 1, margin: -1 }} overflow="hidden" background="secondary">
					<ArtifactAudioPlayerToolbar
						play={play}
						pause={pause}
						action={action}
						activeTool={activeTool}
						setActiveTool={setActiveTool}
					/>

					<Divider />

					<ArtifactAudioPlayerTimeline
						play={play}
						action={action}
						markers={markers}
						audioFiles={audioFiles}
						activeTool={activeTool}
						setActiveTool={setActiveTool}
						onAddMarker={onAddMarker}
						onUpdateMarker={onUpdateMarker}
					/>

					<Divider />

					<Timeline.Navigation />
				</Panel>

				{markers.length > 0 && (
					<Panel style={{ height: 300, flex: 0.2, margin: -1 }} background="secondary">
						<ArtifactAudioPlayerChapters markers={markers} onRemoveMarker={onRemoveMarker} />
					</Panel>
				)}
			</Flex>
		</Theme>
	);
}
