import React from "react";
import { useTranslation } from "react-i18next";
import { useClearRefinements } from "react-instantsearch";

import Button from "../Buttons/Button";

export default function InstantSearchClear() {
	const { t } = useTranslation();

	const { refine, canRefine } = useClearRefinements();

	return (
		<Button basic circular size="small" disabeld={!canRefine} onClick={() => refine()}>
			{t("clear", "Clear")}
		</Button>
	);
}
