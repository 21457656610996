import React from "react";
import { useTranslation } from "react-i18next";

import { db } from "astrid-firebase";

import CollectionDataTable from "../../../ui/components/CollectionDataTable/CollectionDataTable";
import { timestampColumn } from "../../../ui/utils/columns";

import AdminCard from "../../../admin/components/AdminCard/AdminCard";

export default function ArticleExpiredExportsView({ article }) {
	const { t } = useTranslation();

	return (
		<AdminCard transparent header={t("expiredExports", "Expired Exports")}>
			<CollectionDataTable
				columns={[
					timestampColumn({ id: "created", Header: t("created", "Created") }),
					timestampColumn({ id: "deletedAt", Header: t("deleted", "Deleted") }),
				]}
				sortable={false}
				hoverRow={false}
				query={db.collection("exports").where("article.id", "==", article.id).where("deleted", "==", true)}
			/>
		</AdminCard>
	);
}
