import runTransaction from "../../utils/runTransaction";
import updateDocumentData from "../../utils/updateDocumentData";

import { Imprint } from "../imprints/types/Imprint";
import { Serie } from "../series/types/Serie";

import { Work } from "./types/Work";

export default function updateWork(firebase, data) {
	return runTransaction(
		firebase,
		[data.ref, data.serie?.ref, data.imprint?.ref],
		(transaction, [work, serie, imprint]) => {
			// If the imprint does not exist, create it
			if (data.imprint && !imprint?.exists) {
				transaction.set(imprint.ref, Imprint.parse({ ...data.imprint, publisher: work.publisher }));
			}

			// Update serie data if serie is changed or season is changed
			if (data.serie && (data.serie.id !== work.serie?.id || data.season?.id !== work.season?.id)) {
				const seasons = serie.seasons?.find((s) => s.id === data.season?.id)
					? serie.seasons
					: [...(serie.seasons || []), data.season].filter(Boolean);

				if (serie?.exists) {
					transaction.update(serie.ref, Serie.partial().parse({ seasons }));
				} else {
					transaction.set(serie.ref, Serie.parse({ ...data.serie, seasons, publisher: work.publisher }));
				}
			}

			const parsedWork = Work.partial().parse(updateDocumentData(firebase, data));

			transaction.set(parsedWork.ref, parsedWork, { merge: true });

			return parsedWork;
		},
	);
}
