export function canDeliverArticleToChannel({ article, channel, deliveryType }) {
	const { active, acceptedArticleTypes, acceptedLanguages, acceptedDeliveryTypes } = channel;

	if (!active) {
		return false;
	}

	// Channel disabled for article
	if (!article.channelIds.includes(channel.id)) {
		return false;
	}

	// If the channel has accepted article types, and the article type is not in the list, return false
	if (acceptedArticleTypes?.length > 0 && !acceptedArticleTypes.includes(article.type)) {
		return false;
	}

	// If the channel has accepted languages, and the article language is not in the list, return false
	if (acceptedLanguages?.length > 0 && !acceptedLanguages.includes(article.language)) {
		return false;
	}

	// If the channel has accepted delivery types, and the delivery type is not in the list, return false
	if (deliveryType && acceptedDeliveryTypes?.length > 0 && !acceptedDeliveryTypes.includes(deliveryType)) {
		return false;
	}

	if (deliveryType === "artifact") {
		// If the article does not have an artifact, return false
		if (!article.artifact) {
			return false;
		}
	}

	if (deliveryType === "metadata") {
		// If the article does not have a title or ISBN, return false
		if (!article.name || !article.isbn) {
			return false;
		}

		// If the article does not have a release date, return false
		if (!article.channels[channel.id]?.releaseDate) {
			return false;
		}
	}

	return true;
}

export function canDeliverArticlesToChannels({ articles, channel, deliveryType }) {
	return articles.every((article) => canDeliverArticleToChannel({ article, channel, deliveryType }));
}
