import { Icon } from "semantic-ui-react";

import React from "react";
import { useTranslation } from "react-i18next";
import { useHitsPerPage } from "react-instantsearch";

import Button from "../Buttons/Button";
import DropdownMenu from "../DropdownMenu/DropdownMenu";

export default function InstantSearchHitsPerPage() {
	const { t } = useTranslation();

	const { items, refine } = useHitsPerPage({
		items: [{ value: 10, default: true }, { value: 25 }, { value: 50 }, { value: 100 }],
	});

	const currentItem = items.find(({ isRefined }) => isRefined);

	return (
		<DropdownMenu
			options={items.map(({ value, isRefined }) => ({
				text: value,
				active: isRefined,
				onClick: () => refine(value),
			}))}
			trigger={
				<Button basic circular size="small" active={currentItem?.value !== items[0].value}>
					{t("hitsPerPage", "Hits per page")}: {currentItem?.value}
					<Icon size="small" name="chevron down" fitted style={{ paddingLeft: 5 }} />
				</Button>
			}
		/>
	);
}
