import { useTranslation } from "react-i18next";

import OpenButton from "../../../ui/components/OpenButton/OpenButton";

import CreateImportForm from "./CreateImportForm";

export default function CreateImportButton({ article, ...props }) {
	const { t } = useTranslation();

	return (
		<OpenButton text={t("newImport", "New import")} {...props}>
			{({ setOpen }) => <CreateImportForm article={article} onClose={() => setOpen(false)} />}
		</OpenButton>
	);
}
