import { useOutletContext, useParams } from "react-router-dom";

import db from "astrid-firebase/src/db";
import useDocumentData from "astrid-firestore/src/hooks/useDocumentData";

import LoadingContext from "../../ui/components/LoadingContext/LoadingContext";
import Unauthorized from "../../ui/components/Unauthorized/Unauthorized";

import useHasRight from "../../authorization/hooks/useHasRight";

import PublisherView from "../views/PublisherView/PublisherView";

export default function Publisher() {
	const { id } = useParams();
	const { organization } = useOutletContext();

	const hasRight = useHasRight();

	const [publisher, loading, error] = useDocumentData(id && db.collection("organizations").doc(id));

	if (!hasRight("publishers.view")) {
		return <Unauthorized />;
	}

	return (
		<LoadingContext loading={loading} error={error} data={publisher}>
			<PublisherView publisher={publisher} organization={organization} />
		</LoadingContext>
	);
}
