import { useTranslation } from "react-i18next";

import organizationTypes from "astrid-firestore/src/api/organizations/constants/organizationTypes";

import useHasRight from "../../../../authorization/hooks/useHasRight";
import shouldEnableInvoices from "../../../../invoices/utils/shouldEnableInvoices";

export default function useOrganizationFinanceMenuOptions({ organization }) {
	const { t } = useTranslation();

	const hasRight = useHasRight();

	if (organization?.type === organizationTypes.PRODUCER) {
		return [
			{
				path: "expenses",
				name: t("expenses", "Expenses"),
				hidden: !hasRight("costs.view"),
				options: [
					{
						path: "expenses",
						name: t("unprocessedExpenses", "Unprocessed expenses"),
					},
					{
						path: "purchases",
						name: t("POs"),
						hidden: !hasRight("orders.view"),
					},
					{
						path: "orders/PO",
						hidden: true,
					},
				],
			},
			{
				path: "revenues",
				name: t("revenues", "Revenues"),
				hidden: !hasRight("costs.view"),
				options: [
					{
						path: "revenues",
						name: t("unprocessedRevenues", "Unprocessed revenues"),
					},
					{
						path: "sales",
						name: t("salesOrders", "Sales orders"),
						hidden: !hasRight("orders.view"),
					},
					{
						path: "orders/SO",
						hidden: true,
					},
				],
			},
			{
				path: "invoices",
				name: t("invoices", "Invoices"),
				hidden: !hasRight("producerBilling.createPublisherInvoice") || !shouldEnableInvoices(organization.id),
			},
			{
				path: "prices",
				name: t("globalPrices", "Global prices"),
				hidden: !hasRight("prices.view"),
			},
			{
				path: "agreements/pending",
				name: t("agreements", "Agreements"),
				hidden: !hasRight("agreements.view"),
				options: [
					{
						path: "agreements/pending",
						name: t("pendingAgreements", "Pending agreements"),
					},
					{
						path: "agreements/expiring",
						name: t("expiringAgreements", "Expiring agreements"),
					},
					{
						path: "agreements/templates",
						name: t("agreementTemplates", "Agreement templates"),
					},
					{
						path: "agreements/prepare",
						hidden: !hasRight("agreements.create"),
						name: t("prepareAgreements", "Prepare agreements"),
					},
				],
			},
		];
	}

	return [];
}
