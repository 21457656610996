import FirestoreDocument from "../../../types/FirestoreDocument";
import FirestoreLifecycle from "../../../types/FirestoreLifecycle";
import { any, enumValues, nullable, object, string } from "../../../types/_types";

import { Article } from "../../articles/types/Article";

import importSources from "../constants/importSources";
import importStatuses from "../constants/importStatuses";

export const importSource = enumValues(importSources);

export const importStatus = enumValues(importStatuses).default(importStatuses.PENDING);

export const Import = FirestoreDocument.extend({
	source: importSource,
	status: importStatus,
	error: nullable(string()),
	data: object().passthrough(),
	article: nullable(
		object({
			id: string(),
			ref: any(),
			after: nullable(Article.partial()),
			before: nullable(Article.partial()),
		}),
	),
}).merge(FirestoreLifecycle);
