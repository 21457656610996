import { Checkbox } from "semantic-ui-react";

import React, { useContext } from "react";

import TableCell from "./TableCell";
import TableContext from "./TableContext";

export default function TableCellCheckbox({ row }) {
	const { selectedRowIds, setSelectedRowIds } = useContext(TableContext);

	return (
		<TableCell
			first
			sticky
			collapsing
			onClick={() => {
				setSelectedRowIds((selectedRowIds) =>
					selectedRowIds.includes(row.id)
						? selectedRowIds.filter((id) => id !== row.id)
						: [...selectedRowIds, row.id],
				);
			}}
		>
			<Checkbox checked={selectedRowIds.includes(row.id)} />
		</TableCell>
	);
}
