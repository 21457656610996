import { useMemo } from "react";
import { useTranslation } from "react-i18next";

import db from "astrid-firebase/src/db";

import Form from "../../../../forms/components/Form/Form";

export default function useBiblioPublishersColumns(form) {
	const { t } = useTranslation();
	const { setValue } = form;

	return useMemo(
		() => [
			{
				id: "description",
				flex: 1,
				Header: "Imprint",
				accessor: ({ id, description, comment }) => `${description}${comment ? ` (${comment})` : ""} [${id}]`,
			},
			{
				id: "publisher",
				flex: 1,
				Header: t("publisher"),
				Cell: ({ row }) => {
					setValue(
						`publishers.${row.original.id}.name`,
						`${row.original.description}${row.original.comment ? ` (${row.original.comment})` : ""}`,
					);

					return (
						<Form.CollectionSelect
							allowEmpty
							query={db.collection("organizations").where("type", "==", "publisher")}
							name={`publishers.${row.original.id}.publisher`}
							defaultValue={null}
						/>
					);
				},
			},
		],
		[setValue, t],
	);
}
