import { Loader, Image as SemanticImage } from "semantic-ui-react";

import React, { useEffect, useState } from "react";

import CoverImageModal from "./CoverImageModal";

export default function CoverImage({ data, thumb, style, modal, size = 600, ...props }) {
	const [open, setOpen] = useState(false);
	const [internalSrc, setInternalSrc] = useState("/img/thumb.jpg");

	const src = thumb ? data.coverThumb?.url || data.cover?.thumbUrl : data.cover?.url;

	useEffect(() => {
		if (src) {
			const img = new Image();
			img.onload = () => setInternalSrc(src);
			img.src = src;
		}
	}, [src]);

	const loading = src && src !== internalSrc;

	if (loading) {
		return (
			<div className="ui image cover" style={{ width: "100%", ...style }}>
				<Loader active />
			</div>
		);
	}

	return (
		<>
			<SemanticImage
				src={internalSrc}
				className="cover"
				style={{ cursor: modal && "pointer", height: size, ...style }}
				onClick={() => setOpen(true)}
				{...props}
			/>

			{modal && <CoverImageModal data={data} open={open} onClose={() => setOpen(false)} />}
		</>
	);
}
